






























import { Vue, Component } from 'vue-property-decorator'

@Component({ name: 'BridgeToArbitrum' })

export default class BridgeToArbitrum extends Vue {

}
