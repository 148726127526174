

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'LazyImageCircle'
})
export default class LazyImageCircle extends Vue {
  @Prop({ required: true, default: '' }) readonly imageUrl!: string
  @Prop({ required: true }) readonly handleImageError!: string
  @Prop({ required: false }) readonly alt: any
  @Prop({ required: false, default: '100%' }) readonly width: any
  @Prop({ required: false, default: '100%' }) readonly height: any
}
