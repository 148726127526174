




















import { Component, Mixins } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import AcceptCustomToken from '@/components/AcceptCustomToken.vue'
import { Modal } from '@/classes/modal'
import { Token, TokenInput, TokenInputType } from '@/types'

@Component({
  name: 'TokenImportModal',
  components: {
    AcceptCustomToken
  }
})
export default class TokenImportModal extends Mixins(AbstractSwapView, Modal) {
  tokenData = {} as Token
  tokenInputType = '' as TokenInputType

  async showModalWithTokenAddress(tokenInputType: TokenInputType, tokenAddress: string) {
    this.tokenInputType = tokenInputType
    const result = await this.getTokenInfo(tokenAddress) as Token
    this.tokenData = result
    this.showModal()
  }

  handleContinueBtn(token: Token) {
    const data: TokenInput = { address: token.address, symbol: token.symbol }
    this.setTokenInput(this.tokenInputType, data)
    this.handleTokenToVuexState(token)
    this.getTokensBalance([this.tokenData])
    this.isModalOpen = false
    this.amplitudeLogEvent('Click button accept import token inside TokenImportModal', {
      tokenAddress: token.address,
      tokenName: token.name,
      tokenSymbol: token.symbol
    })
  }
}
