import { Module, VuexModule, MutationAction, getModule, Mutation } from 'vuex-module-decorators'
import Store from '@/store'
import { TopOptiPunkClass } from '../types'
import { stateDecoratorFactory } from 'vuex-module-decorators-state'

@Module({
  namespaced: true,
  dynamic: true,
  store: Store,
  name: 'optiPunkNft'
})
export class OptiPunkNft extends VuexModule {
  topOptiPunkClass: TopOptiPunkClass = {} as TopOptiPunkClass

  @MutationAction({ mutate: ['topOptiPunkClass'] })
  async setTopOptiPunkClass(topOptiPunkClass: TopOptiPunkClass | object) {
    return { topOptiPunkClass }
  }

  @Mutation
  async updateTopOptiPunkImageUrl(url: string) {
    this.topOptiPunkClass = { ...this.topOptiPunkClass, imageUrl: url }
  }
}

export const OptiPunkNftModule = getModule(OptiPunkNft)
export const OptiPunkNftState = stateDecoratorFactory(OptiPunkNftModule)
