import { OptiPunkNFTFeeDiscountTear } from '@/types/index'
import { OptiPunkClass } from '@/features/OptiPunkNft/types'

export const optiPunkNFTfeeDiscountTear: OptiPunkNFTFeeDiscountTear[] = [
  {
    tear: 1,
    displayClassName: 'Optipunk',
    className: OptiPunkClass.OPTIPUNK,
    displayDiscount: '10%',
    discountPercentage: 10,
    feePercentage: '0.09%'
  }
]
