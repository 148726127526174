



































import { Component } from 'vue-property-decorator'
import Toast from 'primevue/toast'
import Header from '@/features/Header/index.vue'
import ContactInfo from '@/components/ContactInfo.vue'
import ConnectWalletButtonOnMobile from '@/features/ConnectWalletButtonOnMobile/index.vue'
import AutoConnectView from '@/features/Web3Connector/components/AutoConnect'
import { getMute, getTheme, toggleMute, toggleTheme } from '@/utils/helper'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'

@Component({
  components: {
    Header,
    ContactInfo,
    Toast,
    ConnectWalletButtonOnMobile
  },
  computed: mapGetters(['theme'])
})
export default class Home extends AutoConnectView {
  theme?: string
  hideLayout: boolean

  constructor() {
    super()
    this.hideLayout = false
  }

  mounted() {
    let _theme
    const theme = getTheme()
    const t = this.$route.query?.t
    if (t) {
      _theme = t === '1' ? THEME.SPACE_MODE : THEME.LIGHT_MODE

      if (_theme !== theme) {
        toggleTheme()
      }
    } else {
      _theme = theme
    }

    let _mute
    const mute = getMute()
    const s = this.$route.query?.s
    if (s) {
      _mute = s === '1'

      if (_mute !== mute) {
        toggleMute()
      }
    } else {
      _mute = mute
    }

    this.$store.dispatch('setMute', _mute)
    this.$store.dispatch('setTheme', _theme)
    document.body.setAttribute('data-theme', _theme)
    this.handleLayoutHidden()
  }

  get iconCertik() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-certik-space.svg')
    }

    return require('@/assets/svg/icon-certik.svg')
  }

  get iconValix() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-valix-space.svg')
    }

    return require('@/assets/svg/icon-valix.svg')
  }

  handleLayoutHidden() {
    const query = this.$route.query
    if (query.hasOwnProperty('hideLayout') && query.hideLayout !== 'false') {
      this.hideLayout = true
      document.body.setAttribute('data-hide-layout', '')
    }
  }
}
