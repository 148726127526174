import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import * as Sentry from '@sentry/vue'
import { Network } from '@/features/Web3Connector/types'
import { SeverityType } from '../types'
import { extractError, extractAggregateErrorsMessage, findErrorMessage } from '@/utils/helper'
@Component
export class AbstractSentryView extends Vue {
  sentryLogInfo(network: Network | 'disconnected', message: string, tags?: { [key: string]: any }) {
    Sentry.withScope(function(scope: Sentry.Scope) {
      if (tags && Reflect.ownKeys(tags)) {
        scope.setTags(tags)
      }
      scope.setLevel(Sentry.Severity.Info)
      scope.setFingerprint([network, message])
      Sentry.captureMessage(message)
    })
  }

  sentryLogError(
    network: Network | 'disconnected' | 'Unknow' = 'Unknow',
    error: any,
    logLevel: SeverityType = 'Error',
    tags?: { [key: string]: any }
  ) {
    const exception = extractError(error)
    Sentry.withScope(function(scope: Sentry.Scope) {
      if (tags && Reflect.ownKeys(tags)) {
        scope.setTags(tags)
      }
      scope.setLevel(Sentry.Severity[logLevel])

      if (error instanceof AggregateError) {
        scope.setFingerprint([network, ...extractAggregateErrorsMessage(error)])
      } else {
        scope.setFingerprint([network, findErrorMessage(exception)])
      }
      Sentry.captureException(exception)
    })
  }
}
