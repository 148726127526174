








import ICountUp from 'vue-countup-v2'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CountUpOptions } from 'countup.js'
import Bignumber from '@/utils/bignumber'

@Component({
  name: 'NumberAnimation',
  components: {
    ICountUp
  }
})

export default class NumberAnimation extends Vue {
  @Prop([Number, String]) readonly value!: number | string
  @Prop({ default: '' }) readonly prefix!: string
  @Prop({ default: '' }) readonly suffix!: string
  @Prop(Number) readonly duration!: number

  delay = 1000
  options: CountUpOptions = {
    useEasing: true,
    useGrouping: true,
    decimalPlaces: 0,
    separator: ',',
    decimal: '.',
    prefix: '',
    suffix: ''
  }

  @Watch('prefix', { immediate: true })
  async onPrefixChange(val: string) {
    this.options.prefix = val
  }

  @Watch('suffix', { immediate: true })
  async onSuffixChange(val: string) {
    this.options.suffix = val
  }

  @Watch('duration', { immediate: true })
  async onDurationChange(val: number) {
    if (val) {
      this.options.duration = val
    }
  }

  @Watch('value', { immediate: true })
  async onValueChange(newVal: number | string, oldVal: number | string) {
    if (oldVal === undefined && newVal) {
      this.options.startVal = this.parseStringToNumber(newVal)
    }
    if (newVal !== undefined) {
      if (Bignumber(this.parseStringToNumber(newVal)).isInteger()) {
        this.options.decimalPlaces = 0
      } else {
        this.options.decimalPlaces = this.parseStringToNumber(newVal).toString(10).split('.')[1]?.length || 0
      }
    }
  }

  get displayValue(): number {
    if (this.value !== undefined) {
      return this.parseStringToNumber(this.value)
    }
    return 0
  }

  parseStringToNumber(value: string | number): number {
    return Bignumber(value.toString(10).replace(/,/g, '')).toNumber()
  }
}
