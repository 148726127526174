import { GetterTree } from 'vuex'

import State from './state'

const getters: GetterTree<State, State> = {
  mute(state: State): boolean {
    return state.mute
  },
  theme(state: State): string {
    return state.theme
  }
}

export default getters
