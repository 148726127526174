





































































































import { Vue, Component } from 'vue-property-decorator'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'

@Component({
  name: 'ContactInfo',
  computed: mapGetters(['theme'])
})
export default class ContactInfo extends Vue {
  theme?: string

  get iconTwitter() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-twitter-space.svg')
    }

    return require('@/assets/svg/icon-twitter.svg')
  }

  get iconMedium() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-medium-space.svg')
    }

    return require('@/assets/svg/icon-medium.svg')
  }

  get iconTelegram() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-telegram-space.svg')
    }

    return require('@/assets/svg/icon-telegram.svg')
  }

  get iconDiscord() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-discord-space.svg')
    }

    return require('@/assets/svg/icon-discord.svg')
  }

  get iconGithub() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-github-space.svg')
    }

    return require('@/assets/svg/icon-github.svg')
  }

  get iconGitbook() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-gitbook-space.svg')
    }

    return require('@/assets/svg/icon-gitbook.svg')
  }

  get iconCoinmarketcap() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-coinmarketcap-space.svg')
    }

    return require('@/assets/svg/icon-coinmarketcap.svg')
  }

  get iconCoingecko() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-coingecko-space.svg')
    }

    return require('@/assets/svg/icon-coingecko.svg')
  }
}
