





































































import { Component } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { getTokenImage } from '@/utils/helper'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'
import ImageCircle from '@/components/ImageCircle.vue'

enum DexImagePath {
  pancake = '/svg/icon-pancake.svg',
  pancakev2 = '/svg/icon-pancake-v2.svg',
  pancakev3 = '/svg/icon-pancake-v3.svg',
  jul = '/svg/icon-jul.svg',
  bakery = '/svg/icon-bakery.svg',
  warden = '/svg/icon-warden.svg',
  ellipsis = '/png/icon-ellipsis.png',
  valuedefi = '/png/icon-valuedefi.png',
  spartan = '/svg/icon-spartan.svg',
  acryptos = '/png/icon-acryptos.png',
  nerve = '/png/icon-nerve.png',
  definix = '/png/icon-definix.png',
  mdex = '/png/icon-mdex.png',
  belt = '/png/icon-belt.png',
  ape = '/png/icon-ape.png',
  dop = '/png/icon-dopple.png',
  twin = '/png/icon-twindex.png',
  wault = '/png/icon-wault.png',
  dodov2 = '/svg/icon-dodo.svg',
  dodo = '/svg/icon-dodo.svg',
  fat = '/png/icon-fat.png',
  panther = '/png/icon-panther.png',
  biswap = '/png/icon-biswap.png',
  quick = '/png/icon-quick.png',
  sushi = '/png/icon-sushi.png',
  sushiswap = '/png/icon-sushi.png',
  curve = '/png/icon-curve.png',
  dfyn = '/png/icon-dfyn.png',
  kyber = '/png/icon-kyber.png',
  balancer = '/svg/icon-balancer.svg',
  xwin = '/png/icon-xwin.png',
  rabbit = '/png/icon-rabbit.png',
  fortube = '/png/icon-fortube.png',
  jetswap = '/png/icon-jetswap.png',
  firebird = '/png/icon-firebird.png',
  polydex = '/png/icon-polydex.png',
  iron = '/png/icon-iron.png',
  smoothy = '/png/icon-smoothswap.png',
  gambit = '/png/icon-gambit.png',
  uni = '/png/icon-uni.png',
  uniswapv2 = '/png/icon-uni.png',
  latte = '/png/icon-latte.png',
  baby = '/png/icon-babyswap.png',
  dsg = '/png/icon-dsgmetaverse.png',
  synapse = '/png/icon-synapse.png',
  mars = '/png/icon-mars.png',
  autoshark = '/png/icon-autoshark.png',
  shiba = '/png/icon-shiba.png',
  uniswapv3 = '/svg/icon-uniswap-v3.svg',
  pangolin = '/svg/icon-pangolin.svg',
  olivecash = '/png/icon-olivecash.png',
  baguette = '/svg/icon-baguette.svg',
  canary = '/png/icon-canary.png',
  elk = '/svg/icon-elk.svg',
  haku = '/png/icon-hakuswap.png',
  lydia = '/png/icon-lydia.png',
  thorus = '/png/icon-thorus.png',
  traderjoe = '/png/icon-traderjoe.png',
  yeti = '/png/icon-yeti.png',
  axial = '/png/icon-axial.png',
  gmx = '/svg/icon-gmx.svg',
  velodrome = '/svg/icon-velodrome.svg',
  velodromev2 = '/svg/icon-velodrome-v2.svg',
  synthetix = '/svg/icon-synthetix.svg',
  velo = '/svg/icon-velodrome-slipstream.svg',
  thena = '/png/icon-thena.png'
}
type DexImagePathStrings = keyof typeof DexImagePath;

@Component({
  name: 'BestRateRoutingBox',
  computed: mapGetters(['theme']),
  components: {
    ImageCircle
  }
})
export default class BestRateRoutingBox extends AbstractSwapView {
  theme?: string
  DexImagePath = DexImagePath

  get iconWarden() {
    if (this.theme && this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/warden-logo-no-text-space.svg')
    }

    return require('@/assets/svg/warden-logo-no-text.svg')
  }

  getTokenImage = getTokenImage
  getListOfDex(subRoutesName: string[]) {
    const nameOfDex = subRoutesName.map(text => {
      const textSplit = text?.split(':')
      const dexName = textSplit[0]?.toLowerCase()
      const poolName = textSplit[1]?.toLowerCase()
      if (dexName === 'uni' && poolName.includes('v3')) {
        return 'uniswapv3'
      } else if (dexName === 'velodrome' && poolName.includes('v2volatile')) {
        return 'velodromev2'
      } else if (dexName === 'pnck' && poolName.includes('v3')) {
        return 'pancakev3'
      }
      return dexName
    })
    const uniqueNameOfDex = Array.from(new Set(nameOfDex))
    return uniqueNameOfDex
  }

  getDexImage(routeName: DexImagePathStrings) {
    try {
      return require(`@/assets${this.DexImagePath[routeName]}`)
    } catch {
      return require('@/assets/svg/icon-unknow-token.svg')
    }
  }
}
