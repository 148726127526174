import { WardenswapSdkVersion } from '@/features/Swap/types'
export enum WalletProvider {
  METAMASK = 'MetaMask',
  BINANCE_CHAIN_WALLET = 'BinanceChainWallet',
  WALLET_CONNCET = 'WalletConnect',
  TRUST_WALLET = 'TrustWallet',
  TOKEN_POCKET = 'TokenPocket',
  MATH_WALLET = 'MathWallet'
}
export enum DisplayWalletProvider {
  INJECTED_WEB3 = 'MetaMask',
  WALLET_CONNECT = 'Wallet Connect',
  FORTMATIC = 'Formatic',
  WALLET_LINK = 'WALLET_LINK',
  BINANCE_CHAIN = 'Binance Chain Wallet'
}

export type BestRateButtonStatus =
  'CONNECT_WALLET' |
  'WRONG_NETWORK' |
  'UNLOCK_TOKEN' |
  'UNLOCK_TOKEN_LOADING' |
  'ENTER_TOKEN_AMOUNT' |
  'BALANCE_IS_NOT_ENOUGH' |
  'SELECT_A_TOKEN' |
  'SWAP' |
  'BEST_RATE_QUERY_LOADING' |
  'UNVALUABLE' |
  'FAIL'

export interface Token {
  name: string;
  symbol: string;
  address: string;
  chainId?: number;
  decimals: number;
  logoURI?: string | undefined;
}

export interface TokenInput {
  address?: string;
  symbol?: string;
  amount?: string;
}

export type TokenInputType =
  'tokenA' |
  'tokenB'

export interface CurrentBestRateSdk {
  subRoutesIndex?: string[];
  subRoutesName: string[];
  amountIn: string;
  amountOut: string;
  percentage: string;
  swapAddress?: string;
  sdkVersion: WardenswapSdkVersion.NEW_VERION_2;
}
export interface RouteDataFromCachingId {
  subRoutesIndex: string[];
  subRoutesName: string[];
}

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}
export enum BestRateQueryState {
  UNKNOWN,
  PENDING,
  SUCCESS,
  FAIL,
  UNVALUABLE
}

export enum BestRateQuerySide {
  UNKNOWN,
  FROM_A_TO_B,
  FROM_B_TO_A,
}

export enum TradeState {
  UNKNOWN,
  PENDING,
  REJECTED,
  WAIT_TX_CONFIRM,
  SUCCESS,
  FAIL,
  ERROR
}

export enum MethodNameForTrade {
  TRADE_WITH_LEARN = 'tradeWithLearned',
  TRADE_STRATEGIES = 'tradeStrategies',
  SPLIT_TRADES = 'splitTrades',
  TRADE_ETH_TO_WETH = 'tradeEthToWeth',
  TRADE_WETH_TO_ETH = 'tradeWethToEth',
  TRADE_STRATEGIES_C1 = 'tradeStrategiesC1',
  TRADE_WITH_LEARN_C1 = 'tradeWithLearnedC1',
  TRADE_STRATEGIES_2 = 'swap',
  SPLIT_TRADES_2 = 'swapSplit',
}

export enum MethodNameForGetBestRate {
  SPLIT = 'split',
  STRATEGIES = 'strategies',
  ETH_TO_WETH = 'ethToWeth',
  WETH_TO_ETH = 'wethToEth'
}

export enum ApprovalTransactionStatus {
  PENDING,
  SUCCESS,
  FAIL
}

export interface FarmConfig {
  pid: number;
  lpSymbol: string;
  lpAddresses: string;
}

export interface OldUserLocalStorage {
  tokens: {
    [address: string]: Token;
  };
  createdTimestamp: number;
  lastUpdateTimestamp?: number;
  mute?: boolean;
  theme?: string;
}

export interface NewUserLocalStorage {
  tokens: {
    [networkId: number]: {
      [address: string]: Token;
    };
  };
  createdTimestamp: number;
  lastUpdateTimestamp?: number;
  mute?: boolean;
  theme?: string;
  priceSlippagePercentage?: string;
}

export interface SystemLocalStorage {
  version: string;
  createdTimestamp: number;
  lastUpdateTimestamp?: number;
}

export interface ErrorHTMLImageElement {
  target: HTMLImageElement;
}

export type FindBestRateOptions = {
  shouldFindBestRateType: MethodNameForGetBestRate[];
}

export type FindBestRateOptional = {
  [K in keyof FindBestRateOptions]?: FindBestRateOptions[K];
};

export interface TransactionHashHistory {
  transactionHash: string;
}

export interface TransactionReceiptData {
  srcAssetAddress: string;
  destAssetAddress: string;
  srcAmount: string;
  destAmount: string;
  srcAssetData: Token;
  destAssetData: Token;
}

export type UnPromisify<T> = T extends Promise<infer U> ? U : T

export interface WardenWhiteLabelOptions {
  /** css style object, ex. { '--color-background': 'red' } */
  style?: { [key: string]: string };
}

export interface WADTokenFeeDiscountTear {
  tear: number;
  displayMinTokenAmount: string;
  minTokenAmount: number;
  displayDiscount: string;
  discountPercentage: number;
  feePercentage: string;
}

export interface MWADNFTFeeDiscountTear {
  tear: number;
  displayClassName: string;
  className: string;
  displayDiscount: string;
  discountPercentage: number;
  feePercentage: string;
}

export interface OptiPunkNFTFeeDiscountTear {
  tear: number;
  displayClassName: string;
  className: string;
  displayDiscount: string;
  discountPercentage: number;
  feePercentage: string;
}

export enum FeeDiscountType {
  WAD_TOKEN = 'WAD_TOKEN',
  MWAD_NFT = 'MWAD_NFT',
  OPTIPUNK_NFT = 'OPTIPUNK_NFT',
  WARP_OR_UNSWAP_TOKEN = 'WARP_OR_UNSWAP_TOKEN',
  FREE_FEE_DISCOUNT = 'FREE_FEE_DISCOUNT',
  NOT_RECEIVE_DISCOUNT = 'NOT_RECEIVE_DISCOUNT'
}
