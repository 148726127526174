















































































































































import { Component, Emit, Prop } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView/index'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import TradeFeeDiscountTooltip from '@/components/TradeFeeDiscountTooltip.vue'
import LazyImageCircle from '@/components/LazyImageCircle.vue'
import THEME from '@/constants/theme'
import { NETWORK_CONSTANT } from '@/constants'
import { FeeDiscountType } from '@/types'
import { mapGetters } from 'vuex'

@Component({
  name: 'TradingFeeDiscount',
  computed: mapGetters(['theme']),
  components: {
    TradeFeeDiscountTooltip,
    LazyImageCircle
  }
})
export default class TradingFeeDiscount extends AbstractSwapView {
  theme?: string
  chainIDThatSupport = ChainIDThatSupport
  feeDiscountType = FeeDiscountType

  @Prop({ default: false }) readonly displayTooltipEndOfLine!: boolean

  @Emit()
  handleBuyWadToken() {
    return
  }

  get iconWarden() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-warden-no-bg-space.svg')
    }

    return require('@/assets/svg/icon-warden-no-bg.svg')
  }

  get wrapOrUnwrapNativeTokenDisplayText() {
    const nativeToken = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN
    const wrappedToken = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].WRAPPED_TOKEN
    const text = `trading fee for ${nativeToken.symbol}, ${wrappedToken.symbol} pairs`
    return text
  }
}
