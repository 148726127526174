import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import { Web3Connector } from '@/features/Web3Connector'
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import ToastService from 'primevue/toastservice'
import Skeleton from 'primevue/skeleton'
import Ripple from 'primevue/ripple'
import Galleria from 'primevue/galleria'
import Checkbox from 'primevue/checkbox'
import { ValidationProvider } from 'vee-validate'
import VueCarousel from 'vue-carousel'
import * as _ from '@/utils/lodash-extended'
import VTooltip from 'v-tooltip'
import vClickOutside from 'v-click-outside'
import VueWindowSize from 'vue-window-size'
import VLazyImage from 'v-lazy-image/v2/v-lazy-image.es.js'
import InfiniteLoading from 'vue-infinite-loading'
import amplitude from 'amplitude-js'
import { DEFAULT_AMPLITUDE_API_KEY, APP_NAME, APP_VERSION } from '@/constants/index'
import Sentry from './sentry'

// @ts-ignore
import Jazzicon from 'vue-jazzicon'
import VueClipboard from 'vue-clipboard2'

// Style
import '@/assets/scss/main.scss'
import '@/assets/scss/tailwind.scss'
import '@/assets/scss/v-tooltip.scss'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

// utils
import '@/utils/viewFilter'
import '@/utils/veeValidate'

Vue.config.productionTip = false

Vue.use(PrimeVue, { ripple: true })
Vue.component('Dialog', Dialog)
Vue.component('Button', Button)
Vue.component('Sidebar', Sidebar)
Vue.component('Skeleton', Skeleton)
Vue.component('Galleria', Galleria)
Vue.component('Checkbox', Checkbox)
Vue.directive('ripple', Ripple)
Vue.component('jazzicon', Jazzicon)
Vue.use(ToastService)
Vue.use(VueClipboard)
Vue.use(VueCarousel)
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(vClickOutside)
Vue.use(VueWindowSize)
Vue.component('VLazyImage', VLazyImage)
Vue.use(InfiniteLoading)

Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click',
  popover: {
    defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
  }
})

Vue.prototype.$web3Connector = new Web3Connector()
Vue.prototype.$get = _.get

// Amplitude
amplitude.getInstance(APP_NAME).init(process.env.VUE_APP_AMPLITUDE_API_KEY ?? DEFAULT_AMPLITUDE_API_KEY)
amplitude.getInstance(APP_NAME).setTransport('beacon')
amplitude.getInstance(APP_NAME).setVersionName(APP_VERSION)

Vue.config.errorHandler = (err) => {
  Sentry.withScope(function(scope) {
    scope.setLevel(Sentry.Severity.Error)
    Sentry.captureException(err)
  })
  throw err // rethrow
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
