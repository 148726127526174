




















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { WADTokenFeeDiscountTear, MWADNFTFeeDiscountTear } from '@/types/index'

@Component({ name: 'TradeFeeDiscountTooltip' })
export default class PriceImpactTooltipForBsc extends Vue {
  @Prop({ required: false }) readonly mWadNFTFeeDiscountTear!: MWADNFTFeeDiscountTear[]
  @Prop({ required: false }) readonly wadTokenFeeDiscountTear!: WADTokenFeeDiscountTear[]
}
