































































































































































































































import { Component, Mixins, Watch, Emit } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { AbstractSentryView } from '@/features/Sentry/abstractView'
import { Modal } from '@/classes/modal'
import PercentageSelector from '@/components/PercentageSelector.vue'
import TradeFeeDiscountTooltip from '@/components/TradeFeeDiscountTooltip.vue'
import ImageCircle from '@/components/ImageCircle.vue'
import TradingFeeDiscount from '@/components/TradingFeeDiscount.vue'
import {
  loadUserStorage,
  getTokenImage
} from '@/utils/helper'
import { CurrentBestRateSdk, FeeDiscountType } from '@/types'
import { ChainIDThatSupport, ChainIdSupportWadToken } from '@/features/Web3Connector/types'
import { DEFAULT_PRICE_SLIPPAGE, NETWORK_CONSTANT } from '@/constants'
import { groupTokenAmountFromBestRateData } from '@/features/Swap/utils'
import { validate } from 'vee-validate'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'

@Component({
  name: 'ConfirmSwapModal',
  components: {
    PercentageSelector,
    TradeFeeDiscountTooltip,
    ImageCircle,
    TradingFeeDiscount
  },
  computed: mapGetters(['theme'])
})
export default class ConfirmSwapModal extends Mixins(AbstractSwapView, AbstractSentryView, Modal) {
  theme?: string
  chainIDThatSupport = ChainIDThatSupport
  feeDiscountType = FeeDiscountType

  isUserHoldWadToken = false
  SelectedToken = {}
  getTokenImage = getTokenImage
  priceSlippagePercentageList = [0.5, 0.8, 1.5]
  currentPriceSlippage: number | string = ''
  customPriceSlippage: string | null = ''
  tokenAAmountIn = '0'
  tokenBAmountOut = '0'
  confirmText = ''
  isValidConfirmText = false
  isValidCustomPriceSlippage = true

  priceImpactDesc = '<span>The difference between the market price and estimated price due to trade size.</span>'

  async handleBuyWadToken() {
    this.buyWardenTokenWithMinTokenAmount()
    this.isModalOpen = false
    this.amplitudeLogEvent('Click buy warden token on confirm swap modal', { wardenTokenPrice: this.displayWardenTokenPriceUsd })
  }

  checkIsValidBeforeConfirmSwap() {
    if (this.isOverMaxPriceImpact && this.isValidConfirmText) {
      const result = groupTokenAmountFromBestRateData(this.currentBestRate)
      this.amplitudeLogEvent('Input confirm swap when price is over price impact.', {
        tokenAAmount: result.amountIn,
        tokenBAmount: result.amountOut,
        tokenAAddress: this.tokenAInput.address,
        tokenBAddress: this.tokenBInput.address,
        priceImpact: this.priceImpact,
        userTypingConfirm: this.confirmText
      })
      this.handleConfirmSwap()
    }
  }

  displayPriceSlippage() {
    const currentUserStorage = loadUserStorage()
    const priceSlippagePercentage = currentUserStorage.priceSlippagePercentage as string
    if (this.priceSlippagePercentageList.includes(parseFloat(priceSlippagePercentage))) {
      this.currentPriceSlippage = priceSlippagePercentage
    } else {
      this.customPriceSlippage = priceSlippagePercentage
    }
  }

  isNumber(evt: any) {
    const charCode = (evt.which) ? evt.which : evt.keyCode
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      evt.preventDefault()
    } else {
      return true
    }
  }

  get totalValueUsdOfTokenA() {
    return this.tokenInputVolumeUsd(this.tokenAInput)
  }

  get totalValueUsdOfTokenB() {
    return this.tokenInputVolumeUsd(this.tokenBInput)
  }

  get swapAnywayBtnShouldDisabled() {
    return !this.isValidConfirmText || !this.isValidCustomPriceSlippage
  }

  get confirmSwapBtnShouldDisabled() {
    return !this.isValidCustomPriceSlippage
  }

  get currentBestRateVolumeUsd() {
    const result = groupTokenAmountFromBestRateData(this.currentBestRate)
    if (!result) {
      return {}
    }
    const tokenAVolumeUsd = this.getVolumeUsdOfToken(this.tokenAInput, result.amountIn)
    const tokenBVolumeUsd = this.getVolumeUsdOfToken(this.tokenBInput, result.amountOut)
    return { tokenAVolumeUsd, tokenBVolumeUsd }
  }

  get iconBarChart() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-bar-chart-space.svg')
    }

    return require('@/assets/svg/icon-bar-chart.svg')
  }

  get iconWarden() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-warden-no-bg-space.svg')
    }

    return require('@/assets/svg/icon-warden-no-bg.svg')
  }

  get iconPriceImpact() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-price-impact-space.svg')
    }

    return require('@/assets/svg/icon-price-impact.svg')
  }

  @Watch('currentBestRate', { deep: true })
  async handelCurrentBestRateChange(val: (CurrentBestRateSdk)[]) {
    const result = groupTokenAmountFromBestRateData(val)
    if (!result) {
      return
    }
    this.tokenAAmountIn = result.amountIn
    this.tokenBAmountOut = result.amountOut
  }

  @Watch('isModalOpen')
  async handleModalOpen(val: boolean) {
    if (val) {
      this.displayPriceSlippage()
      this.confirmText = ''
      this.isValidConfirmText = false
      this.amplitudeLogEvent('ConfirmSwapModal is open')
      try {
        const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
        if (wardenTokenData) {
          this.getTokensBalance([wardenTokenData])
        }
      } catch (error) {
        this.sentryLogError(this.network, error, 'Error')
        console.error(error)
      }
    } else {
      this.amplitudeLogEvent('ConfirmSwapModal is close')
    }
  }

  @Watch('currentPriceSlippage', { deep: true })
  async updatePriceSlippage(newVal: string | number, oldVal: string | number) {
    this.amplitudeLogEvent('Select price slippage', { priceSlippagePercentage: newVal.toString() })
    if (oldVal.toString() === newVal.toString()) {
      return
    }
    this.setPriceSlippage(newVal)
    this.isValidCustomPriceSlippage = true
    if (newVal !== this.customPriceSlippage) {
      this.customPriceSlippage = null
    }
  }

  @Watch('customPriceSlippage')
  async setCustomPriceSlippage(val: string | null | '') {
    this.amplitudeLogEvent('Input custom price slippage', { priceSlippagePercentage: val })
    if (val === null) {
      return
    }
    const { valid } = await validate(val, 'required|priceSlippageBetween:0.01,50')
    if (!valid && val !== '') {
      this.isValidCustomPriceSlippage = false
      this.setPriceSlippage(DEFAULT_PRICE_SLIPPAGE)
      return
    }
    if (val !== '') {
      this.currentPriceSlippage = val
    } else {
      this.currentPriceSlippage = DEFAULT_PRICE_SLIPPAGE
    }
    this.isValidCustomPriceSlippage = true
  }

  @Watch('confirmText')
  async confirmTextChange(val: string) {
    const result = await validate(val, 'required|confirmSwap')
    this.isValidConfirmText = result.valid
  }

  @Emit()
  handleConfirmSwap() {
    this.isModalOpen = false
  }
}
