



























































































import { Component, Mixins, Ref } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { AbstractMwadNftView } from '@/features/MwadNft/abstractView'
import { AbstractOptiPunkNftView } from '@/features/OptiPunkNft/abstractView'
import ConnectWalletModal from '@/components/ConnectWalletModal.vue'
import UserWalletModal from '@/components/UserWalletModal.vue'
import LazyImageCircle from '@/components/LazyImageCircle.vue'
import SwitchNetworkAndConnectWalletModal from '@/features/SwitchNetwork/SwitchNetworkAndConnectWalletModal.vue'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'

@Component({
  name: 'ConnectWalletButtonOnMobile',
  components: {
    ConnectWalletModal,
    UserWalletModal,
    SwitchNetworkAndConnectWalletModal,
    LazyImageCircle
  },
  computed: mapGetters(['theme'])
})

export default class ConnectWalletButtonOnMobile extends Mixins(AbstractWeb3ConnectorView, AbstractMwadNftView, AbstractOptiPunkNftView) {
  theme?: string

  @Ref() readonly UserWalletModal!: UserWalletModal
  @Ref() readonly SwitchNetworkAndConnectWalletModal!: SwitchNetworkAndConnectWalletModal

  get iconShape() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-shape-space.svg')
    }

    return require('@/assets/svg/icon-shape.svg')
  }
}
