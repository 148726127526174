import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { APP_NAME } from '@/constants'
import amplitude from 'amplitude-js'
import { AmplitudeUserProperties } from '../types'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
@Component
export class AbstractAmplitudeView extends Vue {
  @Web3ConnectorState public readonly networkId!: number

  amplitudeLogEvent(eventName: string, eventProperties: null | object = null) {
    amplitude.getInstance(APP_NAME).logEvent(eventName, eventProperties)
  }

  amplitudeSetNftUserProperties(nftId: number, nftClass: string) {
    const userProperties: any = {}
    if (this.networkId === ChainIDThatSupport.bsc) {
      userProperties[AmplitudeUserProperties.BSC_NFT_ID] = nftId
      userProperties[AmplitudeUserProperties.BSC_NFT_CLASS] = nftClass
    } else if (this.networkId === ChainIDThatSupport.optimism) {
      userProperties[AmplitudeUserProperties.OPTIMISM_OPTIPUNK_NFT_ID] = nftId
      userProperties[AmplitudeUserProperties.OPTIMISM_OPTIPUNK_NFT_CLASS] = nftClass
    }
    amplitude.getInstance(APP_NAME).setUserProperties(userProperties)
  }

  amplitudeUnsetNftUserProperties() {
    const identify = new amplitude.Identify()
    if (this.networkId === ChainIDThatSupport.bsc) {
      identify.unset(AmplitudeUserProperties.BSC_NFT_ID)
      identify.unset(AmplitudeUserProperties.BSC_NFT_CLASS)
    } else if (this.networkId === ChainIDThatSupport.optimism) {
      identify.unset(AmplitudeUserProperties.OPTIMISM_OPTIPUNK_NFT_ID)
      identify.unset(AmplitudeUserProperties.OPTIMISM_OPTIPUNK_NFT_CLASS)
    }
    amplitude.getInstance(APP_NAME).identify(identify)
  }
}
