

















































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { Modal } from '@/classes/modal'
import SwitchNetworkList from './SwitchNetworkList.vue'
import { ChainIDThatSupport, WalletProvider, Network } from '@/features/Web3Connector/types'
import { AbstractAmplitudeView } from '../Amplitude/abstractView'

@Component({
  name: 'SwitchNetworkAndConnectWalletModal',
  components: {
    SwitchNetworkList
  }
})

export default class SwitchNetworkAndConnectWalletModal extends Mixins(AbstractWeb3ConnectorView, AbstractAmplitudeView, Modal) {
  chainIDThatSupport = ChainIDThatSupport
  walleTypes = [
    {
      iconPath: require('@/assets/svg/icon-metamask.svg'),
      displayText: 'MetaMask',
      walletNameSupport: 'MetaMask',
      walletProvider: [WalletProvider.INJECTED_WEB3],
      chainSupport: [ChainIDThatSupport.bsc, ChainIDThatSupport.polygon, ChainIDThatSupport.arbitrum, ChainIDThatSupport.ethereum, ChainIDThatSupport.avalanche, ChainIDThatSupport.optimism]
    },
    {
      iconPath: require('@/assets/svg/icon-walletconnect.svg'),
      displayText: 'WalletConnect',
      walletProvider: [WalletProvider.WALLET_CONNECT],
      chainSupport: [ChainIDThatSupport.bsc, ChainIDThatSupport.polygon, ChainIDThatSupport.arbitrum, ChainIDThatSupport.ethereum, ChainIDThatSupport.avalanche, ChainIDThatSupport.optimism]
    },
    {
      iconPath: require('@/assets/svg/icon-safepal.svg'),
      displayText: 'SafePal',
      walletNameSupport: 'Web3',
      walletProvider: [WalletProvider.INJECTED_WEB3],
      chainSupport: [ChainIDThatSupport.bsc, ChainIDThatSupport.polygon, ChainIDThatSupport.ethereum, ChainIDThatSupport.arbitrum, ChainIDThatSupport.avalanche, ChainIDThatSupport.optimism]
    },
    {
      iconPath: require('@/assets/svg/icon-trust-wallet.svg'),
      displayText: 'Trust Wallet',
      walletNameSupport: 'Trust',
      walletProvider: [WalletProvider.INJECTED_WEB3],
      chainSupport: [ChainIDThatSupport.bsc, ChainIDThatSupport.polygon, ChainIDThatSupport.ethereum, ChainIDThatSupport.arbitrum, ChainIDThatSupport.avalanche, ChainIDThatSupport.optimism]
    },
    {
      iconPath: require('@/assets/svg/icon-coinbase-wallet.svg'),
      displayText: 'Coinbase Wallet',
      walletNameSupport: 'Coinbase Wallet',
      walletProvider: [WalletProvider.INJECTED_WEB3, WalletProvider.COINBASE_CONNECT],
      chainSupport: [ChainIDThatSupport.bsc, ChainIDThatSupport.polygon, ChainIDThatSupport.arbitrum, ChainIDThatSupport.ethereum, ChainIDThatSupport.avalanche, ChainIDThatSupport.optimism]
    }
  ]

  async selectedWallet(walletProvider: WalletProvider) {
    if (walletProvider === this.walletProvider) {
      return
    }
    await this.connect(walletProvider)
    this.isModalOpen = false
  }

  async handleSelectNetwork(networkType: Network) {
    try {
      type NetworkExclude = Exclude<Network, Network.private>
      this.amplitudeLogEvent('Select network in SwitchNetworkAndConnectWalletModal', { network: networkType })
      const netId = ChainIDThatSupport[networkType as NetworkExclude]
      await this.switchNetwork(netId)
    } catch (error) {
      console.error(error)
    }
  }

  @Watch('isModalOpen')
  resetWrongNetworkNoticeOnModal(val: boolean) {
    if (val) {
      this.closeWrongNetworkNoticeOnModal()
      this.amplitudeLogEvent('SwitchNetworkAndConnectWalletModal is open')
    } else {
      this.amplitudeLogEvent('SwitchNetworkAndConnectWalletModal is close')
    }
  }
}
