




























































import { Component, Prop } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Token, NewUserLocalStorage } from '@/types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import ImageCircle from '@/components/ImageCircle.vue'
import {
  getTokenImage,
  precisionNumber,
  numberWithCommas,
  loadUserStorage,
  addTokenInlocalStorage,
  removeTokenInlocalStorage
} from '@/utils/helper'
import bscTokens from '@/constants/token/bscTokens.json'
import polygontokens from '@/constants/token/polygonTokens.json'
import arbitrumTokens from '@/constants/token/arbitrumTokens.json'
import ethTokens from '@/constants/token/ethTokens.json'
import avalancheTokens from '@/constants/token/avalancheTokens.json'
import optimismTokens from '@/constants/token/optimismTokens.json'

@Component({
  name: 'TokenDetailWrap',
  components: {
    ImageCircle
  }
})
export default class TokenDetailWrap extends AbstractSwapView {
  @Prop() readonly token!: Token
  getTokenImage = getTokenImage
  currentUserStorage = {} as NewUserLocalStorage

  created() {
    this.currentUserStorage = loadUserStorage()
  }

  get shouldDisplayTokenBalance(): boolean {
    if (this.token?.address && this.tokensBalance.hasOwnProperty(this.token.address)) {
      return true
    }
    return false
  }

  get isCustomToken(): boolean {
    let tokenForCheck
    switch (this.networkId) {
      case ChainIDThatSupport.bsc :
        tokenForCheck = bscTokens
        break
      case ChainIDThatSupport.polygon :
        tokenForCheck = polygontokens
        break
      case ChainIDThatSupport.arbitrum :
        tokenForCheck = arbitrumTokens
        break
      case ChainIDThatSupport.ethereum :
        tokenForCheck = ethTokens
        break
      case ChainIDThatSupport.avalanche :
        tokenForCheck = avalancheTokens
        break
      case ChainIDThatSupport.optimism :
        tokenForCheck = optimismTokens
        break
      default:
        return false
    }
    return !tokenForCheck.some(token => token.address.toLowerCase() === this.token?.address?.toLowerCase())
  }

  get isTokenInLocalStorage(): boolean {
    if (this.currentUserStorage?.tokens[this.networkId]?.hasOwnProperty(this.token.address as string)) {
      return true
    }
    return false
  }

  handleAddTokenInLocalStorage() {
    const tokenData: Token = {
      name: this.token.name,
      symbol: this.token.symbol,
      address: this.token.address,
      decimals: this.token.decimals
    }
    if (!this.allToken.some((token: Token) => token?.address === this.token.address)) {
      this.increaseTokenInState(tokenData)
    }
    addTokenInlocalStorage(tokenData, this.networkId)
    this.currentUserStorage = loadUserStorage()
    this.getTokensBalance()
    this.amplitudeLogEvent('Click icon import token', tokenData)
  }

  handleRemoveTokenInLocalStorage() {
    this.decreaseTokenInState(this.token.address)
    removeTokenInlocalStorage(this.token.address, this.networkId)
    this.currentUserStorage = loadUserStorage()

    const tokenData: Token = {
      name: this.token.name,
      symbol: this.token.symbol,
      address: this.token.address,
      decimals: this.token.decimals
    }
    this.amplitudeLogEvent('Click icon remove token', tokenData)
  }

  displayTokensBalance(tokenAddress: any) {
    return numberWithCommas((precisionNumber(this.tokensBalance[tokenAddress], 6)).toString(10))
  }
}
