export class CustomError extends Error {
  constructor(msg: string, data?: object) {
    super(msg)
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CustomError.prototype)
    if (data && Reflect.ownKeys(data).length) {
      Object.defineProperty(this, 'data', { value: data })
    }
  }
}
