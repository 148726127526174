





































import { Component } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
// Logic for random slide show
// :activeIndex="Math.floor(Math.random() * images.length)"
interface CarouselImage {
  itemImageSrc: any;
  link: string;
  displayOnNetworks: ChainIDThatSupport[];
}

@Component({
  name: 'BannerCarousel'
})
export default class BannerCarousel extends AbstractWeb3ConnectorView {
  prevLabel = `<img src='${require('@/assets/svg/icon-left-arrow.svg')}'/>`
  nextLabel = `<img src='${require('@/assets/svg/icon-right-arrow.svg')}'/>`

  images: CarouselImage[] = [
    {
      itemImageSrc: require('@/assets/png/banner-bsc-token-list-v3.png'),
      link: '#',
      displayOnNetworks: [ChainIDThatSupport.bsc]
    },
    {
      itemImageSrc: require('@/assets/png/banner-bsc-token-list-v3-2.png'),
      link: '#',
      displayOnNetworks: [ChainIDThatSupport.bsc]
    },
    {
      itemImageSrc: require('@/assets/png/banner-op-token-list-v3.png'),
      link: '#',
      displayOnNetworks: [ChainIDThatSupport.optimism]
    },
    {
      itemImageSrc: require('@/assets/png/banner-arb-token-list-v3.png'),
      link: '#',
      displayOnNetworks: [ChainIDThatSupport.arbitrum]
    },
    {
      itemImageSrc: require('@/assets/png/banner-eth-token-list-v3.png'),
      link: '#',
      displayOnNetworks: [ChainIDThatSupport.ethereum]
    },
    {
      itemImageSrc: require('@/assets/png/banner-polygon-bloodstone.png'),
      link: 'https://bit.ly/34UWNgk',
      displayOnNetworks: [ChainIDThatSupport.polygon]
    },
    // {
    //   itemImageSrc: require('@/assets/png/banner-bloodstone.png'),
    //   link: 'https://bit.ly/3u1510N',
    //   displayOnNetworks: [ChainIDThatSupport.bsc]
    // },
    // {
    //   itemImageSrc: require('@/assets/png/banner-big-3.0-alpha'),
    //   link: 'https://bit.ly/3qUaaVz',
    //   displayOnNetworks: [ChainIDThatSupport.bsc]
    // },
    {
      itemImageSrc: require('@/assets/svg/banner-polygon-free-trading-fee-stablecoin.svg'),
      link: 'https://bit.ly/2ZTScZ6',
      displayOnNetworks: [ChainIDThatSupport.polygon]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-new-trading-fee-scheme.svg'),
      link: 'https://bit.ly/38yGK6t',
      displayOnNetworks: [ChainIDThatSupport.bsc]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-iron.svg'),
      link: 'https://bit.ly/3zEYDfd',
      displayOnNetworks: [ChainIDThatSupport.polygon]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-polydex.svg'),
      link: 'https://bit.ly/3x5O9Ej',
      displayOnNetworks: [ChainIDThatSupport.polygon]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-balancer-v2.svg'),
      link: 'https://bit.ly/2V9162K',
      displayOnNetworks: [ChainIDThatSupport.polygon]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-kyber.svg'),
      link: 'https://bit.ly/3BKms7y',
      displayOnNetworks: [ChainIDThatSupport.polygon]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-dodo.svg'),
      link: 'https://bit.ly/3v4LQjA',
      displayOnNetworks: [ChainIDThatSupport.bsc]
    },
    {
      itemImageSrc: require('@/assets/svg/banner-ape.svg'),
      link: 'https://bit.ly/3xyWZLY',
      displayOnNetworks: [ChainIDThatSupport.bsc]
    }
  ]

  openLinkNewTab(uri: string) {
    window.open(uri, '_blank')
  }

  isMobile() {
    return window.innerWidth <= 768
  }

  get carouselImageByNetwork() {
    return this.images.filter((image: CarouselImage) => {
      return image.displayOnNetworks.includes(this.networkId)
    })
  }
}
