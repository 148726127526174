






































































import { Component, Mixins } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { WalletProvider } from '@/features/Web3Connector/types/Wallet'
import { Modal } from '@/classes/modal'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import ImageCircle from '@/components/ImageCircle.vue'

@Component({
  components: {
    ImageCircle
  }
})
export default class ConnectWalletButton extends Mixins(
  AbstractWeb3ConnectorView,
  Modal
) {
  chainIDThatSupport = ChainIDThatSupport

  async selectedWallet(walletProvider: WalletProvider) {
    await this.connect(walletProvider)
    this.isModalOpen = false
    if (!this.isCorrectNetwork && typeof window.ethereum !== 'undefined') {
      await this.walletAddChainRpc(this.networkId)
      await this.connect(walletProvider)
    }
  }
}
