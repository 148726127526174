import { Component, Watch, Vue } from 'vue-property-decorator'
import { vueWindowSize } from 'vue-window-size'

@Component
export class Modal extends Vue {
  isModalOpen = false
  modalPosition = 'center'

  mounted() {
    vueWindowSize.init()
  }

  destroyed() {
    vueWindowSize.destroy()
  }

  created() {
    this.handleModalPosition()
  }

  showModal() {
    this.isModalOpen = true
  }

  closeModal() {
    this.isModalOpen = false
  }

  handleModalPosition() {
    if (this.isMobile) {
      this.modalPosition = 'bottom'
    } else {
      this.modalPosition = 'center'
    }
  }

  get isMobile() {
    return this.windowWidth <= 768
  }

  @Watch('isMobile')
  onIsMobileChange() {
    this.handleModalPosition()
  }
}
