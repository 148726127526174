import { ChainIDThatSupport } from './types/Web3'
import { web3ConnectorModule } from './store'
import { WardenBestRate as WardenBestRateSdk, log as logSdk, LogLevel as logLevel } from '@wardenswap/bestrate-sdk'
import { ethers } from 'ethers'
import Web3 from 'web3'
import { getGasFeeDataFromApi } from '@/resources/thirdPartyApi'
import * as _ from '@/utils/lodash-extended'
import amplitude from 'amplitude-js'
import { getAddress } from '@ethersproject/address'
import {
  APP_NAME,
  WALLET_PROVIDER_LOCAL_STORAGE_KEY,
  NETWORK_CONSTANT,
  DEFAULT_NETWORK_ID
} from '@/constants'
import {
  getNetwork,
  getConnector,
  getCurrentNetworkIdFromLocalStorage,
  saveCurrentNetworkIdInlocalStorage,
  delay
} from './utils'
import { CustomError } from '@/classes/CustomErrorClass'
import { WardenswapSdkVersion, WardenBestRateSdkTagVersion } from '@/features/Swap/types'
import { AmplitudeUserProperties } from '@/features/Amplitude/types'
import { WalletConnector, WalletProvider, ErrorMessage, GasfeeData, GasSpeed, GasFeeDataSourceNo } from './types'
import * as Sentry from '@sentry/vue'
const gasSpeedList = Object.values(GasSpeed)

export class Web3Connector {
  connector: WalletConnector | null = null
  constructor() {
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleDisconnect = this.handleDisconnect.bind(this)
  }

  public async connecTo(connector: WalletConnector) {
    this.connector = connector

    let resultConnector = null
    // In mobile mode If web3Modal can't connect within 2sec should refresh web page
    const promiseEvent = [connector.connect()]
    if (window.innerWidth <= 768) {
      promiseEvent.push(delay(2000).then(() => { throw Error(ErrorMessage.REFRESH_WEB_PAGE) })) // delay 2sec
    }
    resultConnector = await Promise.race(promiseEvent)
    const {
      provider,
      web3, accounts, walletInfo
    } = resultConnector

    web3ConnectorModule.setWalletProvider(walletInfo.provider)
    web3ConnectorModule.setIsWalletConnected(true)
    const currentNetworkId: ChainIDThatSupport = await web3.eth.net.getId()
    if (!this.isCorrectNetwork(currentNetworkId)) {
      web3ConnectorModule.setIsCorrectNetwork(false)
      this.unwatchWalletEvents(provider)
      this.watchWalletEvents(provider)
      throw new CustomError(ErrorMessage.WRONG_NETWORK, { networkId: currentNetworkId })
    }

    web3ConnectorModule.setUserAddress(accounts[0])
    web3ConnectorModule.setWalletName(walletInfo.name)
    web3ConnectorModule.setWalletType(walletInfo.type)
    web3ConnectorModule.setWalletLogo(walletInfo.logo)
    web3ConnectorModule.setProvider(provider)
    web3ConnectorModule.setIsWrongNetworkNoticeOnModal(false)
    web3ConnectorModule.setIsWrongNetworkNoticeOnHeader(false)

    const identify = new amplitude.Identify()
      .set(AmplitudeUserProperties.WALLET_ADDRESS, getAddress(accounts[0]))
      .set(AmplitudeUserProperties.WALLET_NAME, walletInfo.name)
    amplitude.getInstance(APP_NAME).identify(identify)

    const ethersProvider = new ethers.providers.Web3Provider(provider)

    const feeData: any = await ethersProvider.getFeeData()
    const isWalletProviderSupportEip1559 = feeData.maxFeePerGas !== null && feeData.maxPriorityFeePerGas !== null
    web3ConnectorModule.setIsWalletProviderSupportEip1559(isWalletProviderSupportEip1559)
    web3ConnectorModule.setEthersProvider(ethersProvider)

    // HOTFIX: Issue on mobile
    if (window.innerWidth <= 768 && !window.ethereum?.isMetaMask) {
      // For mobile provider Web3 don't have sendAsync()
      const httpProvider = new Web3.providers.HttpProvider(NETWORK_CONSTANT[currentNetworkId].RPC_OFFICIAL_URL)
      const web3ForRead = new Web3(httpProvider)
      web3ConnectorModule.setWeb3(web3ForRead)
    } else {
      web3ConnectorModule.setWeb3(web3)
    }

    const netId = await web3.eth.net.getId()
    this.setGasPrice(netId, ethersProvider).then(() => {
      // Set wardenswap SDK
      this.manageWardenBestRateSdk(ethersProvider, currentNetworkId)
    })

    const network = getNetwork(netId)
    await web3ConnectorModule.setNetwork(network)
    web3ConnectorModule.setNetworkId(netId)
    web3ConnectorModule.setIsCorrectNetwork(this.isCorrectNetwork(netId))
    saveCurrentNetworkIdInlocalStorage(netId)
    this.unwatchWalletEvents(provider)
    this.watchWalletEvents(provider)
    Sentry.configureScope(function(scope) {
      scope.setTag('network', network)
      scope.setUser({
        id: accounts[0]
      })
      scope.setTag('walletAddress', accounts[0])
    })

    amplitude
      .getInstance(APP_NAME)
      .identify(new amplitude.Identify().set(AmplitudeUserProperties.NETWORK, network))

    return {
      web3,
      accounts,
      provider,
      walletInfo
    }
  }

  async connectContractForRead() {
    let currentNetworkId: ChainIDThatSupport = getCurrentNetworkIdFromLocalStorage()

    // If localstorage store wrong network id, should save default network id
    if (!(currentNetworkId in ChainIDThatSupport)) {
      saveCurrentNetworkIdInlocalStorage(DEFAULT_NETWORK_ID)
      currentNetworkId = DEFAULT_NETWORK_ID
    }

    const ethersProvider = new ethers.providers.JsonRpcProvider(NETWORK_CONSTANT[currentNetworkId].RPC_OFFICIAL_URL)
    web3ConnectorModule.setEthersProvider(ethersProvider)
    web3ConnectorModule.setIsWalletProviderSupportEip1559(null)

    const web3ForRead = new Web3(NETWORK_CONSTANT[currentNetworkId].RPC_OFFICIAL_URL)
    web3ConnectorModule.setWeb3(web3ForRead)

    this.setGasPrice(currentNetworkId).then(() => {
      // Set wardenswap SDK
      this.manageWardenBestRateSdk(ethersProvider, currentNetworkId)
    })

    const network = getNetwork(currentNetworkId)
    await web3ConnectorModule.setNetwork(network)
    web3ConnectorModule.setNetworkId(currentNetworkId)
    web3ConnectorModule.setIsCorrectNetwork(this.isCorrectNetwork(currentNetworkId))

    amplitude
      .getInstance(APP_NAME)
      .identify(new amplitude.Identify().set(AmplitudeUserProperties.NETWORK, network))
  }

  private getWardenBestRateSdkByNetworkId(providers: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider, networkId: ChainIDThatSupport) {
    const ethersProviderForSdk = _.cloneDeep(providers)
    const networkName: any = getNetwork(networkId)
    // @ts-ignore
    const wardenBestRateSdk = new WardenBestRateSdk(ethersProviderForSdk, networkName) as WardenBestRateSdkTagVersion
    wardenBestRateSdk.sdkVersion = WardenswapSdkVersion.NEW_VERION_2

    return wardenBestRateSdk
  }

  async manageWardenBestRateSdk(providers: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider, currentNetworkId: ChainIDThatSupport) {
    const currentEthersProviders = _.cloneDeep(providers)
    // TODO: disabled eth node
    // if (currentNetworkId === ChainIDThatSupport.ethereum) {
    //   try {
    //     const providersTemp = new ethers.providers.JsonRpcProvider(NETWORK_CONSTANT[currentNetworkId as ChainIDThatSupport.ethereum].RPC_WARDEN_URL)
    //     await providersTemp.getBlockNumber()
    //     currentEthersProviders = providersTemp
    //   } catch (error) {
    //     console.error('Warden rpc error', error)
    //     const network = getNetwork(currentNetworkId)
    //     Sentry.withScope(function(scope: Sentry.Scope) {
    //       scope.setLevel(Sentry.Severity.Critical)
    //       scope.setFingerprint([network, error.message])
    //       Sentry.captureException(error)
    //     })
    //   }
    // }
    // Set warden best rate SDK 1
    const wardenBestRateSdk1 = this.getWardenBestRateSdkByNetworkId(currentEthersProviders, currentNetworkId)
    web3ConnectorModule.setWardenBestRateSdk1(wardenBestRateSdk1)

    logSdk.level = logLevel.SILENT

    if (process.env.VUE_APP_ENV === 'local' && process.env?.VUE_APP_WARDEN_BEST_RATE_SDK_INSPECT_LOG === 'true') {
      logSdk.level = logLevel.DEBUG
    }

    // Set warden best rate SDK 2 from warden rpc provider
    if ([ChainIDThatSupport.polygon, ChainIDThatSupport.arbitrum].includes(currentNetworkId)) {
      const ethersProviderForSdk2 = new ethers.providers.JsonRpcProvider(NETWORK_CONSTANT[currentNetworkId as ChainIDThatSupport.polygon | ChainIDThatSupport.arbitrum].RPC_WARDEN_URL)
      const wardenBestRateSdk2 = this.getWardenBestRateSdkByNetworkId(ethersProviderForSdk2, currentNetworkId)
      web3ConnectorModule.setWardenBestRateSdk2(wardenBestRateSdk2)
    } else {
      // Reset best rate SDK 2 to null when user swap chain from (polygon, arbitrum) to bsc
      web3ConnectorModule.setWardenBestRateSdk2(null)
    }
  }

  async disconnect() {
    console.log('disconnect')
    web3ConnectorModule.setNetwork('disconnected')
    web3ConnectorModule.setNetworkId(0)
    const currentNetworkId: ChainIDThatSupport = getCurrentNetworkIdFromLocalStorage()
    web3ConnectorModule.setIsCorrectNetwork(this.isCorrectNetwork(currentNetworkId))
    this.unwatchWalletEvents(web3ConnectorModule.provider)
    this.clearData()

    const identify = new amplitude.Identify()
      .unset(AmplitudeUserProperties.WALLET_ADDRESS)
      .unset(AmplitudeUserProperties.WALLET_NAME)
      .unset(AmplitudeUserProperties.NETWORK)
    amplitude.getInstance(APP_NAME).identify(identify)
    if (web3ConnectorModule.web3 !== null) {
      this.connector?.disconnect()
    }
    this.connectContractForRead()
  }

  clearData() {
    web3ConnectorModule.setWalletProvider(null)
    web3ConnectorModule.setWeb3(null)
    web3ConnectorModule.setUserAddress(null)
    web3ConnectorModule.setWalletName(null)
    web3ConnectorModule.setWalletType(null)
    web3ConnectorModule.setWalletLogo(null)
    web3ConnectorModule.setProvider(null)
    web3ConnectorModule.setIsWalletConnected(false)
    web3ConnectorModule.setIsWrongNetworkNoticeOnModal(false)
    web3ConnectorModule.setIsWrongNetworkNoticeOnHeader(false)
  }

  watchWalletEvents(provider: any) {
    if (provider && provider.on) {
      // Subscribe to accounts change
      provider.on('accountsChanged', this.handleAccountsChanged)

      // Subscribe to chainId change
      provider.on('chainChanged', this.handleChainChanged)

      // Subscribe to provider disconnection
      provider.on('disconnect', this.handleDisconnect)
    }
  }

  private async handleChainChanged(chainId: string) {
    console.log('Handling \'ChainChanged\' event with payload', parseInt(chainId))
    const walletProviderLocalStorageName = `${APP_NAME}.${WALLET_PROVIDER_LOCAL_STORAGE_KEY}`
    const walletProvider: WalletProvider = localStorage.getItem(walletProviderLocalStorageName) as WalletProvider || WalletProvider.INJECTED_WEB3
    const connector = await getConnector(walletProvider)
    await this.connecTo(connector)
  }

  private handleAccountsChanged(accounts: string[]): void {
    console.log('Handling \'accountsChanged\' event with payload', accounts)
    if (accounts[0] === undefined) {
      this.clearData()
      this.connectContractForRead()
    }
    web3ConnectorModule.setUserAddress(accounts[0])
    const identify = new amplitude.Identify().set(AmplitudeUserProperties.WALLET_ADDRESS, getAddress(accounts[0]))
    amplitude.getInstance(APP_NAME).identify(identify)

    Sentry.configureScope(function(scope) {
      scope.setUser({
        id: accounts[0]
      })
      scope.setTag('walletAddress', accounts[0])
    })
  }

  private handleDisconnect(error: { code: number; message: string }): void {
    console.log('Handling \'disconnect\' event')
    // Event from swap chain should not disconnect
    if ([1011, 1013].includes(error.code)) {
      return
    }
    this.disconnect()
  }

  private isCorrectNetwork(chainId: number): boolean {
    if (chainId in ChainIDThatSupport) {
      return true
    }
    return false
  }

  public async setGasPrice(networkId: ChainIDThatSupport, ethersProvider?: ethers.providers.Web3Provider) {
    const network = getNetwork(networkId)
    try {
      if ([ChainIDThatSupport.optimism, ChainIDThatSupport.arbitrum].includes(networkId)) {
        const [gasFeeDataL1, gasFeeDataL2] = await Promise.all([
          getGasFeeDataFromApi(ChainIDThatSupport.ethereum),
          getGasFeeDataFromApi(networkId)
        ])
        if (Reflect.ownKeys(gasFeeDataL1) && Reflect.ownKeys(gasFeeDataL2)) {
          await web3ConnectorModule.setGasfeeData(gasFeeDataL1)
          await web3ConnectorModule.setGasfeeL2Data(gasFeeDataL2)
        } else {
          throw new Error('Gas fee data not found')
        }
      } else {
        const gasFeeData = await getGasFeeDataFromApi(networkId)
        if (Reflect.ownKeys(gasFeeData)) {
          await web3ConnectorModule.setGasfeeData(gasFeeData)
        } else {
          throw new Error('Gas fee data not found')
        }
      }
    } catch (error) {
      Sentry.withScope(function(scope: Sentry.Scope) {
        scope.setLevel(Sentry.Severity.Warning)
        scope.setFingerprint([network, error.message])
        Sentry.captureException(error)
      })
      console.error('Error: Can\'t get gas price from rpc endpoint', error)
      const gasFeeData: GasfeeData = {} as GasfeeData
      if ([ChainIDThatSupport.bsc, ChainIDThatSupport.polygon, ChainIDThatSupport.arbitrum, ChainIDThatSupport.avalanche].includes(networkId)) {
        gasFeeData.dataSourceNo = GasFeeDataSourceNo.CONSTANT_VALUE
        gasSpeedList.forEach(type => {
          gasFeeData[type] = {
            gasPriceWei: NETWORK_CONSTANT[networkId as ChainIDThatSupport.bsc | ChainIDThatSupport.polygon | ChainIDThatSupport.arbitrum | ChainIDThatSupport.avalanche].DEFAULT_GAS_PRICE
          }
        })
        web3ConnectorModule.setGasfeeData(gasFeeData)
      } else if (networkId === ChainIDThatSupport.ethereum) {
        this.handleGetGasPriceErrorForEthereum(networkId, ethersProvider)
      } else if (networkId === ChainIDThatSupport.optimism) {
        this.handleGetGasPriceErrorForEthereumL2(networkId, ethersProvider)
      }
    }
  }

  async handleGetGasPriceErrorForEthereum(networkId: ChainIDThatSupport.ethereum, ethersProvider?: ethers.providers.Web3Provider) {
    const network = getNetwork(networkId)
    let gasPrice: string
    const gasFeeData: GasfeeData = {} as GasfeeData

    try {
      gasPrice = await this.getGasPriceFromCustomRpcNode(networkId)
      gasFeeData.dataSourceNo = GasFeeDataSourceNo.SECOND
    } catch (error) {
      Sentry.withScope(function(scope: Sentry.Scope) {
        scope.setLevel(Sentry.Severity.Warning)
        scope.setFingerprint([network, error.message])
        Sentry.captureException(error)
      })
      try {
        if (!ethersProvider) {
          throw new Error('For etherum network sould import params ethersProvider')
        }
        gasPrice = await this.getGasPriceFromUserWalletProvider(ethersProvider)
        gasFeeData.dataSourceNo = GasFeeDataSourceNo.THIRD
      } catch (error) {
        Sentry.withScope(function(scope: Sentry.Scope) {
          scope.setLevel(Sentry.Severity.Critical)
          scope.setFingerprint([network, error.message])
          Sentry.captureException(error)
        })
        throw new Error('Get gas price failed')
      }
    }

    gasSpeedList.forEach(type => {
      gasFeeData[type] = {
        gasPriceWei: gasPrice
      }
    })
    web3ConnectorModule.setGasfeeData(gasFeeData)
  }

  async handleGetGasPriceErrorForEthereumL2(networkId: ChainIDThatSupport.optimism, ethersProvider?: ethers.providers.Web3Provider) {
    // Manage L1
    const gasFeeL1Data: GasfeeData = {} as GasfeeData
    const gasPriceL1 = await this.getGasPriceFromCustomRpcNode(ChainIDThatSupport.ethereum)
    gasFeeL1Data.dataSourceNo = GasFeeDataSourceNo.SECOND
    gasSpeedList.forEach(type => {
      gasFeeL1Data[type] = {
        gasPriceWei: gasPriceL1
      }
    })
    web3ConnectorModule.setGasfeeData(gasFeeL1Data)

    // Manage L2
    const network = getNetwork(networkId)
    let gasPriceL2: string
    const gasFeeL2Data: GasfeeData = {} as GasfeeData
    try {
      gasPriceL2 = await this.getGasPriceFromCustomRpcNode(networkId)
      gasFeeL2Data.dataSourceNo = GasFeeDataSourceNo.SECOND
    } catch (error) {
      Sentry.withScope(function(scope: Sentry.Scope) {
        scope.setLevel(Sentry.Severity.Warning)
        scope.setFingerprint([network, error.message])
        Sentry.captureException(error)
      })
      try {
        if (!ethersProvider) {
          throw new Error('For etherum network sould import params ethersProvider')
        }
        gasPriceL2 = await this.getGasPriceFromUserWalletProvider(ethersProvider)
        gasFeeL2Data.dataSourceNo = GasFeeDataSourceNo.THIRD
      } catch (error) {
        Sentry.withScope(function(scope: Sentry.Scope) {
          scope.setLevel(Sentry.Severity.Critical)
          scope.setFingerprint([network, error.message])
          Sentry.captureException(error)
        })
        throw new Error('Get gas price failed')
      }
    }

    gasSpeedList.forEach(type => {
      gasFeeL2Data[type] = {
        gasPriceWei: gasPriceL2
      }
    })
    web3ConnectorModule.setGasfeeL2Data(gasFeeL2Data)
  }

  unwatchWalletEvents(provider: any) {
    if (provider && provider.removeListener) {
      if (provider.stop) {
        provider.stop()
      }
      provider.removeListener('accountsChanged', this.handleAccountsChanged)
      provider.removeListener('disconnect', this.handleDisconnect)
      provider.removeListener('chainChanged', this.handleChainChanged)
    }
  }

  async getGasPriceFromCustomRpcNode(networkId: ChainIDThatSupport.ethereum | ChainIDThatSupport.optimism) {
    const ethersProvider = new ethers.providers.JsonRpcProvider(NETWORK_CONSTANT[networkId].RPC_WARDEN_URL)
    const feeData = await ethersProvider.getFeeData()
    if (feeData.gasPrice) {
      return feeData.gasPrice.toString()
    }
    throw new Error('Can\'t get gas price from infura rpc node')
  }

  async getGasPriceFromUserWalletProvider(ethersProvider: ethers.providers.Web3Provider) {
    const feeData = await ethersProvider.getFeeData()
    if (feeData.gasPrice) {
      return feeData.gasPrice.toString()
    }
    throw new Error('Can\'t get gas price from user wallet provider')
  }
}
