import { Module, VuexModule, MutationAction, getModule } from 'vuex-module-decorators'
import Store from '@/store'
import { TopMwadClass } from '../types'
import { stateDecoratorFactory } from 'vuex-module-decorators-state'
import { Contract } from 'ethers'

@Module({
  namespaced: true,
  dynamic: true,
  store: Store,
  name: 'mWadNft'
})
export class MwadNft extends VuexModule {
  topMwadClass: TopMwadClass = {} as TopMwadClass
  metaWardenContract: Contract | null = null

  @MutationAction({ mutate: ['topMwadClass'] })
  async setTopMwadClass(topMwadClass: TopMwadClass | object) {
    return { topMwadClass }
  }

  @MutationAction({ mutate: ['metaWardenContract'] })
  async setMetaWardenContract(metaWardenContract: Contract | null) {
    return { metaWardenContract }
  }
}

export const MwadNftModule = getModule(MwadNft)
export const MwadNftState = stateDecoratorFactory(MwadNftModule)
