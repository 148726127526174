

















import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({
  name: 'BaseSwitch'
})
export default class BaseSwitch extends Vue {
  @Prop({ default: '' }) name?: string
  @Prop({ default: false }) checked!: boolean

  @Emit()
  onToggle() {
    return
  }
}
