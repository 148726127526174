





































import { Component, Mixins, Prop, Emit } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { Network, ChainIDThatSupport } from '@/features/Web3Connector/types'

@Component({
  name: 'SwitchNetworkDropList'
})

export default class SwitchNetworkList extends Mixins(AbstractWeb3ConnectorView) {
  @Prop() readonly padding!: string

  NetworkType = Network
  chainIDThatSupport = ChainIDThatSupport
  networkSelected: Network | null = null

  networkSelectItems = [
    {
      iconPath: require('@/assets/svg/icon-eth-round.svg'),
      networkName: 'Ethereum',
      networkType: Network.ethereum,
      isBlockChainLayerTwo: false
    },
    {
      iconPath: require('@/assets/svg/icon-bsc-round.svg'),
      networkName: 'BNB Chain',
      networkType: Network.bsc,
      isBlockChainLayerTwo: false
    },
    {
      iconPath: require('@/assets/svg/icon-polygon-round.svg'),
      networkName: 'Polygon',
      networkType: Network.polygon,
      isBlockChainLayerTwo: false
    },
    {
      iconPath: require('@/assets/svg/icon-avalanche-round.svg'),
      networkName: 'Avalanche',
      networkType: Network.avalanche,
      isBlockChainLayerTwo: false
    },
    {
      iconPath: require('@/assets/svg/icon-arbitrum-round.svg'),
      networkName: 'Arbitrum',
      networkType: Network.arbitrum,
      isBlockChainLayerTwo: true
    },
    {
      iconPath: require('@/assets/svg/icon-optimism-round.svg'),
      networkName: 'Optimism',
      networkType: Network.optimism,
      isBlockChainLayerTwo: true
    }
  ]

  @Emit()
  onSelectNetwork(network: Network) {
    return network
  }
}
