import { ActionTree } from 'vuex'

import { SET_MUTE, SET_THEME } from './mutation-types'
import State from './state'

const actions: ActionTree<State, State> = {
  setMute({ commit }, mute) {
    commit(SET_MUTE, mute)
  },
  setTheme({ commit }, theme) {
    commit(SET_THEME, theme)
  }
}

export default actions
