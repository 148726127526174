import { Module, VuexModule, MutationAction, getModule } from 'vuex-module-decorators'
import { stateDecoratorFactory } from 'vuex-module-decorators-state'
import { WalletProvider, GasfeeData, GasSpeed } from '../types'
import { Network } from '@/features/Web3Connector/types/Web3'
import Web3 from 'web3'
import Store from '@/store'
import { ethers } from 'ethers'
import { Multicall as EthereumMulticall } from 'ethereum-multicall'
import { APP_NAME, WALLET_PROVIDER_LOCAL_STORAGE_KEY, DEFAULT_GAS_SPEED } from '@/constants'
import { WardenBestRateSdkTagVersion } from '@/features/Swap/types'
const walletProviderLocalStorageName = `${APP_NAME}.${WALLET_PROVIDER_LOCAL_STORAGE_KEY}`

@Module({
  namespaced: true,
  dynamic: true,
  store: Store,
  name: 'web3Connector'
})
export class Web3Connector extends VuexModule {
  walletProvider: WalletProvider | null = localStorage.getItem(walletProviderLocalStorageName) as WalletProvider || null
  web3: Web3 | null = null
  userAddress: string | null = null

  // Wallet Info
  walletName: string | null = null
  walletType: string | null = null
  walletLogo: string | null = null

  provider: any | null = null
  ethersProvider: ethers.providers.Web3Provider | null = null
  isWalletProviderSupportEip1559: boolean | null = null
  ethereumMulticall: EthereumMulticall | null = null

  network: Network | 'disconnected' = 'disconnected'
  networkId = 0
  isWalletConnected = false
  isCorrectNetwork = false
  isWrongNetworkNoticeOnModal = false
  isWrongNetworkNoticeOnHeader = false
  gasFeeData: GasfeeData = {} as GasfeeData
  gasFeeL2Data: GasfeeData = {} as GasfeeData
  selectedGasSpeed: GasSpeed = DEFAULT_GAS_SPEED
  wardenBestRateSdk1: WardenBestRateSdkTagVersion | null = null
  wardenBestRateSdk2: WardenBestRateSdkTagVersion | null = null

  get userDefaultWallet(): string {
    return this.userAddress ?? 'unknown'
  }

  // 'events' and 'conferences' are replaced by returned object
  // whose shape must be `{events: [...], conferences: [...] }`
  @MutationAction({ mutate: ['walletProvider'] })
  async setWalletProvider(walletProvider: WalletProvider | null) {
    localStorage.setItem(walletProviderLocalStorageName, walletProvider ?? '')
    return { walletProvider }
  }

  @MutationAction({ mutate: ['web3'] })
  async setWeb3(web3: Web3 | null) {
    return { web3 }
  }

  @MutationAction({ mutate: ['userAddress'] })
  async setUserAddress(userAddress: string | null) {
    return { userAddress }
  }

  @MutationAction({ mutate: ['walletName'] })
  async setWalletName(walletName: string | null) {
    return { walletName }
  }

  @MutationAction({ mutate: ['walletType'] })
  async setWalletType(walletType: string | null) {
    return { walletType }
  }

  @MutationAction({ mutate: ['walletLogo'] })
  async setWalletLogo(walletLogo: string | null) {
    return { walletLogo }
  }

  @MutationAction({ mutate: ['provider'] })
  async setProvider(provider: any | null) {
    return { provider }
  }

  @MutationAction({ mutate: ['ethersProvider'] })
  async setEthersProvider(ethersProvider: any | null) {
    return { ethersProvider }
  }

  @MutationAction({ mutate: ['isCorrectNetwork'] })
  async setIsCorrectNetwork(isCorrectNetwork: boolean) {
    return { isCorrectNetwork }
  }

  @MutationAction({ mutate: ['network'] })
  async setNetwork(network: Network | 'disconnected') {
    return { network }
  }

  @MutationAction({ mutate: ['networkId'] })
  async setNetworkId(networkId: number) {
    return { networkId }
  }

  @MutationAction({ mutate: ['isWalletConnected'] })
  async setIsWalletConnected(isWalletConnected: boolean) {
    return { isWalletConnected }
  }

  @MutationAction({ mutate: ['isWrongNetworkNoticeOnModal'] })
  async setIsWrongNetworkNoticeOnModal(isWrongNetworkNoticeOnModal: boolean) {
    return { isWrongNetworkNoticeOnModal }
  }

  @MutationAction({ mutate: ['isWrongNetworkNoticeOnHeader'] })
  async setIsWrongNetworkNoticeOnHeader(isWrongNetworkNoticeOnHeader: boolean) {
    return { isWrongNetworkNoticeOnHeader }
  }

  @MutationAction({ mutate: ['gasFeeData'] })
  async setGasfeeData(gasFeeData: GasfeeData) {
    return { gasFeeData }
  }

  @MutationAction({ mutate: ['gasFeeL2Data'] })
  async setGasfeeL2Data(gasFeeL2Data: GasfeeData) {
    return { gasFeeL2Data }
  }

  @MutationAction({ mutate: ['wardenBestRateSdk1'] })
  async setWardenBestRateSdk1(wardenBestRateSdk1: WardenBestRateSdkTagVersion | null) {
    return { wardenBestRateSdk1 }
  }

  @MutationAction({ mutate: ['wardenBestRateSdk2'] })
  async setWardenBestRateSdk2(wardenBestRateSdk2: WardenBestRateSdkTagVersion | null) {
    return { wardenBestRateSdk2 }
  }

  @MutationAction({ mutate: ['selectedGasSpeed'] })
  async setSelectedGasSpeed(selectedGasSpeed: GasSpeed) {
    return { selectedGasSpeed }
  }

  @MutationAction({ mutate: ['isWalletProviderSupportEip1559'] })
  async setIsWalletProviderSupportEip1559(isWalletProviderSupportEip1559: boolean | null) {
    return { isWalletProviderSupportEip1559 }
  }
}

export const web3ConnectorModule = getModule(Web3Connector)
export const Web3ConnectorState = stateDecoratorFactory(web3ConnectorModule)
