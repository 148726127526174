import Bignumber from 'bignumber.js'

export default function(number: number | string| any) {
  let context = new Bignumber(number)
  context = Object.assign(context, {
    toBase(decimal = 18) {
      const humanNumber = context.dividedBy(this.baseInWei(decimal))
      return Object.assign(new Bignumber(context), humanNumber)
    },
    toWei(decimal = 18) {
      const amount = context.multipliedBy(this.baseInWei(decimal))
      return Object.assign(new Bignumber(context), amount)
    },
    gweiToWei() {
      const b = new Bignumber('10').pow(9)
      const amount = context.multipliedBy(b)
      return Object.assign(new Bignumber(context), amount)
    },
    baseInWei(decimal: number) {
      return new Bignumber(10).exponentiatedBy(decimal)
    }
  })
  return context
}
