








































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { AbstractMwadNftView } from '@/features/MwadNft/abstractView'
import { NETWORK_CONSTANT, WARDEN_TOKEN_IMAGE } from '@/constants'
import { Modal } from '@/classes/modal'
import { DisplayWalletProvider } from '@/types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import THEME from '@/constants/theme'
import LazyImageCircle from '@/components/LazyImageCircle.vue'
import { mapGetters } from 'vuex'

const mapWalletName: any = {
  Trust: 'Trust Wallet'
}

@Component({
  name: 'UserWalletModal',
  computed: mapGetters(['theme']),
  components: {
    LazyImageCircle
  }
})

export default class UserWalletModal extends Mixins(AbstractSwapView, Modal, AbstractMwadNftView) {
  DisplayWalletProvider = DisplayWalletProvider
  chainIDThatSupport = ChainIDThatSupport

  isModalOpen = false
  unclaimed = ''
  wadTokenBalanceOfUser = ''
  allWadToken = ''

  theme?: string

  async handleDisconnect() {
    this.amplitudeLogEvent('Click disconnect wallet')
    await this.disconnect()
    this.isModalOpen = false
  }

  openLinkBlockExplorerNewTab() {
    const uri = `${NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].BLOCK_EXPLORER_URL}/address/${this.userAddress}`
    window.open(uri, '_blank')
    this.amplitudeLogEvent('Click open link explorer new tab in user wallet modal', { uri })
  }

  handleAddWardenToken() {
    this.amplitudeLogEvent('Click icon add warden token in metamask')
    const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport.bsc]?.WARDEN_TOKEN
    if (!wardenTokenData) {
      return
    }
    try {
      this.addTokenInMetaMask(wardenTokenData, WARDEN_TOKEN_IMAGE)
      this.amplitudeLogEvent('Add warden token in metamask complete')
    } catch (error) {
      this.amplitudeLogEvent('Add warden token in metamask failed')
    }
  }

  async getModalInfo() {
    if (this.networkId === ChainIDThatSupport.bsc) {
      this.findWADPrice()
      this.getWardenFarmInfo()
        .then((wardenFarmInfo: any) => {
          this.unclaimed = wardenFarmInfo?.unclaimed || ''
          this.wadTokenBalanceOfUser = wardenFarmInfo?.wadTokenBalanceOfUser || ''
          this.allWadToken = wardenFarmInfo?.allWadToken || ''
        })
    }
  }

  @Watch('isModalOpen')
  async handleModalOpen(val: boolean) {
    if (val) {
      this.getModalInfo()
      this.amplitudeLogEvent('UserWalletModal is open')
    } else {
      this.amplitudeLogEvent('UserWalletModal is close')
    }
  }

  @Watch('networkId')
  async handleNetWorkChange() {
    this.getModalInfo()
  }

  get displayWalletName() {
    if (this.walletProvider === this.WalletProvider.WALLET_CONNECT) {
      return DisplayWalletProvider[this.walletProvider]
    } else if (this.walletProvider === this.WalletProvider.BINANCE_CHAIN) {
      return DisplayWalletProvider[this.walletProvider]
    }
    return mapWalletName[this.walletName as string] ?? this.walletName
  }

  get iconSpace() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-copy-space.svg')
    }

    return require('@/assets/svg/icon-copy.svg')
  }

  get iconBsc() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-bsc-link-on-modal-space.svg')
    }

    return require('@/assets/svg/icon-bsc-link-on-modal.svg')
  }

  get iconSwitchNetwork() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-switch-network-space.svg')
    }

    return require('@/assets/svg/icon-switch-network.svg')
  }

  get iconDisconnect() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-disconnect-space.svg')
    }

    return require('@/assets/svg/icon-disconnect.svg')
  }
}
