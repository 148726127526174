











import { Vue, Component, Prop } from 'vue-property-decorator'
import { handleTokenImageError } from '@/utils/helper'

@Component({
  name: 'ImageCircle'
})
export default class ImageCircle extends Vue {
  @Prop({ required: true }) readonly imageSrc: any
  @Prop({ required: false }) readonly alt: any

  handleTokenImageError = handleTokenImageError
}
