import Component from 'vue-class-component'
import { AbstractWeb3ConnectorView } from '../abstractView'
import { WalletProvider, Network, ChainIDThatSupport } from '../types'
import { saveCurrentNetworkIdInlocalStorage } from '../utils'
@Component
export default class AutoConnectView extends AbstractWeb3ConnectorView {
  checkIsMobile = false
  networkQuery: any

  mounted() {
    this.checkIsMobile = this.isMobile()
    this.checkQueryStringNetwork()

    if (this.walletProvider !== null) {
      console.log('connect to lastest walletProvider', this.walletProvider)
      if (this.walletProvider === WalletProvider.BINANCE_CHAIN) {
        setTimeout(() => {
          if (this.walletProvider !== null) {
            this.connect(this.walletProvider)
          }
        }, 1000)
      } else {
        this.handleConnectFirstTime(this.walletProvider)
      }
    } else if (this.hasInjectedWeb3() && this.isMobile()) {
      console.log('connect to mobile')
      this.handleConnectFirstTime(WalletProvider.INJECTED_WEB3)
    } else {
      this.connectContractForRead()
    }
  }

  async handleConnectFirstTime(walletProvider: WalletProvider) {
    await this.connect(walletProvider, this.networkQuery)
    if (!this.isCorrectNetwork) {
      this.disconnect()
    }
  }

  public isMobile() {
    return window.innerWidth <= 768
  }

  hasInjectedWeb3() {
    return typeof window.ethereum !== 'undefined'
  }

  checkQueryStringNetwork() {
    this.networkQuery = this.$route.query?.network
    if (!this.networkQuery) {
      return
    } else if (this.networkQuery === Network.bsc) {
      saveCurrentNetworkIdInlocalStorage(ChainIDThatSupport.bsc)
    } else if (this.networkQuery === Network.polygon) {
      saveCurrentNetworkIdInlocalStorage(ChainIDThatSupport.polygon)
    } else if (this.networkQuery === Network.arbitrum) {
      saveCurrentNetworkIdInlocalStorage(ChainIDThatSupport.arbitrum)
    } else if (this.networkQuery === Network.ethereum) {
      saveCurrentNetworkIdInlocalStorage(ChainIDThatSupport.ethereum)
    } else if (this.networkQuery === Network.avalanche) {
      saveCurrentNetworkIdInlocalStorage(ChainIDThatSupport.avalanche)
    } else if (this.networkQuery === Network.optimism) {
      saveCurrentNetworkIdInlocalStorage(ChainIDThatSupport.optimism)
    }
  }
}
