































import { Component, Prop } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView/index'
import { ChainIdSupportWadToken } from '@/features/Web3Connector/types'
import { NETWORK_CONSTANT } from '@/constants'
import { TokenInput } from '@/types'
import { getAddress } from '@ethersproject/address'

@Component({
  name: 'BuyWardenTokenCard'
})
export default class BuyWardenTokenCard extends AbstractSwapView {
  @Prop({ required: false }) readonly cssVarsOption!: object

  cssVarsDefault = {
    '--price-labe--font-size': '15px',
    '--price-labe--font-weight': 'bold',
    '--buy-wad-btn--font-size': '13px',
    '--buy-wad-btn--font-weight': 'bold',
    '--buy-wad-btn--border-radius': '15px'
  }

  get userCssVars() {
    return {
      ...this.cssVarsDefault,
      ...this.cssVarsOption
    }
  }

  async handleBtnBuyWardenToken() {
    this.amplitudeLogEvent('Click buy warden token on header', { wardenTokenPrice: this.displayWardenTokenPriceUsd })
    const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
    if (!wardenTokenData) {
      return
    }
    const tokenData: TokenInput = {
      address: wardenTokenData.address,
      symbol: wardenTokenData.symbol
    }
    if (getAddress(this.tokenAInput.address as string) === getAddress(wardenTokenData.address)) {
      const nativeTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken].NATIVE_TOKEN
      await this.swapTokenInput()
      const tokenData: TokenInput = {
        address: nativeTokenData.address,
        symbol: nativeTokenData.symbol
      }
      await this.setTokenInput('tokenA', tokenData)
    }
    await this.setTokenInput('tokenB', tokenData)
  }
}
