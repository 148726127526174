import { extend } from 'vee-validate'

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true
})

extend('confirmSwap', {
  validate: value => value === 'confirm'
})

extend('priceSlippageBetween', {
  // @ts-ignore
  validate(value, { min, max }) {
    const valueInFloat = parseFloat(value)
    return valueInFloat >= parseFloat(min) && valueInFloat <= parseFloat(max)
  },
  params: ['min', 'max'],
  message: 'Enter a valid slippage percentage'
})
