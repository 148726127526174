















































import { Component, Mixins } from 'vue-property-decorator'
import { Modal } from '@/classes/modal'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { getMute, getTheme, toggleMute, toggleTheme } from '@/utils/helper'
import BaseSwitch from '@/components/BaseSwitch.vue'
import THEME from '@/constants/theme'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import { mapGetters } from 'vuex'

@Component({
  name: 'SettingsModal',
  components: {
    BaseSwitch
  },
  computed: mapGetters(['mute', 'theme'])
})
export default class SettingsModal extends Mixins(Modal, AbstractWeb3ConnectorView) {
  mute?: boolean
  theme?: string

  get isSpaceMode() {
    return this.theme === THEME.SPACE_MODE
  }

  get isSilentMode() {
    return this.mute
  }

  get iconSpaceMode() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/space_mode-space.svg')
    }

    return require('@/assets/svg/space_mode.svg')
  }

  get iconSoundMode() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/sound_mode-space.svg')
    }

    return require('@/assets/svg/sound_mode.svg')
  }

  onToggleMute() {
    toggleMute()

    const mute = getMute()
    this.$store.dispatch('setMute', mute)

    if (!mute) {
      let audio

      switch (this.networkId) {
        case ChainIDThatSupport.polygon :
          audio = document.getElementById('bgMusic-polygon') as HTMLAudioElement
          break
        case ChainIDThatSupport.arbitrum :
          audio = document.getElementById('bgMusic-arbitrum') as HTMLAudioElement
          break
        case ChainIDThatSupport.ethereum :
          audio = document.getElementById('bgMusic-eth') as HTMLAudioElement
          break
        case ChainIDThatSupport.avalanche :
          audio = document.getElementById('bgMusic-avalanche') as HTMLAudioElement
          break
        case ChainIDThatSupport.optimism :
          audio = document.getElementById('bgMusic-optimism') as HTMLAudioElement
          break
        default:
          audio = document.getElementById('bgMusic') as HTMLAudioElement
      }
      if (audio) {
        audio.play()
      }
    }
  }

  onToggleTheme() {
    toggleTheme()

    const theme = getTheme()
    this.$store.dispatch('setTheme', theme)
    document.body.setAttribute('data-theme', theme)
  }
}
