import { MutationTree } from 'vuex'

import { SET_MUTE, SET_THEME } from './mutation-types'
import State from './state'

const mutations: MutationTree<State> = {
  [SET_MUTE](state: State, mute: boolean): void {
    state.mute = mute
  },
  [SET_THEME](state: State, theme: string): void {
    state.theme = theme
  }
}

export default mutations
