import { Component, Mixins, Vue } from 'vue-property-decorator'
import { SwapState, SwapModule } from '../store'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import Bignumber from '@/utils/bignumber'
import { ethers, utils, BigNumber as BigNumberForEthers } from 'ethers'
import { getAddress } from '@ethersproject/address'
import * as _ from '@/utils/lodash-extended'
import { AbstractAmplitudeView } from '@/features/Amplitude/abstractView'
import { AbstractSentryView } from '@/features/Sentry/abstractView'
import { groupTokenAmountFromBestRateData } from '../utils'
import { Network, ChainIDThatSupport, GasfeeData, GasSpeed } from '@/features/Web3Connector/types'
import {
  Token,
  TokenInput,
  CurrentBestRateSdk,
  BestRateQueryState,
  BestRateQuerySide,
  MethodNameForGetBestRate,
  FindBestRateOptions,
  FindBestRateOptional
} from '@/types'
import {
  WardenBestRateSdkTagVersion,
  WardenswapSdkVersion,
  BestRateResultSdk,
  WrapResourceBestRateFromSdk,
  GetQuoteRetrunTypeFromSdk
} from '../types'
import { USD_AMOUNT_FOR_CALC_TOKEN_PRICE, NETWORK_CONSTANT, DEFAULT_FIND_BEST_RATE_OPIONS } from '@/constants'
import { checkIsWrapOrUnwrapNativeToken } from '@/utils/helper'

@Component
export class BestRate extends Mixins(AbstractAmplitudeView, AbstractSentryView, Vue) {
  @SwapState public readonly allToken!: Token[]
  @SwapState public readonly tokenAInput!: TokenInput
  @SwapState public readonly tokenBInput!: TokenInput
  @SwapState public readonly tokenPrices!: { [key: string]: string }
  @SwapState public readonly bestRateQueryState!: BestRateQueryState
  @SwapState public readonly bestRateQuerySide!: BestRateQuerySide
  @SwapState public readonly getBestRateCount!: number

  @Web3ConnectorState public readonly ethersProvider!: ethers.providers.Web3Provider
  @Web3ConnectorState public readonly wardenBestRateSdk1!: WardenBestRateSdkTagVersion | null
  @Web3ConnectorState public readonly wardenBestRateSdk2!: WardenBestRateSdkTagVersion | null
  @Web3ConnectorState public readonly network!: Network | 'disconnected'
  @Web3ConnectorState public readonly networkId!: number
  @Web3ConnectorState public readonly isCorrectNetwork!: boolean
  @Web3ConnectorState public readonly gasFeeData!: GasfeeData
  @Web3ConnectorState public readonly gasFeeL2Data!: GasfeeData
  @Web3ConnectorState public readonly selectedGasSpeed!: GasSpeed

  public async findBestRateTokenAToTokenB(tokenInputAmount: string) {
    this.amplitudeLogEvent('Start process find best rate tokenA to TokenB', {
      tokenAAddress: getAddress(this.tokenAInput.address as string),
      tokenBAddress: getAddress(this.tokenBInput.address as string),
      tokenAAmountInBase: tokenInputAmount,
      gasPriceWei: this.gasFeeData[this.selectedGasSpeed].gasPriceWei
    })
    const timeStart = performance.now()
    SwapModule.setBestRateQueryState(BestRateQueryState.PENDING)
    SwapModule.setbestRateQuerySide(BestRateQuerySide.FROM_A_TO_B)
    SwapModule.setLastBestRateQuerySide(BestRateQuerySide.FROM_A_TO_B)
    await SwapModule.setTokenBInputAmount('')
    await SwapModule.increaseGetBestRateCount()
    SwapModule.setPriceImpact('0.00')
    const stampGetRateCount = this.getBestRateCount
    try {
      const { findBestRateOptional } = await this.getFindBestRateOptional()
      const bestRateResult = await this.tranformDataToFindBestRate(
        this.tokenAInput.address as string,
        this.tokenBInput.address as string,
        tokenInputAmount,
        findBestRateOptional
      )
      let currentBestRate: CurrentBestRateSdk[] = []
      if (bestRateResult === undefined || Bignumber(bestRateResult.amountOut.toString()).isZero()) {
        SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
        SwapModule.setBestRateQueryState(BestRateQueryState.UNVALUABLE)
        return
      }
      currentBestRate = await this.generateCurrentBestRateData(tokenInputAmount, bestRateResult)
      const { amountOut: totalAmountOut } = groupTokenAmountFromBestRateData(currentBestRate)
      const priceImpactPercent = await this.calculatePriceImpact(currentBestRate)

      if (stampGetRateCount === this.getBestRateCount && this.tokenAInput.amount === tokenInputAmount) {
        await SwapModule.setPriceImpact(priceImpactPercent)
        await SwapModule.setTokenBInputAmount(totalAmountOut)
        await SwapModule.setBestRateResultSdk(bestRateResult)
        await SwapModule.setCurrentBestRate(currentBestRate)
        await SwapModule.setBestRateQueryState(BestRateQueryState.SUCCESS)
        const timeEnd = performance.now()
        const executionTime = ((timeEnd - timeStart) / 1000).toFixed(2) // seconds
        this.amplitudeLogEvent('End process find best rate tokenA to TokenB', {
          tokenAAddress: getAddress(this.tokenAInput.address as string),
          tokenBAddress: getAddress(this.tokenBInput.address as string),
          tokenAAmountInBase: tokenInputAmount,
          bestRateResult: bestRateResult,
          gasPriceWei: this.gasFeeData[this.selectedGasSpeed].gasPriceWei,
          executionTimeSec: executionTime
        })
      } else {
        SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
      }
    } catch (error) {
      console.error('Query best rate from token A to token B failed', error)
      SwapModule.setBestRateQueryState(BestRateQueryState.FAIL)
      const dataShouldLog = {
        tokenAAddress: getAddress(this.tokenAInput.address as string),
        tokenBAddress: getAddress(this.tokenBInput.address as string),
        tokenAAmountInBase: tokenInputAmount,
        gasPriceWei: this.gasFeeData[this.selectedGasSpeed].gasPriceWei
      }
      this.sentryLogError(this.network, error, 'Error', dataShouldLog)
      this.amplitudeLogEvent('Find best rate failed tokenA to TokenB', dataShouldLog)
    }
    SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
  }

  public async watchBestRateTokenAToTokenB() {
    if (!this.isCorrectNetwork) {
      return
    }
    if (
      !this.tokenAInput?.address ||
      !this.tokenAInput?.amount ||
      this.tokenAInput?.amount === '' ||
      Bignumber(this.tokenAInput?.amount).lte(0) ||
      !this.tokenBInput?.address ||
      !this.tokenBInput?.amount ||
      this.tokenBInput?.amount === '' ||
      Bignumber(this.tokenBInput?.amount).lte(0) ||
      this.bestRateQueryState === BestRateQueryState.PENDING
    ) {
      return
    }
    await SwapModule.increaseGetBestRateCount()
    const stampGetRateCount = this.getBestRateCount
    const tempTokenAInput = this.tokenAInput.address
    const tempTokenBInput = this.tokenBInput.address

    try {
      const tokenAInputAmount = this.tokenAInput.amount
      const { findBestRateOptional } = await this.getFindBestRateOptional()
      const bestRateResult = await this.tranformDataToFindBestRate(
        this.tokenAInput.address,
        this.tokenBInput.address,
        tokenAInputAmount,
        findBestRateOptional
      )
      let currentBestRate: CurrentBestRateSdk[] = []
      if (bestRateResult === undefined || Bignumber(bestRateResult.amountOut.toString()).isZero()) {
        return
      }
      currentBestRate = await this.generateCurrentBestRateData(tokenAInputAmount, bestRateResult)
      const { amountOut: totalAmountOut } = groupTokenAmountFromBestRateData(currentBestRate)
      const priceImpactPercent = await this.calculatePriceImpact(currentBestRate)

      if (
        stampGetRateCount === this.getBestRateCount &&
        this.tokenAInput.amount === tokenAInputAmount &&
        tempTokenAInput === this.tokenAInput.address &&
        tempTokenBInput === this.tokenBInput.address &&
        // @ts-ignore
        this.bestRateQueryState !== BestRateQueryState.PENDING
      ) {
        await SwapModule.setPriceImpact(priceImpactPercent)
        await SwapModule.setTokenBInputAmount(totalAmountOut)
        await SwapModule.setBestRateResultSdk(bestRateResult)
        await SwapModule.setCurrentBestRate(currentBestRate)
      }
    } catch (error) {
      console.error(error)
    }
  }

  public async tranformDataToFindBestRate(
    tokenXAddress: string,
    tokenYAddress: string,
    tokenXInputAmount: string,
    options?: FindBestRateOptional
  ): Promise<BestRateResultSdk> {
    // Manage options
    const findBestRateOption: FindBestRateOptions = _.cloneDeep(DEFAULT_FIND_BEST_RATE_OPIONS)
    if (options && Reflect.ownKeys(options).length) {
      if (options?.shouldFindBestRateType !== undefined) {
        findBestRateOption.shouldFindBestRateType = options.shouldFindBestRateType
      }
    }
    const tokenXData = this.allToken.find((token: Token) => token.address === tokenXAddress) as Token
    const tokenYData = this.allToken.find((token: Token) => token.address === tokenYAddress) as Token
    const tokenInputAmountInWei = utils.parseUnits(tokenXInputAmount, tokenXData.decimals)
    const tokenXChecksumAddress = getAddress(tokenXData.address)
    const tokenYChecksumAddress = getAddress(tokenYData.address)
    const bestRateResult = await this.handleBestRateFromSdk(
      tokenXChecksumAddress,
      tokenYChecksumAddress,
      tokenInputAmountInWei.toString(),
      findBestRateOption
    )
    return bestRateResult
  }

  public async calculatePriceImpact(
    currentBestRate: CurrentBestRateSdk[]
  ): Promise<string> {
    const result = groupTokenAmountFromBestRateData(currentBestRate)
    if (!result) {
      throw Error('System not found token amount from currentBestRate data')
    }
    if (!this.tokenPrices.hasOwnProperty(this.tokenAInput.address as string)) {
      const tokenAPrice = await this.handleTokenPrice(this.tokenAInput.address as string)
      if (!tokenAPrice) {
        throw Error("Error: Function calculatePriceImpact can't get token price")
      }
    }
    const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token

    if (checkIsWrapOrUnwrapNativeToken(tokenAData.address, tokenBData.address, this.networkId)) {
      return '0.00'
    }
    const tokenAAmountInOneHundredUsd = Bignumber(USD_AMOUNT_FOR_CALC_TOKEN_PRICE)
      .div(this.tokenPrices[this.tokenAInput.address as string])
      .toFixed(tokenAData.decimals)
    const tokenAAmountInOneHundredUsdInWei = ethers.utils.parseUnits(tokenAAmountInOneHundredUsd, tokenAData.decimals)

    const bestRateResult = await this.tranformDataToFindBestRate(
      this.tokenAInput.address as string,
      this.tokenBInput.address as string,
      tokenAAmountInOneHundredUsd,
      { shouldFindBestRateType: [MethodNameForGetBestRate.STRATEGIES] }
    )

    const oneHundredUsdTokenBAmountOutInWei = bestRateResult.amountOut.toString()
    const oneHundredUsdRate = Bignumber(oneHundredUsdTokenBAmountOutInWei).div(
      Bignumber(tokenAAmountInOneHundredUsdInWei.toString())
    )
    const askVolumeRate = Bignumber(
      ethers.utils.parseUnits(result.amountOut.toString(), tokenBData.decimals).toString()
    ).div(ethers.utils.parseUnits(result.amountIn.toString(), tokenAData.decimals).toString())
    const priceImpact = askVolumeRate
      .minus(oneHundredUsdRate)
      .div(oneHundredUsdRate)
      .multipliedBy('100')
    if (Bignumber(priceImpact).isPositive()) {
      SwapModule.setPriceImpact('0.00')
      return '0.00'
    } else {
      const priceImpactPercent = Bignumber(priceImpact)
        .multipliedBy('-1')
        .toFormat(2)
      return priceImpactPercent
    }
  }

  public async getFindBestRateOptional(): Promise<Record<'findBestRateOptional', FindBestRateOptional>> {
    const findBestRateOptional: FindBestRateOptional = {}

    return { findBestRateOptional }
  }

  public tokenInputVolumeUsd(tokenInput: TokenInput) {
    if (tokenInput?.address && tokenInput?.amount && this.tokenPrices.hasOwnProperty(tokenInput.address)) {
      const totalVolumeUsd = Bignumber(this.tokenPrices[tokenInput.address])
        .multipliedBy(tokenInput.amount)
        .toString()
      return totalVolumeUsd
    }
    return ''
  }

  public async generateCurrentBestRateData(
    tokenAInputAmount: string,
    bestRateResult: BestRateResultSdk
  ): Promise<CurrentBestRateSdk[]> {
    if (bestRateResult.sdkVersion === WardenswapSdkVersion.NEW_VERION_2) {
      return this.generateCurrentBestRateFromSdk(tokenAInputAmount, bestRateResult)
    } else {
      throw new Error('generateCurrentBestRateData: not support for this version')
    }
  }

  private async generateCurrentBestRateFromSdk(
    tokenAInputAmount: string,
    bestRateResult: BestRateResultSdk
  ): Promise<CurrentBestRateSdk[]> {
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
    let currentBestRate: CurrentBestRateSdk[] = []

    if (![WardenswapSdkVersion.NEW_VERION_2].includes(bestRateResult.sdkVersion)) {
      throw Error(`generateCurrentBestRateFromNewSdk: sdkVersion ${bestRateResult.sdkVersion} not support`)
    }
    const totalAmountOut = bestRateResult.amountOut.toString()
    const totalAmountOutInBase = utils.formatUnits(totalAmountOut, tokenBData.decimals)

    if (bestRateResult.type === MethodNameForGetBestRate.STRATEGIES) {
      const subRoutesIndex = bestRateResult.path.routes.map(data => data.id)
      const subRoutesName = bestRateResult.path.routes.map(data => data.name)

      currentBestRate = [
        {
          subRoutesIndex: subRoutesIndex,
          subRoutesName: subRoutesName,
          amountIn: tokenAInputAmount,
          amountOut: totalAmountOutInBase,
          percentage: '100',
          swapAddress: bestRateResult.swapAddress,
          sdkVersion: WardenswapSdkVersion.NEW_VERION_2
        }
      ]
    } else if (bestRateResult.type === MethodNameForGetBestRate.SPLIT) {
      currentBestRate = await this.generateCurrentBestRateDataForBestRateSplitSdk(
        this.tokenAInput,
        this.tokenBInput,
        tokenAInputAmount,
        bestRateResult
      )
    } else if (
      bestRateResult.type === MethodNameForGetBestRate.ETH_TO_WETH ||
      bestRateResult.type === MethodNameForGetBestRate.WETH_TO_ETH
    ) {
      currentBestRate = [
        {
          subRoutesName: ['WARDEN'], // Mock data
          amountIn: tokenAInputAmount,
          amountOut: totalAmountOutInBase,
          percentage: '100',
          swapAddress: '', // Mock data
          sdkVersion: WardenswapSdkVersion.NEW_VERION_2
        }
      ]
    }
    return currentBestRate
  }

  public async generateCurrentBestRateDataForBestRateSplitSdk(
    tokenXtInput: TokenInput,
    tokenYInput: TokenInput,
    tokenInputAmount: string,
    bestRateSplit: BestRateResultSdk
  ): Promise<CurrentBestRateSdk[]> {
    if (
      bestRateSplit.type !== MethodNameForGetBestRate.SPLIT ||
      ![WardenswapSdkVersion.NEW_VERION_2].includes(bestRateSplit.sdkVersion)
    ) {
      throw new Error(`Best rate type ${bestRateSplit?.type} not support for generate data`)
    }

    const currentBestRate: CurrentBestRateSdk[] = []
    const tokenXData = this.allToken.find((token: Token) => token.address === tokenXtInput.address) as Token
    const tokenYData = this.allToken.find((token: Token) => token.address === tokenYInput.address) as Token

    const tokenInputAmountInWei = utils.parseUnits(tokenInputAmount, tokenXData.decimals).toString()
    const totalAmountOutInWei = bestRateSplit.amountOut.toString()

    let tokenInputRemaining = _.cloneDeep(tokenInputAmountInWei)
    let totalAmountOutRemaining = _.cloneDeep(totalAmountOutInWei)

    await Promise.all(
      bestRateSplit.volumns.map(async(volumn: number, index: number) => {
        const percentage = volumn.toString()
        let splitAmountIn = ''
        let splitAmountOut = ''
        if (index !== bestRateSplit.volumns.length - 1) {
          splitAmountIn = Bignumber(percentage)
            .div(100)
            .multipliedBy(tokenInputAmountInWei)
            .toFixed(0)
          splitAmountOut = Bignumber(percentage)
            .div(100)
            .multipliedBy(totalAmountOutInWei)
            .toFixed(0)
          tokenInputRemaining = Bignumber(tokenInputRemaining)
            .minus(splitAmountIn)
            .toFixed(0)
          totalAmountOutRemaining = Bignumber(totalAmountOutRemaining)
            .minus(splitAmountOut)
            .toFixed(0)
        } else {
          splitAmountIn = tokenInputRemaining
          splitAmountOut = totalAmountOutRemaining
        }

        const subRoutesIndex = bestRateSplit.paths[index].routes.map(data => data.id)
        const subRoutesName = bestRateSplit.paths[index].routes.map(data => data.name)
        currentBestRate.push({
          subRoutesIndex,
          subRoutesName,
          amountIn: utils.formatUnits(splitAmountIn, tokenXData.decimals).toString(),
          amountOut: utils.formatUnits(splitAmountOut, tokenYData.decimals).toString(),
          percentage: percentage,
          swapAddress: bestRateSplit.swapAddress,
          sdkVersion: WardenswapSdkVersion.NEW_VERION_2
        })
      })
    )

    return currentBestRate
  }

  private callMethodGetQuoteBySdkVersion(
    wardenBestRateSdk: WardenBestRateSdkTagVersion,
    tokenXAddress: string,
    tokenYAddress: string,
    amountInWei: string,
    shouldFindBestRateSplit: boolean
  ) {
    const gasPrice = this.gasFeeData[this.selectedGasSpeed].gasPriceWei
    if (wardenBestRateSdk.sdkVersion === WardenswapSdkVersion.NEW_VERION_2) {
      const GetQuoteOption: any = { enableSplit: shouldFindBestRateSplit }
      if ([ChainIDThatSupport.optimism, ChainIDThatSupport.arbitrum].includes(this.networkId)) {
        const gasPriceL2 = this.gasFeeL2Data[this.selectedGasSpeed].gasPriceWei
        GetQuoteOption.gasPriceL2 = BigNumberForEthers.from(gasPriceL2)
      }

      return (wardenBestRateSdk as WardenBestRateSdkTagVersion).getQuote(
        tokenXAddress,
        tokenYAddress,
        BigNumberForEthers.from(amountInWei),
        BigNumberForEthers.from(gasPrice),
        GetQuoteOption
      )
    } else {
      throw Error('Not support getQuote')
    }
  }

  async handleBestRateFromSdk(
    tokenXAddress: string,
    tokenYAddress: string,
    amountInWei: string,
    findBestRateOption: FindBestRateOptions
  ): Promise<BestRateResultSdk> {
    const shouldFindBestRateSplit = findBestRateOption.shouldFindBestRateType.includes(MethodNameForGetBestRate.SPLIT)
    const promiseArray: Promise<WrapResourceBestRateFromSdk>[] = [
      new Promise((resolve, reject) => {
        this.callMethodGetQuoteBySdkVersion(
          this.wardenBestRateSdk1 as WardenBestRateSdkTagVersion,
          tokenXAddress,
          tokenYAddress,
          amountInWei,
          shouldFindBestRateSplit
        )
          // @ts-ignore
          .then((val: any) => resolve({ value: val, sdkType: 'sdk1', sdkVersion: this.wardenBestRateSdk1?.sdkVersion }))
          .catch((reason: any) => {
            console.log('wardenBestRateSdk1 error=>', reason)
            console.log('Get rate data', {
              tokenXAddress,
              tokenYAddress,
              amountInWei,
              shouldFindBestRateSplit
            })
            reject(reason)
          })
      })
    ]
    if (this.wardenBestRateSdk2 !== null) {
      promiseArray.push(
        new Promise((resolve, reject) => {
          this.callMethodGetQuoteBySdkVersion(
            this.wardenBestRateSdk2 as WardenBestRateSdkTagVersion,
            tokenXAddress,
            tokenYAddress,
            amountInWei,
            shouldFindBestRateSplit
          )
            // @ts-ignore
            .then((val: any) => resolve({ value: val, sdkType: 'sdk2', sdkVersion: this.wardenBestRateSdk2?.sdkVersion }))
            .catch((reason: any) => {
              console.log('wardenBestRateSdk2 error =>', reason)
              console.log('Get rate data', {
                tokenXAddress,
                tokenYAddress,
                amountInWei,
                shouldFindBestRateSplit
              })
              reject(reason)
            })
        })
      )
    }
    const sdkResponsesData = await Promise.any<WrapResourceBestRateFromSdk>(promiseArray)
    if (
      process.env.VUE_APP_ENV === 'local' &&
      process.env?.VUE_APP_WARDEN_BEST_RATE_SDK_GET_QUOTE_RESULT_INSPECT_LOG === 'true'
    ) {
      console.log(`Best rate SDK result from type ${sdkResponsesData.sdkType}`, sdkResponsesData)
    }

    return {
      ...(sdkResponsesData.value as GetQuoteRetrunTypeFromSdk),
      sdkVersion: sdkResponsesData.sdkVersion
    } as BestRateResultSdk
  }

  public async getTokenPrice(tokenAddress: string) {
    if (
      !this.isCorrectNetwork ||
      [this.wardenBestRateSdk1, this.wardenBestRateSdk2].every(val => val === null) ||
      // @ts-ignore
      this.wardenBestRateSdk1?.network !== this.network
    ) {
      return
    }
    const stableCoin = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].STABLE_COIN_TOKEN as Token
    const destinationTokenData = this.allToken.find((token: Token) => token.address === tokenAddress) as Token
    if (!destinationTokenData || !Object.keys(destinationTokenData)) {
      return
    }
    if (stableCoin.address === tokenAddress) {
      return '1'
    }
    const bestRateResult = await this.tranformDataToFindBestRate(
      stableCoin.address,
      tokenAddress,
      USD_AMOUNT_FOR_CALC_TOKEN_PRICE.toString(),
      { shouldFindBestRateType: [MethodNameForGetBestRate.STRATEGIES] }
    )
    if (
      bestRateResult === undefined ||
      !bestRateResult.hasOwnProperty('amountOut') ||
      Bignumber(bestRateResult.amountOut).isZero()
    ) {
      throw new Error('getTokenPrice failed')
    }
    const amountOutInBase = utils
      .formatUnits(bestRateResult.amountOut.toString(), destinationTokenData.decimals)
      .toString()
    const price = Bignumber(USD_AMOUNT_FOR_CALC_TOKEN_PRICE)
      .div(amountOutInBase)
      .toString(10)
    return price
  }

  public async handleTokenPrice(tokenAddress: string): Promise<string | void> {
    try {
      const price = await this.getTokenPrice(tokenAddress)
      if (price === undefined) {
        throw Error(`System not found token price for token address ${tokenAddress}`)
      }
      SwapModule.addTokenPrice({ [tokenAddress as string]: price })
      return price
    } catch (error) {
      if (
        error.message.startsWith('System not found token price') ||
        error.message.includes('All promises were rejected') ||
        error.message.includes('No Promise in Promise.any was resolved')
      ) {
        return
      }
      this.sentryLogError(this.network, error, 'Error', { tokenAddress })
    }
  }
}
