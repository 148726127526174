export interface TopMwadClass {
  class: MWADClass;
  found: boolean;
  tokenId: number;
  userAddress: string;
}

export enum MWADClass {
  GOLDEN = 'GOLDEN',
  SUPER = 'SUPER',
  COMMON = 'COMMON',
  NO_CLASS = 'NO_CLASS'
}
