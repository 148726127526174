import { CurrentBestRateSdk } from '@/types'
import Bignumber from '@/utils/bignumber'
import Web3 from 'web3'
import { ethers } from 'ethers'
import { DEFAULT_GAS_LIMIT_FOR_READ_METHOD } from '@/constants/index'
import * as _ from '@/utils/lodash-extended'
import { GroupTokenAmount } from '../types'

export function groupTokenAmountFromBestRateData(currentBestRate: CurrentBestRateSdk[]): GroupTokenAmount {
  const groupTokenAmount = currentBestRate.reduce(
    (acc: any, cur: CurrentBestRateSdk) => {
      acc.amountIn = Bignumber(acc.amountIn)
        .plus(cur.amountIn)
        .toString(10)
      acc.amountOut = Bignumber(acc.amountOut)
        .plus(cur.amountOut)
        .toString(10)
      return acc
    },
    { amountIn: Bignumber('0'), amountOut: Bignumber('0') }
  )
  return groupTokenAmount
}

export function makeBatchRequest(web3: Web3, calls: any) {
  const batch = new web3.BatchRequest()
  const promises = calls.map((call: any) => {
    return new Promise((resolve, reject) => {
      const req = call.request({ gas: DEFAULT_GAS_LIMIT_FOR_READ_METHOD }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
      batch.add(req)
    })
  })
  batch.execute()
  return Promise.allSettled(promises)
}

export function isValuesIncludeInList(valueToInspect: any[], valueToInclude: any[]) {
  return _.isEmpty(_.difference(valueToInspect, valueToInclude))
}

export function parseLogs(eventLogs: ethers.providers.Log[], abi: any): ethers.utils.LogDescription[] {
  const inter = new ethers.utils.Interface(abi)
  const logDatas = eventLogs.map(log => inter.parseLog(log))

  return logDatas
}
