export interface TopOptiPunkClass {
  class: OptiPunkClass;
  found: boolean;
  tokenId: number;
  imageUrl?: string;
  userAddress: string;
}

export enum OptiPunkClass {
  OPTIPUNK = 'OPTIPUNK',
  NO_CLASS = 'NO_CLASS'
}
