












































































import { Component, Prop, Watch, Emit } from 'vue-property-decorator'
import NumberAnimation from '@/components/NumberAnimation.vue'
import ImageCircle from '@/components/ImageCircle.vue'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { TokenInputType, ApprovalState, BestRateQuerySide } from '@/types'
import { getTokenImage } from '@/utils/helper'
import Bignumber from '@/utils/bignumber'
import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'

@Component({
  name: 'TokenInput',
  components: {
    NumberAnimation,
    ImageCircle
  },
  computed: mapGetters(['theme'])
})
export default class TokenInput extends AbstractSwapView {
  theme?: string

  @Prop({ default: '' }) readonly titleLabel!: string
  @Prop({ required: true }) readonly tokenType!: TokenInputType
  @Prop({ default: '0' }) readonly displayBalance!: string
  @Prop({ default: '0' }) readonly displayTotalValueBalance!: string
  @Prop({ default: false }) readonly isDanger!: boolean

  // Function use in template
  getTokenImage = getTokenImage
  ApprovalState = ApprovalState
  BestRateQuerySide = BestRateQuerySide

  onClickToggle() {
    // @ts-ignore
    this.$parent.$refs[`TokenListModal${this.tokenType}`].showModal()
    this.amplitudeLogEvent(`Click open modal ${this.tokenType}`)
  }

  async handleBtnApproveToken() {
    try {
      this.amplitudeLogEvent('Click icon approve token in TokenInput', { tokenAddress: this.tokenAInput.address })
      await this.approveToken()
    } catch (error) {
      if (error.message.includes('User denied transaction signature')) {
        return
      }
      this.$toast.add({ severity: 'error', summary: 'Error Message', detail: error.message, life: 5000 })
      console.error(error)
    }
  }

  get isGreaterThanZero(): boolean {
    return Bignumber(this.displayBalance).isGreaterThan(0)
  }

  get displayTokenSymbol() {
    switch (this.tokenType) {
      case 'tokenA':
        if (this.tokenAInput?.symbol) {
          return this.tokenAInput.symbol
        }
        return ''
      case 'tokenB':
        if (this.tokenBInput?.symbol) {
          return this.tokenBInput.symbol
        }
        return ''
    }
  }

  get isDisplayLockToken(): boolean {
    if (this.tokenType === 'tokenB') {
      return false
    }
    return [ApprovalState.NOT_APPROVED, ApprovalState.PENDING].includes(this.approvalState)
  }

  get getTokenAddress() {
    switch (this.tokenType) {
      case 'tokenA':
        if (this.tokenAInput?.address) {
          return this.tokenAInput.address
        }
        return ''
      case 'tokenB':
        if (this.tokenBInput?.address) {
          return this.tokenBInput.address
        }
        return ''
    }
  }

  get totalValueUsd() {
    switch (this.tokenType) {
      case 'tokenA':
        return this.tokenInputVolumeUsd(this.tokenAInput)
      case 'tokenB':
        return this.tokenInputVolumeUsd(this.tokenBInput)
    }
  }

  get tokenInputAmount() {
    switch (this.tokenType) {
      case 'tokenA':
        if (this.tokenAInput?.amount) {
          return this.tokenAInput.amount
        }
        return ''
      case 'tokenB':
        if (this.tokenBInput?.amount) {
          return this.tokenBInput.amount
        }
        return ''
    }
  }

  set tokenInputAmount(val) {
    const valueString = val.toString().replace(/,/g, '')
    this.resetEventPercentageSelector()
    this.setTokenInputAmount(this.tokenType, valueString)
  }

  get displayPlaceholder() {
    if (this.bestRateQuerySide === BestRateQuerySide.UNKNOWN) {
      return '0.00'
    } else if (this.bestRateQuerySide === BestRateQuerySide.FROM_A_TO_B && this.tokenType === 'tokenB') {
      return 'Waiting ...'
    } else if (this.bestRateQuerySide === BestRateQuerySide.FROM_B_TO_A && this.tokenType === 'tokenA') {
      return 'Waiting ...'
    }
  }

  get isDisabledInput(): boolean {
    // Hotfix disabled feature get best rate token B -> token A
    if (this.tokenType === 'tokenB') {
      return true
    }
    // @ts-ignore
    if (this.bestRateQuerySide === BestRateQuerySide.FROM_A_TO_B && this.tokenType === 'tokenB') {
      return true
    } else if (this.bestRateQuerySide === BestRateQuerySide.FROM_B_TO_A && this.tokenType === 'tokenA') {
      return true
    }
    return false
  }

  @Watch('tokenAInput', { immediate: true, deep: true })
  async ontokenAInputChange() {
    if (this.userAddress && this.tokenType === 'tokenA') {
      await this.isAllowanced(this.userAddress)
    }
  }

  get iconShape() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-shape-space.svg')
    }

    return require('@/assets/svg/icon-shape.svg')
  }

  @Emit()
  resetEventPercentageSelector() {
    return 0
  }
}
