import { Module, VuexModule, Mutation, Action, MutationAction, getModule } from 'vuex-module-decorators'
import { stateDecoratorFactory } from 'vuex-module-decorators-state'
import Bignumber from '@/utils/bignumber'
import Store from '@/store'
import {
  getTokenComparator,
  precisionNumber,
  numberWithCommas
} from '@/utils/helper'
import {
  groupTokenAmountFromBestRateData
} from '../utils/index'
import {
  Token,
  TokenInput,
  CurrentBestRateSdk,
  BestRateQueryState,
  ApprovalState,
  TradeState,
  BestRateQuerySide,
  TransactionHashHistory
} from '@/types'
import { PARTNER_ID } from '@/constants'
import * as _ from '@/utils/lodash-extended'
import { BestRateResultSdk, SwapGasFees, TokenApprovalTransaction, TokensBalance } from '../types'
@Module({
  namespaced: true,
  dynamic: true,
  store: Store,
  name: 'swap'
})
export class Swap extends VuexModule {
  tokensBalance: TokensBalance = {}
  allToken: Token[] = []
  tokenAInput: TokenInput = {}
  tokenBInput: TokenInput = {}
  currentBestRate: CurrentBestRateSdk[] = []
  bestRateResultSdk = {} as BestRateResultSdk
  tokenPrices: { [key: string]: string } = {}
  priceSlippage = '0'
  bestRateQueryState: BestRateQueryState = BestRateQueryState.UNKNOWN
  approvalState: ApprovalState = ApprovalState.UNKNOWN
  tradeState: TradeState = TradeState.UNKNOWN
  bestRateQuerySide: BestRateQuerySide = BestRateQuerySide.UNKNOWN
  lastBestRateQuerySide: BestRateQuerySide = BestRateQuerySide.UNKNOWN
  getBestRateCount = 0
  isAllowancedCount = 0
  showInvertTradePrice = false
  priceImpact = '0.00'
  transactionHashHistory: TransactionHashHistory[] = []
  swapGasFees: SwapGasFees = { usd: '0', nativeTokenInBase: '0' }
  referralId = PARTNER_ID
  tokenApprovalTransactions: TokenApprovalTransaction[] = []
  metaData = 0

  get computeTradePrice(): string | null {
    if (!this.currentBestRate?.length) {
      return null
    }
    const { amountIn, amountOut } = groupTokenAmountFromBestRateData(this.currentBestRate)
    let showTradePrice = ''
    if (this.showInvertTradePrice) {
      const tradePrice = Bignumber(amountOut).div(amountIn).toString()
      showTradePrice = `${numberWithCommas(precisionNumber(tradePrice).toString())} ${this.tokenBInput.symbol} per ${this.tokenAInput.symbol}`
    } else {
      const tradePrice = Bignumber(amountIn).div(amountOut).toString()
      showTradePrice = `${numberWithCommas(precisionNumber(tradePrice).toString())} ${this.tokenAInput.symbol} per ${this.tokenBInput.symbol}`
    }
    return showTradePrice
  }

  @Mutation
  setTokensBalance(newTokensBalance: TokensBalance) {
    this.tokensBalance = {
      ...this.tokensBalance,
      ...newTokensBalance
    }
    this.allToken
      .sort((tokenA: Token, tokenB: Token): number => {
        return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
      })
      .sort((tokenA: Token, tokenB: Token): number => {
        return getTokenComparator(this.tokensBalance, tokenA, tokenB)
      })
  }

  @Mutation
  clearTokensBalance() {
    this.tokensBalance = {}
  }

  @Mutation
  setTokenAInput(tokenInput: TokenInput | {}) {
    this.tokenAInput = tokenInput
  }

  @Mutation
  setTokenBInput(tokenInput: TokenInput | {}) {
    this.tokenBInput = tokenInput
  }

  @Mutation
  setTokenAInputAmount(tokenInputAmount: string) {
    this.tokenAInput = { ...this.tokenAInput, amount: tokenInputAmount }
  }

  @Mutation
  setTokenBInputAmount(tokenInputAmount: string) {
    this.tokenBInput = { ...this.tokenBInput, amount: tokenInputAmount }
  }

  @Mutation
  setCurrentBestRate(currentBestRate: CurrentBestRateSdk[]) {
    this.currentBestRate = currentBestRate
  }

  @MutationAction({ mutate: ['bestRateResultSdk'] })
  async setBestRateResultSdk(bestRateResultSdk: BestRateResultSdk | object) {
    return { bestRateResultSdk }
  }

  @MutationAction({ mutate: ['allToken'] })
  async setAllToken(allToken: Token[]) {
    return { allToken }
  }

  @MutationAction({ mutate: ['approvalState'] })
  async setApprovalState(approvalState: ApprovalState) {
    return { approvalState }
  }

  @MutationAction({ mutate: ['priceSlippage'] })
  async setPriceSlippage(priceSlippage: string) {
    return { priceSlippage }
  }

  @MutationAction({ mutate: ['bestRateQueryState'] })
  async setBestRateQueryState(bestRateQueryState: BestRateQueryState) {
    return { bestRateQueryState }
  }

  @MutationAction({ mutate: ['tradeState'] })
  async setTradeState(tradeState: TradeState) {
    return { tradeState }
  }

  @MutationAction({ mutate: ['tradeState'] })
  async setTradeTxhash(tradeState: TradeState) {
    return { tradeState }
  }

  @MutationAction({ mutate: ['bestRateQuerySide'] })
  async setbestRateQuerySide(bestRateQuerySide: BestRateQuerySide) {
    return { bestRateQuerySide }
  }

  @MutationAction({ mutate: ['lastBestRateQuerySide'] })
  async setLastBestRateQuerySide(lastBestRateQuerySide: BestRateQuerySide) {
    return { lastBestRateQuerySide }
  }

  @Mutation
  increaseGetBestRateCount() {
    this.getBestRateCount++
  }

  @Mutation
  increaseIsAllowancedCount() {
    this.isAllowancedCount++
  }

  @MutationAction({ mutate: ['showInvertTradePrice'] })
  async setShowInvertTradePrice(showInvertTradePrice: boolean) {
    return { showInvertTradePrice }
  }

  @Mutation
  addTokenPrice(tokenPrice: { [key: string]: string }) {
    this.tokenPrices = { ...this.tokenPrices, ...tokenPrice }
  }

  @MutationAction({ mutate: ['tokenPrices'] })
  async setAllTokenPrices(tokenPrices: object) {
    return { tokenPrices }
  }

  @MutationAction({ mutate: ['priceImpact'] })
  async setPriceImpact(priceImpact: string) {
    return { priceImpact }
  }

  @Mutation
  addTransactionHashHistory(txdata: TransactionHashHistory) {
    this.transactionHashHistory.push(txdata)
  }

  @MutationAction({ mutate: ['transactionHashHistory'] })
  async setTransactionHashHistory(transactionHashHistory: Array<any>) {
    return { transactionHashHistory }
  }

  @MutationAction({ mutate: ['swapGasFees'] })
  async setSwapGasFees(swapGasFees: SwapGasFees) {
    return { swapGasFees }
  }

  @MutationAction({ mutate: ['referralId'] })
  async setReferralId(referralId: string) {
    return { referralId }
  }

  @Mutation
  setTokenApprovalTransactions(tokenApprovalTransactions: TokenApprovalTransaction[]) {
    this.tokenApprovalTransactions = tokenApprovalTransactions
  }

  @Mutation
  async addTokenApprovalTransaction(tokenApprovalTransaction: TokenApprovalTransaction) {
    this.tokenApprovalTransactions.push(tokenApprovalTransaction)
  }

  @Mutation
  async updateTokenApprovalTransaction(payload: { index: number; tokenApprovalTransaction: TokenApprovalTransaction }) {
    this.tokenApprovalTransactions[payload.index] = payload.tokenApprovalTransaction
  }

  @Action({ commit: 'setTokenApprovalTransactions', rawError: true })
  async removeTokenApprovalTransactionByIndex(index: number) {
    const tempData = _.cloneDeep(this.tokenApprovalTransactions)
    tempData.splice(index, 1)

    return tempData
  }

  @MutationAction({ mutate: ['metaData'] })
  async setMetaData(metaData: number) {
    return { metaData }
  }
}

export const SwapModule = getModule(Swap)
export const SwapState = stateDecoratorFactory(SwapModule)
