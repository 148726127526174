



































































import { Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { getTokenImage } from '@/utils/helper'
import { NETWORK_CONSTANT } from '@/constants'
import { Token } from '@/types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import THEME from '@/constants/theme'
import ImageCircle from '@/components/ImageCircle.vue'

@Component({
  name: 'AcceptCustomToken',
  computed: mapGetters(['theme']),
  components: {
    ImageCircle
  }
})
export default class AcceptCustomToken extends AbstractSwapView {
  theme?: string
  chainIDThatSupport = ChainIDThatSupport

  @Prop() readonly token!: Token

  getTokenImage = getTokenImage
  checked = false

  openLinkBSCNewTab() {
    const uri = `${NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].BLOCK_EXPLORER_URL}/token/${this.token.address}`
    window.open(uri, '_blank')
  }

  @Emit()
  handleContinueBtn() {
    return this.token
  }

  @Watch('checked')
  async handleCheckBoxChange(val: boolean) {
    this.amplitudeLogEvent('Click understand the risk', { checked: val })
  }

  get iconBsc() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-bsc-link-on-modal-space.svg')
    }

    return require('@/assets/svg/icon-bsc-link-on-modal.svg')
  }
}
