import Web3 from 'web3'
import Web3Modal, { IProviderOptions } from 'web3modal'
import { getWalletInfo } from '../utils'
import { WalletProvider, WalletConnector } from '../types'

export class InjectedWeb3Connector implements WalletConnector {
  public web3: Web3 | null = null
  public provider: any = null

  getWalletConnectWeb3Modal() {
    console.log('getWalletConnectWeb3Modal')
    const providerOptions: IProviderOptions = {
    }
    console.log('providerOptions', providerOptions)

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: false
    })
    return web3Modal
  }

  async connect() {
    const web3Modal = this.getWalletConnectWeb3Modal()
    this.provider = await web3Modal.connect()
    this.web3 = new Web3(this.provider)
    const accounts = await this.web3.eth.getAccounts()
    return {
      provider: this.provider,
      web3: this.web3,
      accounts,
      walletInfo: getWalletInfo(this.provider, WalletProvider.INJECTED_WEB3)
    }
  }

  async disconnect() {
    // @ts-ignore
    if (this.web3 && this.web3.currentProvider && this.web3.currentProvider.close) {
      console.log('close wallet provider', this.web3.currentProvider)
      // @ts-ignore
      await this.web3.currentProvider.close()
    }
    this.provider = null
    this.web3 = null
  }
}
