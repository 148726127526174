














import { Component, Mixins } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import SwitchNetworkList from './SwitchNetworkList.vue'
import { Network, ChainIDThatSupport } from '@/features/Web3Connector/types'
import { AbstractAmplitudeView } from '../Amplitude/abstractView'

@Component({
  name: 'SwitchNetworkDropdown',
  components: {
    SwitchNetworkList
  }
})

export default class SwitchNetworkDropdown extends Mixins(AbstractWeb3ConnectorView, AbstractAmplitudeView) {
  switchNetworkPopupOpened = false

  SwitchNetworkPopup() {
    this.switchNetworkPopupOpened = !this.switchNetworkPopupOpened
    if (this.switchNetworkPopupOpened) {
      this.amplitudeLogEvent('Click open switch network dropdown')
    } else {
      this.amplitudeLogEvent('Click close switch network dropdown')
    }
  }

  onClickOutside() {
    this.switchNetworkPopupOpened = false
    this.amplitudeLogEvent('Click close switch network dropdown')
  }

  async handleSelectNetwork(networkType: Network) {
    try {
      type NetworkExclude = Exclude<Network, Network.private>
      this.amplitudeLogEvent('Select network in switch network dropdown', { network: networkType })
      const netId = ChainIDThatSupport[networkType as NetworkExclude]
      await this.switchNetwork(netId)
      this.switchNetworkPopupOpened = false
    } catch (error) {
      console.error(error)
    }
  }
}
