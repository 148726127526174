import { Component, Mixins, Watch } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '../../Web3Connector/abstractView'
import { AbstractMwadNftView } from '@/features/MwadNft/abstractView'
import { AbstractAmplitudeView } from '@/features/Amplitude/abstractView'
import { AbstractSentryView } from '@/features/Sentry/abstractView'
import { BestRate } from '../Entities/BestRate'
import { Swap } from '../Entities/Swap'
import { SwapState, SwapModule } from '../store'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import ERC20_ABI from '@/constants/abis/erc20.json'
import MASTERCHEF_ABI from '@/constants/abis/masterchef.json'
import Bignumber from '@/utils/bignumber'
import { ethers, utils, constants } from 'ethers'
import { Multicall as EthereumMulticall, ContractCallContext } from 'ethereum-multicall'
import { getAddress } from '@ethersproject/address'
import { TransactionResponse, TransactionReceipt } from '@ethersproject/providers'
import * as _ from '@/utils/lodash-extended'
import bscTokens from '@/constants/token/bscTokens.json'
import polygontokens from '@/constants/token/polygonTokens.json'
import arbitrumTokens from '@/constants/token/arbitrumTokens.json'
import ethTokens from '@/constants/token/ethTokens.json'
import avalancheTokens from '@/constants/token/avalancheTokens.json'
import optimismTokens from '@/constants/token/optimismTokens.json'

import { isValuesIncludeInList, parseLogs } from '../utils'
import { getWardenAbis, genGasFeeDataFromSelectedGasSpeed, delay } from '@/features/Web3Connector/utils'
import { Network, ChainIDThatSupport, GasfeeData, GasSpeed, ChainIdSupportWadToken } from '@/features/Web3Connector/types'
import {
  Token,
  TokenInput,
  TokenInputType,
  CurrentBestRateSdk,
  ApprovalState,
  BestRateQueryState,
  TradeState,
  BestRateQuerySide,
  TransactionHashHistory,
  TransactionReceiptData,
  ApprovalTransactionStatus,
  FeeDiscountType
} from '@/types'
import {
  DEFAULT_GAS_LIMIT_FOR_READ_METHOD,
  MAX_PRICE_IMPACT,
  NETWORK_CONSTANT
} from '@/constants'
import {
  isAddress,
  loadUserStorage,
  checkIsWrapOrUnwrapNativeToken,
  setPriceSlippageInlocalStorage,
  generateEtheruemMulticallOptions
} from '@/utils/helper'

import farmsConfig from '@/constants/farms'
import {
  SwapGasFees,
  WardenBestRateSdkTagVersion,
  TokenApprovalTransaction,
  TokensBalance
} from '../types'
import { AbstractOptiPunkNftView } from '@/features/OptiPunkNft/abstractView'

@Component
export class AbstractSwapView extends Mixins(
  AbstractWeb3ConnectorView,
  AbstractMwadNftView,
  AbstractOptiPunkNftView,
  AbstractAmplitudeView,
  AbstractSentryView,
  BestRate,
  Swap
) {
  @SwapState public readonly allToken!: Token[]
  @SwapState public readonly tokensBalance!: TokensBalance
  @SwapState public readonly tokenAInput!: TokenInput
  @SwapState public readonly tokenBInput!: TokenInput
  @SwapState public readonly currentBestRate!: CurrentBestRateSdk[]
  @SwapState public readonly tokenPrices!: { [key: string]: string }
  @SwapState public readonly priceSlippage!: string
  @SwapState public readonly bestRateQueryState!: BestRateQueryState
  @SwapState public readonly bestRateQuerySide!: BestRateQuerySide
  @SwapState public readonly lastBestRateQuerySide!: BestRateQuerySide
  @SwapState public readonly approvalState!: ApprovalState
  @SwapState public readonly tradeState!: TradeState
  @SwapState public readonly computeTradePrice!: string | null
  @SwapState public readonly getBestRateCount!: number
  @SwapState public readonly isAllowancedCount!: number
  @SwapState public readonly showInvertTradePrice!: boolean
  @SwapState public readonly priceImpact!: string
  @SwapState public readonly transactionHashHistory!: TransactionHashHistory[]
  @SwapState public readonly swapGasFees!: SwapGasFees
  @SwapState public readonly tokenApprovalTransactions!: TokenApprovalTransaction[]

  @Web3ConnectorState public readonly wardenBestRateSdk1!: WardenBestRateSdkTagVersion | null
  @Web3ConnectorState public readonly wardenBestRateSdk2!: WardenBestRateSdkTagVersion | null
  @Web3ConnectorState public readonly provider: any | null
  @Web3ConnectorState public readonly ethersProvider!: ethers.providers.Web3Provider
  @Web3ConnectorState public readonly userAddress!: string
  @Web3ConnectorState public readonly network!: Network | 'disconnected'
  @Web3ConnectorState public readonly gasFeeData!: GasfeeData
  @Web3ConnectorState public readonly gasFeeL2Data!: GasfeeData
  @Web3ConnectorState public readonly isWalletProviderSupportEip1559!: boolean | null
  @Web3ConnectorState public readonly selectedGasSpeed!: GasSpeed

  tokenInputDelayTimer: undefined | ReturnType<typeof setTimeout> = setTimeout(() => '', 100)

  public handleTokenToVuexState(tokenSelected: Token) {
    if (this.allToken.some((token: Token) => token?.address === tokenSelected.address)) {
      return
    }
    this.increaseTokenInState(tokenSelected)
  }

  increaseTokenInState(tokenData: Token) {
    const tempOfAllToken: Token[] = [...this.allToken]
    tempOfAllToken.push(tokenData)
    SwapModule.setAllToken(tempOfAllToken)
  }

  decreaseTokenInState(tokenAddress: string) {
    let tempOfAllToken: Token[] = [...this.allToken]
    tempOfAllToken = tempOfAllToken.filter((token: Token) => token.address !== tokenAddress)
    SwapModule.setAllToken(tempOfAllToken)
    this.clearTokenInputWhenDecreaseTokenInState(tokenAddress)
  }

  handleUpdateAllToken() {
    const currentUserStorage = loadUserStorage()
    if (!currentUserStorage.hasOwnProperty('tokens')) {
      return
    }
    if (currentUserStorage?.tokens[this.networkId] === undefined) {
      return
    }
    const tokenFromUserStorage: Token[] = []
    Object.values(currentUserStorage.tokens[this.networkId]).forEach(token => {
      if (!this.allToken.some(t => getAddress(t.address) === getAddress(token.address))) {
        tokenFromUserStorage.push(token)
      }
    })
    const allTokenIncludeTokenFromUserStorage: Token[] = [...this.allToken, ...tokenFromUserStorage]
    SwapModule.setAllToken(allTokenIncludeTokenFromUserStorage)
  }

  async handleTokenNetworkChange(network: Network | 'disconnected') {
    switch (network) {
      case Network.bsc:
        await SwapModule.setAllToken(bscTokens)
        break
      case Network.polygon:
        await SwapModule.setAllToken(polygontokens)
        break
      case Network.arbitrum:
        await SwapModule.setAllToken(arbitrumTokens)
        break
      case Network.ethereum:
        await SwapModule.setAllToken(ethTokens)
        break
      case Network.avalanche:
        await SwapModule.setAllToken(avalancheTokens)
        break
      case Network.optimism:
        await SwapModule.setAllToken(optimismTokens)
        break
    }
  }

  async getTokenInfo(tokenAddress: string): Promise<Token | Error> {
    if (!isAddress(tokenAddress)) {
      return new Error(`${tokenAddress} is not token contract address`)
    }
    const ethereumMulticall = new EthereumMulticall(
      generateEtheruemMulticallOptions(this.networkId, this.ethersProvider)
    )
    const contractCallContext: ContractCallContext[] = ['decimals', 'symbol', 'name'].map(
      (methodName: string): ContractCallContext => {
        return {
          reference: methodName,
          contractAddress: tokenAddress,
          abi: ERC20_ABI,
          calls: [{ reference: methodName, methodName, methodParameters: [] }]
        }
      }
    )
    const { results } = await ethereumMulticall.call(contractCallContext)
    const decimals = results.decimals.callsReturnContext[0].returnValues[0]
    const symbol = results.symbol.callsReturnContext[0].returnValues[0]
    const name = results.name.callsReturnContext[0].returnValues[0]

    return { address: tokenAddress, decimals, symbol, name }
  }

  public async clearDataAfterTradeSuccess(transactionReceiptData: TransactionReceiptData) {
    if (this.tradeState !== TradeState.SUCCESS) {
      return
    }
    if (
      getAddress(transactionReceiptData.srcAssetAddress) !== getAddress(this.tokenAInput.address as string) ||
      (!Bignumber(transactionReceiptData.srcAmount).isEqualTo(this.tokenAInput.amount) &&
        this.networkId !== ChainIDThatSupport.arbitrum)
    ) {
      // If user close modal between wait confirm transaction and change addrss, token input shoud't clear data
      return
    }
    if (
      this.networkId === ChainIDThatSupport.arbitrum &&
      Bignumber(this.tokenAInput.amount)
        .minus(transactionReceiptData.srcAmount)
        .absoluteValue()
        .gt(1)
    ) {
      // Protect feature remain token 1 WEI, should clear data
      return
    }

    const tempTokenA = this.tokenAInput
    const tempTokenB = this.tokenBInput
    await SwapModule.setTokenAInputAmount('')
    await SwapModule.setTokenBInputAmount('')
    await SwapModule.setCurrentBestRate([])
    await SwapModule.setSwapGasFees({ usd: '0', nativeTokenInBase: '0' })
    await SwapModule.setPriceImpact('0.00')
    await SwapModule.setBestRateQueryState(BestRateQueryState.UNKNOWN)
    await SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
    const tokenAData = this.allToken.find((token: Token) => token.address === tempTokenA.address) as Token
    const tokenBData = this.allToken.find((token: Token) => token.address === tempTokenB.address) as Token
    this.getTokensBalance([tokenAData, tokenBData])
  }

  public async clearDataWhenEventChange() {
    await Promise.all([
      SwapModule.setTokenAInput({}),
      SwapModule.setTokenBInput({}),
      SwapModule.setBestRateResultSdk({}),
      SwapModule.setCurrentBestRate([]),
      SwapModule.setSwapGasFees({ usd: '0', nativeTokenInBase: '0' }),
      SwapModule.setPriceImpact('0.00'),
      SwapModule.clearTokensBalance(),
      SwapModule.setAllTokenPrices({}),
      SwapModule.setTokenApprovalTransactions([]),
      SwapModule.setTransactionHashHistory([])
    ])
    SwapModule.setBestRateQueryState(BestRateQueryState.UNKNOWN)
    SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
    SwapModule.setApprovalState(ApprovalState.UNKNOWN)
    SwapModule.setTradeState(TradeState.UNKNOWN)
  }

  private clearTokenInputWhenDecreaseTokenInState(tokenAddress: string) {
    if (this.tokenAInput.address === tokenAddress) {
      SwapModule.setTokenAInput({})
    }
    if (this.tokenBInput.address === tokenAddress) {
      SwapModule.setTokenBInput({})
    }
  }

  public async getTokensBalance(tokenList: Token[] = this.allToken, maxRetryCount = 3): Promise<any> {
    if (!this.userAddress || !this.isCorrectNetwork) {
      return
    }
    const tempTokenList = _.cloneDeep(tokenList)
    const nativeTokenIndex = tokenList.findIndex(
      (token: Token) => token.address === NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN.address
    )
    if (nativeTokenIndex >= 0) {
      const nativeToken = tempTokenList.splice(nativeTokenIndex, 1)
      const balanceInwei = await (this.ethersProvider as ethers.providers.Web3Provider).getBalance(this.userAddress)
      const nativeTokenBalance = utils.formatEther(balanceInwei).toString()
      const newData: TokensBalance = {
        [nativeToken[0].address]: nativeTokenBalance
      }
      SwapModule.setTokensBalance(newData)
    }

    const erc20Interface = new utils.Interface(ERC20_ABI)
    const balanceOfFragment = JSON.parse(erc20Interface.getFunction('balanceOf').format(utils.FormatTypes.json))

    const contractCallContext: ContractCallContext[] = tempTokenList.map(
      (token: Token): ContractCallContext => {
        return {
          reference: token.address,
          contractAddress: token.address,
          abi: [balanceOfFragment],
          calls: [{ reference: token.address, methodName: 'balanceOf', methodParameters: [this.userAddress] }]
        }
      }
    )
    const ethereumMulticall = new EthereumMulticall(
      generateEtheruemMulticallOptions(this.networkId, this.ethersProvider, true)
    )
    const { results } = await ethereumMulticall.call(contractCallContext)
    const mapNewResult = Object.values(results).map(data => {
      return {
        tokenAddress: data.originalContractCallContext.contractAddress,
        amount: data.callsReturnContext[0].returnValues[0],
        success: data.callsReturnContext[0].success
      }
    })
    const resultSuccessfully = mapNewResult.filter(data => data.success === true)
    const newData: TokensBalance = {}

    resultSuccessfully.forEach(data => {
      const tokenData = tempTokenList.find((token: Token) => token.address === data.tokenAddress) as Token
      const balanceInBase = utils.formatUnits(data.amount, tokenData.decimals)

      newData[data.tokenAddress] = balanceInBase
    })
    SwapModule.setTokensBalance(newData)

    // Flow when mullticall result failed shoud retry getTokensBalance again
    const resultFailed = mapNewResult.filter(data => data.success === false)
    if (resultFailed.length && maxRetryCount) {
      const tokenListForNext = tokenList.filter((token: Token) =>
        resultFailed.find(data => data.tokenAddress === token.address)
      )
      await delay(1000)
      this.getTokensBalance(tokenListForNext, --maxRetryCount)
    }
  }

  public setTokenInput(type: TokenInputType, tokenInput: TokenInput): void {
    SwapModule.increaseGetBestRateCount()
    SwapModule.setBestRateResultSdk({})
    SwapModule.setCurrentBestRate([])

    if (tokenInput?.address) {
      this.handleTokenPrice(tokenInput.address)
    }
    switch (type) {
      case 'tokenA': {
        // Manage approval state
        const { isFound, data: tokenApprovalTransaction } = this.findTokenApprovalTransactionsInState(
          tokenInput.address as string
        )
        if (isFound && (tokenApprovalTransaction as TokenApprovalTransaction).approvalState === ApprovalState.PENDING) {
          SwapModule.setApprovalState(ApprovalState.PENDING)
        } else {
          SwapModule.setApprovalState(ApprovalState.UNKNOWN)
        }

        SwapModule.setTokenAInput(tokenInput)
        if (this.tokenAInput?.address === this.tokenBInput.address) {
          SwapModule.setTokenBInput({})
          return
        }
        if (!this.tokenAInput.amount || (this.tokenAInput?.amount && Bignumber(this.tokenAInput.amount).isZero())) {
          SwapModule.setTokenBInputAmount('')
          return
        }
        this.handelBestRateQuery('fromTokenAToB')
        break
      }
      case 'tokenB':
        SwapModule.setTokenBInput(tokenInput)
        if (this.tokenBInput?.address === this.tokenAInput.address) {
          SwapModule.setTokenAInput({})
          return
        }
        this.handelBestRateQuery('fromTokenBToA')
        break
    }
  }

  public setTokenInputAmount(type: TokenInputType, tokenInputAmount: string): void {
    SwapModule.increaseGetBestRateCount()
    SwapModule.setBestRateResultSdk({})
    SwapModule.setCurrentBestRate([])
    if (this.tokenInputDelayTimer) {
      clearTimeout(this.tokenInputDelayTimer)
    }
    switch (type) {
      case 'tokenA':
        SwapModule.setTokenAInputAmount(tokenInputAmount)
        this.amplitudeLogEvent('Input tokenA amount', { tokenAmountInBase: tokenInputAmount })

        if (Bignumber(tokenInputAmount).isZero() || tokenInputAmount === '') {
          SwapModule.setTokenBInputAmount('')
          return
        }
        this.tokenInputDelayTimer = setTimeout(async() => {
          this.handelBestRateQuery('fromTokenAToB')
        }, 500)
        break
      case 'tokenB':
        SwapModule.setTokenBInputAmount(tokenInputAmount)
        this.amplitudeLogEvent('Input tokenB amount', { tokenAmountInBase: tokenInputAmount })

        if (Bignumber(tokenInputAmount).isZero() || tokenInputAmount === '') {
          SwapModule.setTokenAInputAmount('')
          return
        }
        this.tokenInputDelayTimer = setTimeout(async() => {
          this.handelBestRateQuery('fromTokenBToA')
        }, 500)
        break
    }
  }

  public async swapTokenInput() {
    const oldTokenAInput = Object.assign({}, JSON.parse(JSON.stringify(this.tokenAInput)))
    const oldTokenBInput = Object.assign({}, JSON.parse(JSON.stringify(this.tokenBInput)))
    // ---------------------------
    // TODO: use this feature when close feature find best rate token B -> token A
    delete oldTokenAInput?.amount
    delete oldTokenBInput?.amount
    // bypass if user click swap token and in this time system find best rate
    await SwapModule.increaseGetBestRateCount()
    // ---------------------------
    SwapModule.setTokenAInput(oldTokenBInput)
    SwapModule.setTokenBInput(oldTokenAInput)
    SwapModule.setBestRateResultSdk({})
    SwapModule.setCurrentBestRate([])

    this.amplitudeLogEvent('Click icon swap tokenA and tokenB', {
      currentTokenAAddress: oldTokenAInput.address ? getAddress(oldTokenAInput.address) : null,
      currentTokenBAddress: oldTokenBInput.address ? getAddress(oldTokenBInput.address) : null
    })
    // TODO: close feature
    // if (
    //   this.tokenAInput?.address &&
    //   this.tokenAInput?.amount &&
    //   !Bignumber(this.tokenAInput.amount).isZero() &&
    //   this.isCorrectNetwork
    // ) {
    //   await this.findBestRateTokenAToTokenB(this.tokenAInput.amount)
    // } else if (
    //   this.tokenBInput?.address &&
    //   this.tokenBInput?.amount &&
    //   !Bignumber(this.tokenBInput.amount).isZero() &&
    //   this.isCorrectNetwork
    // ) {
    //   await this.findBestRateTokenBToTokenA(this.tokenBInput.amount)
    // }
  }

  public async isAllowanced(userAddress: string): Promise<void> {
    await SwapModule.increaseIsAllowancedCount()
    const stampIsAllowancedCount = this.isAllowancedCount
    if (!this.isCorrectNetwork) {
      return
    }

    // Token A input should not empty
    const tokenAInputAddress = this.tokenAInput?.address
    if (!tokenAInputAddress) {
      await SwapModule.setApprovalState(ApprovalState.UNKNOWN)
      return
    }

    // Token A input should not native token
    const tokenAInputData = this.allToken.find(
      (token: Token) => getAddress(token.address) === getAddress(tokenAInputAddress)
    ) as Token
    const nativeToken = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN
    if (getAddress(tokenAInputAddress) === getAddress(nativeToken.address)) {
      await SwapModule.setApprovalState(ApprovalState.UNKNOWN)
      return
    }

    // Token A balance should not zero
    await this.getTokensBalance([tokenAInputData])
    if (Bignumber(this.tokensBalance[this.tokenAInput?.address as string]).isZero()) {
      await SwapModule.setApprovalState(ApprovalState.UNKNOWN)
      return
    }

    const allowanceAmount = await this.getAllowanceAmount(userAddress, tokenAInputAddress)
    if (stampIsAllowancedCount !== this.isAllowancedCount) {
      return
    }
    const tokenAInputAmount = this.tokenAInput?.amount ?? '0'
    const tokenAInputAmountWei = utils.parseUnits(tokenAInputAmount, tokenAInputData.decimals)

    const { isFound, index: txApporveTokenIndex, data } = this.findTokenApprovalTransactionsInState(tokenAInputAddress)
    if (allowanceAmount.isZero() || Bignumber(allowanceAmount.toString()).lt(tokenAInputAmountWei.toString())) {
      if (isFound && (data as TokenApprovalTransaction).approvalState === ApprovalState.PENDING) {
        SwapModule.setApprovalState(ApprovalState.PENDING)
      } else {
        SwapModule.setApprovalState(ApprovalState.NOT_APPROVED)
      }
      return
    }

    SwapModule.setApprovalState(ApprovalState.APPROVED)
    if (isFound) {
      await SwapModule.removeTokenApprovalTransactionByIndex(txApporveTokenIndex)
    }
  }

  private async getAllowanceAmount(userAddress: string, tokenAddress: string) {
    const signer = this.ethersProvider.getSigner()
    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer)
    const routingContractAddress =
      NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].WARDEN_ROUTING_CONTRACT_ADDRESS
    const allowanceAmount = await tokenContract.allowance(userAddress, routingContractAddress, {
      gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD
    })

    return allowanceAmount
  }

  findTokenApprovalTransactionsInState(tokenAddress: string): { isFound: boolean; index: number; data: object } {
    const foundTxApporveTokenIndex = this.tokenApprovalTransactions.findIndex(
      (tokenApprovalTransaction: TokenApprovalTransaction) => {
        return getAddress(tokenApprovalTransaction.tokenAddress) === getAddress(tokenAddress)
      }
    )
    if (foundTxApporveTokenIndex !== -1) {
      return {
        isFound: true,
        index: foundTxApporveTokenIndex,
        data: this.tokenApprovalTransactions[foundTxApporveTokenIndex]
      }
    }
    return { isFound: false, index: foundTxApporveTokenIndex, data: {} }
  }

  async approveToken() {
    try {
      const tokenAAddress = this.tokenAInput.address as string
      const tokenAData = this.allToken.find((token: Token) => token.address === tokenAAddress) as Token
      const amountToApprove = this.tokenAInput.amount || this.tokensBalance[tokenAAddress]
      const amountToApproveWei = (amountToApprove && (utils.parseUnits(amountToApprove, tokenAData.decimals)).toString()) ?? undefined
      let useExact = false

      const etherProvider = new ethers.providers.Web3Provider(this.provider)
      const signer = etherProvider.getSigner()
      const tokenContract = new ethers.Contract(tokenAAddress, ERC20_ABI, signer)
      const routingContractAddress =
        NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].WARDEN_ROUTING_CONTRACT_ADDRESS
      const estimatedGas = await this.estimateGasForContract(tokenContract, 'approve', [
        routingContractAddress,
        constants.MaxUint256
      ]).catch(() => {
        useExact = true
        if (!amountToApproveWei) {
          throw Error('Token balance not enough')
        }
        return this.estimateGasForContract(tokenContract, 'approve', [
          routingContractAddress,
          amountToApproveWei
        ])
      })
      let gasFeeDataForApproveToken: GasfeeData
      if ([ChainIDThatSupport.arbitrum, ChainIDThatSupport.optimism].includes(this.networkId)) {
        gasFeeDataForApproveToken = this.gasFeeL2Data
      } else {
        gasFeeDataForApproveToken = this.gasFeeData
      }
      const gasFeeDataFromSelectedGasSpeed = genGasFeeDataFromSelectedGasSpeed(
        gasFeeDataForApproveToken,
        this.selectedGasSpeed,
        this.isWalletProviderSupportEip1559 as boolean,
        this.networkId
      )
      const transactionResponse: TransactionResponse = await tokenContract.approve(
        routingContractAddress,
        useExact ? amountToApproveWei : constants.MaxUint256,
        { gasLimit: estimatedGas, ...gasFeeDataFromSelectedGasSpeed }
      )

      await this.waitApproveTokenConfirm(transactionResponse)
    } catch (error) {
      if (error.message.includes('User denied transaction signature')) {
        this.amplitudeLogEvent('User denied transaction signature (Approve token)', {
          senderAddress: this.userAddress,
          tokenAInputAddress: this.tokenAInput.address,
          tokenAInputSymbol: this.tokenAInput.symbol
        })
      } else {
        this.sentryLogError(this.network, error, 'Error', {
          tokenAAddress: this.tokenAInput.address,
          tokenASymbol: this.tokenAInput.symbol
        })
      }
      throw error
    }
  }

  public setPriceSlippage(percentage: number | string) {
    SwapModule.setPriceSlippage(percentage.toString())
    setPriceSlippageInlocalStorage(percentage.toString())
  }

  public async waitTransactionConfirm(
    transactionResponse: TransactionResponse
  ): Promise<TransactionReceiptData | undefined> {
    try {
      SwapModule.setTradeState(TradeState.WAIT_TX_CONFIRM)
      SwapModule.addTransactionHashHistory({ transactionHash: transactionResponse.hash })
      const transactionReceipt: TransactionReceipt = await transactionResponse.wait()
      if (_.last(this.transactionHashHistory)?.transactionHash !== transactionReceipt.transactionHash) {
        const transactionReceiptData = await this.getTransactionReceiptData(transactionResponse, transactionReceipt)
        this.amplitudeLogEvent('Swap success and get transaction receipt', transactionReceiptData)
        return
      }

      if (transactionReceipt.status === 1) {
        SwapModule.setTradeState(TradeState.SUCCESS)
        const transactionReceiptData = await this.getTransactionReceiptData(transactionResponse, transactionReceipt)
        this.clearDataAfterTradeSuccess(transactionReceiptData)
        this.amplitudeLogEvent('Swap success and get transaction receipt', {
          transactionReceiptData,
          tradeFeeDiscount: this.tradeFeeDiscount
        })
        this.getTokensBalance()
        return transactionReceiptData
      } else {
        SwapModule.setTradeState(TradeState.FAIL)
        this.amplitudeLogEvent('Swap faild', {
          transactionReceipt,
          tradeFeeDiscount: this.tradeFeeDiscount
        })
      }
    } catch (error) {
      SwapModule.setTradeState(TradeState.FAIL)
      this.amplitudeLogEvent('Swap faild', {
        errorMessage: error?.message,
        transactionResponse,
        tradeFeeDiscount: this.tradeFeeDiscount
      })
      throw error
    }
  }

  private async getTransactionReceiptData(
    transactionResponse: TransactionResponse,
    transactionReceipt: TransactionReceipt
  ): Promise<TransactionReceiptData> {
    const swapContractAddr = getAddress(transactionResponse.to as string)
    const routingContractAddrssMapChainId = {
      [getAddress(NETWORK_CONSTANT[ChainIDThatSupport.bsc].WARDEN_ROUTING_CONTRACT_ADDRESS)]: ChainIDThatSupport.bsc,
      [getAddress(
        NETWORK_CONSTANT[ChainIDThatSupport.polygon].WARDEN_ROUTING_CONTRACT_ADDRESS
      )]: ChainIDThatSupport.polygon,
      [getAddress(
        NETWORK_CONSTANT[ChainIDThatSupport.ethereum].WARDEN_ROUTING_CONTRACT_ADDRESS
      )]: ChainIDThatSupport.ethereum,
      [getAddress(
        NETWORK_CONSTANT[ChainIDThatSupport.avalanche].WARDEN_ROUTING_CONTRACT_ADDRESS
      )]: ChainIDThatSupport.avalanche,
      [getAddress(
        NETWORK_CONSTANT[ChainIDThatSupport.optimism].WARDEN_ROUTING_CONTRACT_ADDRESS
      )]: ChainIDThatSupport.optimism,
      [getAddress(
        NETWORK_CONSTANT[ChainIDThatSupport.arbitrum].WARDEN_ROUTING_CONTRACT_ADDRESS
      )]: ChainIDThatSupport.arbitrum
    }
    const chainId = routingContractAddrssMapChainId[swapContractAddr]
    if (!chainId) {
      throw Error(`getTransactionReceiptData not support for contract address ${transactionResponse.to}`)
    }
    const logFromSwapContract = transactionReceipt.logs.filter(log => getAddress(log.address) === swapContractAddr)
    const groupOfAbis = getWardenAbis(chainId)
    const logs = parseLogs(logFromSwapContract, groupOfAbis.wardenRoutingAbi)
    const eventTrade = logs.find(log => log.name === 'Trade')
    if (!eventTrade) {
      throw Error('Transaction receipt data not found event trade')
    }
    let srcAssetData = this.allToken.find(
      (token: Token) => getAddress(token.address) === getAddress(eventTrade.args.srcAsset)
    ) as Token
    let destAssetData = this.allToken.find(
      (token: Token) => getAddress(token.address) === getAddress(eventTrade.args.destAsset)
    ) as Token
    const transactionReceiptData: any = {
      srcAssetAddress: eventTrade.args.srcAsset,
      destAssetAddress: eventTrade.args.destAsset
    }
    if (!srcAssetData) {
      srcAssetData = (await this.getTokenInfo(eventTrade.args.srcAsset)) as Token
    }
    if (!destAssetData) {
      destAssetData = (await this.getTokenInfo(eventTrade.args.destAsset)) as Token
    }
    transactionReceiptData.srcAssetData = srcAssetData
    transactionReceiptData.destAssetData = destAssetData
    transactionReceiptData.srcAmount = utils.formatUnits(eventTrade.args.srcAmount, srcAssetData.decimals).toString()
    transactionReceiptData.destAmount = utils.formatUnits(eventTrade.args.destAmount, destAssetData.decimals).toString()
    return transactionReceiptData
  }

  public async waitApproveTokenConfirm(transactionResponse: TransactionResponse): Promise<void> {
    SwapModule.setApprovalState(ApprovalState.PENDING)
    await SwapModule.addTokenApprovalTransaction({
      tokenAddress: transactionResponse.to as string,
      txHash: transactionResponse.hash,
      txStatus: ApprovalTransactionStatus.PENDING,
      approvalState: ApprovalState.PENDING
    })

    this.ethersProvider.once(transactionResponse.hash, async(transactionReceipt: TransactionReceipt) => {
      const tokenAddress = transactionReceipt.to
      const {
        isFound,
        index: txApporveTokenIndex,
        data: tokenApprovalTransaction
      } = this.findTokenApprovalTransactionsInState(tokenAddress)

      if (transactionReceipt.status === 1) {
        const newTokenApprovalTransaction = _.cloneDeep(tokenApprovalTransaction) as TokenApprovalTransaction
        newTokenApprovalTransaction.txStatus = ApprovalTransactionStatus.SUCCESS
        await SwapModule.updateTokenApprovalTransaction({
          index: txApporveTokenIndex,
          tokenApprovalTransaction: newTokenApprovalTransaction
        })
      } else {
        if (isFound) {
          await SwapModule.removeTokenApprovalTransactionByIndex(txApporveTokenIndex)
        }
        if (this.tokenAInput.address && getAddress(this.tokenAInput.address) === getAddress(tokenAddress)) {
          SwapModule.setApprovalState(ApprovalState.NOT_APPROVED)
        }
      }
    })
  }

  private handelBestRateQuery(type: 'fromTokenAToB' | 'fromTokenBToA') {
    if (!this.isCorrectNetwork) {
      return
    }
    switch (type) {
      case 'fromTokenAToB': {
        if (this.tokenAInput?.address && this.tokenAInput?.amount && this.tokenBInput?.address) {
          this.findBestRateTokenAToTokenB(this.tokenAInput.amount)
        }
        break
      }
      case 'fromTokenBToA': {
        if (this.tokenBInput?.address && this.tokenBInput?.amount && this.tokenAInput?.address) {
          // TODO: close feature
          // this.findBestRateTokenBToTokenA(this.tokenBInput.amount)
        } else if (
          this.tokenBInput?.address &&
          (!this.tokenBInput.hasOwnProperty('amount') || Bignumber(this.tokenBInput?.amount).isZero()) &&
          this.tokenAInput?.address &&
          this.tokenAInput?.amount
        ) {
          this.findBestRateTokenAToTokenB(this.tokenAInput.amount)
        }
        break
      }
    }
  }

  public async findWADPrice(): Promise<void> {
    try {
      if (!this.isCorrectNetwork || [this.wardenBestRateSdk1, this.wardenBestRateSdk2].every(val => val === null)) {
        return
      }
      const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
      if (!wardenTokenData) {
        return
      }
      await this.handleTokenPrice(wardenTokenData.address)
    } catch (error) {
      if (error?.data?.code === -32000 && error?.data?.message === 'header not found') {
        // bypass error from metamask when system use something not on mainnet
        return
      }
      console.error(error)
    }
  }

  public async getWardenFarmInfo(): Promise<any> {
    try {
      // Farm info for network BSC only
      if (this.networkId !== ChainIDThatSupport.bsc || !this.userAddress) {
        return
      }

      const ethereumMulticall = new EthereumMulticall(
        generateEtheruemMulticallOptions(this.networkId, this.ethersProvider)
      )
      const contractCallContext: ContractCallContext[] = farmsConfig.map(
        (farm): ContractCallContext => {
          return {
            reference: farm.pid.toString(),
            contractAddress: NETWORK_CONSTANT[ChainIDThatSupport.bsc].WARDEN_MASTER_CHEF_ADDRESS,
            abi: MASTERCHEF_ABI,
            calls: [
              {
                reference: farm.pid.toString(),
                methodName: 'pendingWarden',
                methodParameters: [farm.pid, this.userAddress]
              }
            ]
          }
        }
      )

      const erc20Interface = new utils.Interface(ERC20_ABI)
      const balanceOfFragment = JSON.parse(erc20Interface.getFunction('balanceOf').format(utils.FormatTypes.json))

      const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport.bsc].WARDEN_TOKEN // For bsc only
      contractCallContext.push({
        reference: wardenTokenData.address,
        contractAddress: wardenTokenData.address,
        abi: [balanceOfFragment],
        calls: [{ reference: wardenTokenData.address, methodName: 'balanceOf', methodParameters: [this.userAddress] }]
      })

      const { results } = await ethereumMulticall.call(contractCallContext)
      const amountWei = ethers.BigNumber.from(
        results[wardenTokenData.address].callsReturnContext[0].returnValues[0]
      ).toString()
      const wadTokenBalanceOfUser = utils.formatUnits(amountWei, wardenTokenData.decimals).toString()
      delete results[wardenTokenData.address]

      const earningsSum = Object.values(results).reduce((accum, result) => {
        const earning = ethers.BigNumber.from(result.callsReturnContext[0].returnValues[0]).toString()
        return Bignumber(accum).plus(utils.formatUnits(earning, wardenTokenData.decimals).toString())
      }, 0)
      const unclaimed = earningsSum.toString()
      const allWadToken = Bignumber(wadTokenBalanceOfUser).plus(unclaimed)

      return { unclaimed, wadTokenBalanceOfUser, allWadToken }
    } catch (error) {
      this.sentryLogError(this.network, error, 'Error')
    }
  }

  public async addTokenInMetaMask(tokenData: Token, tokenImageUrl?: string) {
    if (typeof window.ethereum !== 'undefined') {
      const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: Object.assign(
            {
              address: tokenData.address,
              symbol: tokenData.symbol,
              decimals: tokenData.decimals
            },
            tokenImageUrl ? { image: tokenImageUrl } : null
          )
        }
      })
      return tokenAdded
    }
  }

  public swapInvertTradePrice() {
    SwapModule.setShowInvertTradePrice(!this.showInvertTradePrice)
    this.amplitudeLogEvent('Click invert trade price', {
      displaytradePrice: this.computeTradePrice
    })
  }

  public tokenInputVolumeUsd(tokenInput: TokenInput) {
    if (tokenInput?.address && tokenInput?.amount && this.tokenPrices.hasOwnProperty(tokenInput.address)) {
      const totalVolumeUsd = Bignumber(this.tokenPrices[tokenInput.address])
        .multipliedBy(tokenInput.amount)
        .toString()
      return totalVolumeUsd
    }
    return ''
  }

  getVolumeUsdOfToken(tokenInput: TokenInput, amount: string) {
    if (tokenInput?.address && this.tokenPrices.hasOwnProperty(tokenInput.address)) {
      const totalVolumeUsd = Bignumber(this.tokenPrices[tokenInput.address])
        .multipliedBy(amount)
        .toString()
      return totalVolumeUsd
    }
    return ''
  }

  public fetchTokenPrice() {
    const listOfTokenToGetPrice = []
    if (this.tokenAInput?.address) {
      listOfTokenToGetPrice.push(this.tokenAInput.address)
    }
    if (this.tokenBInput?.address) {
      listOfTokenToGetPrice.push(this.tokenBInput.address)
    }
    for (const tokenAddress of listOfTokenToGetPrice) {
      this.handleTokenPrice(tokenAddress)
    }
  }

  public async fetchApprovalTokenTransactions() {
    const txSuccessList = this.tokenApprovalTransactions.filter(
      (tokenApprovalTransaction: TokenApprovalTransaction) => {
        return (
          tokenApprovalTransaction.txStatus === ApprovalTransactionStatus.SUCCESS &&
          tokenApprovalTransaction.approvalState === ApprovalState.PENDING
        )
      }
    )
    if (txSuccessList.length === 0) {
      return
    }
    await Promise.all(
      txSuccessList.map(async tokenApprovalTransaction => {
        const allowanceAmount = await this.getAllowanceAmount(
          this.userAddress as string,
          tokenApprovalTransaction.tokenAddress
        )
        if (!allowanceAmount.isZero()) {
          const { isFound, index: txApporveTokenIndex } = this.findTokenApprovalTransactionsInState(
            tokenApprovalTransaction.tokenAddress
          )
          if (isFound) {
            await SwapModule.removeTokenApprovalTransactionByIndex(txApporveTokenIndex)
          }
          if (
            this.tokenAInput.address &&
            getAddress(this.tokenAInput.address) === getAddress(tokenApprovalTransaction.tokenAddress)
          ) {
            SwapModule.setApprovalState(ApprovalState.APPROVED)
          }
        }
      })
    )
  }

  public foundTokenPairsMatchInList(network: Network, tokenAAddress: string, tokenBAddress: string) {
    switch (network) {
      case Network.arbitrum: {
        const USDC = getAddress(arbitrumTokens.find(token => token.symbol === 'USDC')?.address ?? '')
        const USDT = getAddress(arbitrumTokens.find(token => token.symbol === 'USDT')?.address ?? '')
        const DAI = getAddress(arbitrumTokens.find(token => token.symbol === 'DAI')?.address ?? '')
        return isValuesIncludeInList([tokenAAddress, tokenBAddress], [USDC, USDT, DAI])
      }
      case Network.polygon: {
        const USDC = getAddress(polygontokens.find(token => token.symbol === 'USDC')?.address ?? '')
        const USDT = getAddress(polygontokens.find(token => token.symbol === 'USDT')?.address ?? '')
        const DAI = getAddress(polygontokens.find(token => token.symbol === 'DAI')?.address ?? '')
        return isValuesIncludeInList([tokenAAddress, tokenBAddress], [USDC, USDT, DAI])
      }
      default: {
        throw new Error(`Function foundTokenPairsMatchInList not support for network ${network}`)
      }
    }
  }

  async buyWardenTokenWithMinTokenAmount() {
    if (![ChainIDThatSupport.bsc, ChainIDThatSupport.ethereum].includes(this.networkId)) {
      return
    }
    try {
      let tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
      const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken].WARDEN_TOKEN

      if (getAddress(tokenAData.address) === getAddress(wardenTokenData.address)) {
        tokenAData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken].NATIVE_TOKEN
        await this.swapTokenInput()
        const tokenData: TokenInput = {
          address: tokenAData.address,
          symbol: tokenAData.symbol,
          amount: ''
        }
        await this.setTokenInput('tokenA', tokenData)
        await this.handleTokenPrice(tokenAData.address)
      }
      const wadPrice = (await this.getTokenPrice(wardenTokenData.address)) as string
      const wadTokenAmount = Bignumber(this.feeDiscountFirstTear?.minTokenAmount)
        .multipliedBy(1.03)
        .toString(10) // increase 3%
      const tokenAPrice = this.tokenPrices[tokenAData.address as string]
      const tokenACountPerWadToken = Bignumber(wadPrice)
        .div(tokenAPrice)
        .toString(10)
      const tokenAAmount = Bignumber(wadTokenAmount)
        .multipliedBy(tokenACountPerWadToken)
        .toFixed(tokenAData.decimals)
      if (Bignumber(tokenAAmount).isNaN() || Bignumber(tokenAAmount).isZero()) {
        return
      }
      const tokenData: TokenInput = {
        address: wardenTokenData.address,
        symbol: wardenTokenData.symbol,
        amount: ''
      }
      await this.setTokenInput('tokenB', tokenData)
      await this.setTokenInputAmount('tokenA', tokenAAmount)
    } catch (error) {
      this.sentryLogError(this.network, error, 'Error', {
        tokenAAddress: this.tokenAInput.address
      })
    }
  }

  public setReferralId(refId: string) {
    SwapModule.setReferralId(refId)
  }

  get displayWardenTokenPriceUsd() {
    const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
    if (!wardenTokenData) {
      return
    }
    const wardenPrice = this.tokenPrices[wardenTokenData.address]
    if (Bignumber(wardenPrice).isZero() || Bignumber(wardenPrice).isNaN()) {
      return
    }
    return wardenPrice
  }

  get shouldDisplayWardenTokenPriceUsd() {
    const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
    if (wardenTokenData) {
      return true
    }

    return false
  }

  get isOverMaxPriceImpact() {
    return Bignumber(this.priceImpact).gt(MAX_PRICE_IMPACT)
  }

  get colorOfPriceImpact() {
    if (Bignumber(this.priceImpact).lt('1')) {
      return 'gray'
    } else if (Bignumber(this.priceImpact).lt('3')) {
      return 'gray'
    } else if (Bignumber(this.priceImpact).lt('5')) {
      return 'yellow'
    }
    return 'red'
  }

  // Trade fee services

  get tradeFeeDiscount() {
    return _.maxBy(
      [
        this.wrapOrUnwrapNativeTokenFreeFeeDiscount,
        this.tokenPairsFreeFeeDiscount,
        this.bestFeeDiscountFromMwadNFT,
        this.bestFeeDiscountFromOptiPunkNFT,
        this.bestFeeDiscountFromWardenToken
      ],
      'discountPercentage'
    )
  }

  get wadTokenFeeDiscountTear() {
    switch (this.networkId) {
      case ChainIDThatSupport.bsc:
        return NETWORK_CONSTANT[ChainIDThatSupport.bsc].SWAP_FEE_DISCOUNT.wadTokenfeeDiscountTear
      case ChainIDThatSupport.ethereum:
        return NETWORK_CONSTANT[ChainIDThatSupport.ethereum].SWAP_FEE_DISCOUNT.wadTokenfeeDiscountTear
      case ChainIDThatSupport.optimism :
        return NETWORK_CONSTANT[ChainIDThatSupport.optimism].SWAP_FEE_DISCOUNT.wadTokenfeeDiscountTear
      default:
        return undefined
    }
  }

  get mWadNFTFeeDiscountTear() {
    switch (this.networkId) {
      case ChainIDThatSupport.bsc:
        return NETWORK_CONSTANT[ChainIDThatSupport.bsc].SWAP_FEE_DISCOUNT.mWadNFTfeeDiscountTear
      default:
        return undefined
    }
  }

  get optiPunkNFTFeeDiscountTear() {
    switch (this.networkId) {
      case ChainIDThatSupport.optimism:
        return NETWORK_CONSTANT[ChainIDThatSupport.optimism].SWAP_FEE_DISCOUNT.optiPunkNFTfeeDiscountTear
      default:
        return undefined
    }
  }

  get feeDiscountFirstTear() {
    if (!this.wadTokenFeeDiscountTear) {
      return undefined
    }

    return this.wadTokenFeeDiscountTear.find(fee => fee.tear === 1)
  }

  get wrapOrUnwrapNativeTokenFreeFeeDiscount() {
    try {
      const tokenA = this.tokenAInput.address ? getAddress(this.tokenAInput.address) : ''
      const tokenB = this.tokenBInput.address ? getAddress(this.tokenBInput.address) : ''
      const isWrapOrUnwrapNativeToken = checkIsWrapOrUnwrapNativeToken(tokenA, tokenB, this.networkId)

      if (isWrapOrUnwrapNativeToken) {
        return { discountPercentage: 100, discountType: FeeDiscountType.WARP_OR_UNSWAP_TOKEN }
      }

      return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    } catch (error) {
      return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    }
  }

  get tokenPairsFreeFeeDiscount() {
    try {
      if (![ChainIDThatSupport.polygon, ChainIDThatSupport.arbitrum].includes(this.networkId)) {
        return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
      }
      const tokenAInputAddress = this.tokenAInput.address ? getAddress(this.tokenAInput.address) : ''
      const tokenBInputAddress = this.tokenBInput.address ? getAddress(this.tokenBInput.address) : ''
      const found = this.foundTokenPairsMatchInList(this.network as Network, tokenAInputAddress, tokenBInputAddress)

      if (found) {
        return { discountPercentage: 100, discountType: FeeDiscountType.FREE_FEE_DISCOUNT }
      }

      return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    } catch (error) {
      return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    }
  }

  get bestFeeDiscountFromWardenToken() {
    if (!this.wardenTokenAmountInWallet) {
      return { discountPercentage: '0', discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    }
    for (const fee of _.orderBy(this.wadTokenFeeDiscountTear, ['tear'], ['desc'])) {
      if (Bignumber(this.wardenTokenAmountInWallet).gte(fee.minTokenAmount)) {
        return {
          ...fee,
          discountPercentage: fee.discountPercentage,
          discountType: FeeDiscountType.WAD_TOKEN
        }
      }
    }

    return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
  }

  get bestFeeDiscountFromMwadNFT() {
    if (!this.topMwadClass || this.topMwadClass.found === false) {
      return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    }
    for (const fee of _.orderBy(this.mWadNFTFeeDiscountTear, ['tear'], ['desc'])) {
      if (this.topMwadClass.class === fee.className) {
        return {
          ...fee,
          discountPercentage: fee.discountPercentage,
          discountType: FeeDiscountType.MWAD_NFT
        }
      }
    }

    return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
  }

  get wardenTokenAmountInWallet() {
    const wardenToken = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
    const wardenBalanceInWallet = this.tokensBalance[wardenToken?.address] ?? undefined
    return wardenBalanceInWallet
  }

  get bestFeeDiscountFromOptiPunkNFT() {
    if (!this.topOptiPunkClass || this.topOptiPunkClass.found === false) {
      return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
    }
    for (const fee of _.orderBy(this.optiPunkNFTFeeDiscountTear, ['tear'], ['desc'])) {
      if (this.topOptiPunkClass.class === fee.className) {
        return {
          ...fee,
          discountPercentage: fee.discountPercentage,
          discountType: FeeDiscountType.OPTIPUNK_NFT
        }
      }
    }

    return { discountPercentage: 0, discountType: FeeDiscountType.NOT_RECEIVE_DISCOUNT }
  }

  @Watch('tradeFeeDiscount', { deep: true, immediate: true })
  setMetaDataWhenTradeFeeDiscountChange() {
    if (
      this.tradeFeeDiscount?.discountType === FeeDiscountType.MWAD_NFT &&
      this.topMwadClass.found &&
      this.topMwadClass.hasOwnProperty('tokenId')
    ) {
      if (this.topMwadClass.tokenId !== 0) {
        SwapModule.setMetaData(this.topMwadClass.tokenId)
      } else {
        // NFT Token id 0 should force number to 10000
        SwapModule.setMetaData(10000)
      }
    } else if (
      this.tradeFeeDiscount?.discountType === FeeDiscountType.OPTIPUNK_NFT &&
      this.topOptiPunkClass.found &&
      this.topOptiPunkClass.hasOwnProperty('tokenId')
    ) {
      if (this.topOptiPunkClass.tokenId !== 0) {
        SwapModule.setMetaData(this.topOptiPunkClass.tokenId)
      } else {
        // NFT Token id 0 should force number to 10000
        SwapModule.setMetaData(10000)
      }
    } else {
      SwapModule.setMetaData(0)
    }
  }
}
