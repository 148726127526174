
import { WADTokenFeeDiscountTear } from '@/types/index'

export const wadTokenfeeDiscountTear: WADTokenFeeDiscountTear[] = [
  {
    tear: 1,
    displayMinTokenAmount: '≥ 1,000 WAD',
    minTokenAmount: 1000,
    displayDiscount: '10%',
    discountPercentage: 10,
    feePercentage: '0.09%'
  },
  {
    tear: 2,
    displayMinTokenAmount: '≥ 5,000 WAD',
    minTokenAmount: 5000,
    displayDiscount: '15%',
    discountPercentage: 15,
    feePercentage: '0.085%'
  },
  {
    tear: 3,
    displayMinTokenAmount: '≥ 10,000 WAD',
    minTokenAmount: 10000,
    displayDiscount: '20%',
    discountPercentage: 20,
    feePercentage: '0.08%'
  },
  {
    tear: 4,
    displayMinTokenAmount: '≥ 25,000 WAD',
    minTokenAmount: 25000,
    displayDiscount: '25%',
    discountPercentage: 25,
    feePercentage: '0.075%'
  },
  {
    tear: 5,
    displayMinTokenAmount: '≥ 50,000 WAD',
    minTokenAmount: 50000,
    displayDiscount: '30%',
    discountPercentage: 30,
    feePercentage: '0.07%'
  },
  {
    tear: 6,
    displayMinTokenAmount: '≥ 100,000 WAD',
    minTokenAmount: 100000,
    displayDiscount: '35%',
    discountPercentage: 35,
    feePercentage: '0.065%'
  },
  {
    tear: 7,
    displayMinTokenAmount: '≥ 250,000 WAD',
    minTokenAmount: 250000,
    displayDiscount: '40%',
    discountPercentage: 40,
    feePercentage: '0.06%'
  },
  {
    tear: 8,
    displayMinTokenAmount: '≥ 500,000 WAD',
    minTokenAmount: 500000,
    displayDiscount: '45%',
    discountPercentage: 45,
    feePercentage: '0.055%'
  },
  {
    tear: 9,
    displayMinTokenAmount: '≥ 1,000,000 WAD',
    minTokenAmount: 1000000,
    displayDiscount: '50%',
    discountPercentage: 50,
    feePercentage: '0.05%'
  }
]
