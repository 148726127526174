import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import Web3 from 'web3'
import { AbstractSentryView } from '@/features/Sentry/abstractView'
import { WalletProvider, ChainIDThatSupport, ErrorMessage, GasfeeData, GasSpeed } from '../types'
import { getConnector, saveCurrentNetworkIdInlocalStorage } from '../utils'
import { Web3ConnectorState, web3ConnectorModule } from '../store'
import { Network } from '../types/Web3'
import { Web3Connector } from '../index'
import { NETWORK_CONSTANT } from '@/constants'
import { ethers } from 'ethers'

@Component
export class AbstractWeb3ConnectorView extends Mixins(Vue, AbstractSentryView) {
  @Web3ConnectorState public readonly network!: Network | 'disconnected'
  @Web3ConnectorState public readonly networkId!: number

  @Web3ConnectorState public readonly walletProvider!: WalletProvider | null
  @Web3ConnectorState public readonly web3!: Web3 | null
  @Web3ConnectorState public readonly ethersProvider!: ethers.providers.Web3Provider
  ethBalance: string | null = null
  @Web3ConnectorState public readonly userAddress!: string | null

  // // Wallet Info
  @Web3ConnectorState public readonly walletName!: string | null
  @Web3ConnectorState public readonly walletType!: string | null
  @Web3ConnectorState public readonly walletLogo!: string | null

  @Web3ConnectorState public readonly provider: any | null
  @Web3ConnectorState public readonly isWalletConnected!: boolean

  @Web3ConnectorState public readonly userDefaultWallet!: string
  @Web3ConnectorState public readonly isCorrectNetwork!: boolean
  @Web3ConnectorState public readonly isWrongNetworkNoticeOnModal!: boolean
  @Web3ConnectorState public readonly isWrongNetworkNoticeOnHeader!: boolean
  @Web3ConnectorState public readonly gasFeeData!: GasfeeData
  @Web3ConnectorState public readonly selectedGasSpeed!: GasSpeed

  WalletProvider = WalletProvider

  get isMobile2() {
    return window.innerWidth <= 768
  }

  public async connect(walletProvider: WalletProvider, networkExpect?: string) {
    try {
      if (window?.ethereum === undefined) {
        // If browser don't have ethereum object should force to use wallet connect
        if (walletProvider === WalletProvider.COINBASE_CONNECT) {
          walletProvider = WalletProvider.COINBASE_CONNECT
        } else {
          walletProvider = WalletProvider.WALLET_CONNECT
        }
      }
      const connector = await getConnector(walletProvider)
      // @ts-ignore
      const web3Connector: Web3Connector = this.$web3Connector
      await web3Connector.connecTo(connector)
    } catch (error) {
      if (error.message === ErrorMessage.REFRESH_WEB_PAGE) {
        this.refreshWebPage(networkExpect)
        return
      } else {
        if (error.message === ErrorMessage.WRONG_NETWORK) {
          this.sentryLogError(undefined, error, 'Warning', { networkId: error?.data?.networkId ?? '' })
        } else {
          this.sentryLogError(error?.data?.network, error, 'Critical', { networkId: error?.data?.networkId ?? '' })
        }
      }
      console.error('connect wallet error', error)
    }
    // Manage notic on header of web page
    if (networkExpect && this.isWalletConnected && this.network !== networkExpect) {
      web3ConnectorModule.setIsWrongNetworkNoticeOnHeader(true)
    }
  }

  public async disconnect() {
    // @ts-ignore
    const web3Connector: Web3Connector = this.$web3Connector
    await web3Connector.disconnect()
  }

  public async connectContractForRead() {
    try {
      // @ts-ignore
      const web3Connector: Web3Connector = this.$web3Connector
      await web3Connector.connectContractForRead()
    } catch (error) {
      this.sentryLogError(error?.data?.network, error, 'Critical', { networkId: error?.data?.networkId ?? '' })
      throw error
    }
  }

  public async sendEther(to: string, amount: string) {
    const amountToSend = this.web3!.utils.toWei(amount, 'ether')
    const result = await this.web3!.eth.sendTransaction({
      from: this.userAddress!,
      to: to,
      value: amountToSend
    })
    console.log(result)
  }

  async switchNetwork(networkId: ChainIDThatSupport) {
    const networkIdHexadecimal = `0x${networkId.toString(16)}`
    if (this.networkId === networkId) {
      this.closeWrongNetworkNoticeOnModal()
      return
    }

    // If user don't connect wallet
    if (!this.isWalletConnected) {
      saveCurrentNetworkIdInlocalStorage(networkId)
      this.disconnect()
      return
    }

    // If user don't use metamask should display wrong network notice
    if (
      [WalletProvider.BINANCE_CHAIN, WalletProvider.WALLET_CONNECT].includes(this.walletProvider as WalletProvider) ||
      (this.walletProvider === WalletProvider.INJECTED_WEB3 && !window?.ethereum?.isMetaMask)
    ) {
      if (this.isMobile2) {
        web3ConnectorModule.setIsWrongNetworkNoticeOnModal(true)
      } else {
        web3ConnectorModule.setIsWrongNetworkNoticeOnHeader(true)
      }
      throw Error('user don\'t use metamask')
    }

    // If user use metamask should auto switch network
    try {
      await this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networkIdHexadecimal }]
      })
    } catch (error) {
      if (error.code === 4902 || error.code === -32603) {
        try {
          await this.walletAddChainRpc(networkId)
        } catch (addError) {
          throw addError
        }
      } else if (error.code !== 4001) {
        if (this.isMobile2) {
          web3ConnectorModule.setIsWrongNetworkNoticeOnModal(true)
        } else {
          web3ConnectorModule.setIsWrongNetworkNoticeOnHeader(true)
        }
        throw error
      }
    }
  }

  public walletAddChainRpc(networkId: ChainIDThatSupport) {
    const networkIdHexadecimal = `0x${networkId.toString(16)}`
    let chainName = ''

    switch (networkId) {
      case ChainIDThatSupport.bsc :
        chainName = 'BNB Chain'
        break
      case ChainIDThatSupport.polygon :
        chainName = 'Polygon'
        break
      case ChainIDThatSupport.arbitrum :
        chainName = 'Arbitrum'
        break
      case ChainIDThatSupport.avalanche :
        chainName = 'Avalanche'
        break
      case ChainIDThatSupport.optimism :
        chainName = 'Optimism'
        break
      default:
        throw `Network ${networkId} not support`
    }

    return this.provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: networkIdHexadecimal,
          chainName: chainName,
          rpcUrls: [NETWORK_CONSTANT[networkId].RPC_OFFICIAL_URL],
          blockExplorerUrls: [NETWORK_CONSTANT[networkId].BLOCK_EXPLORER_URL],
          nativeCurrency: {
            name: NETWORK_CONSTANT[networkId].NATIVE_TOKEN.name,
            symbol: NETWORK_CONSTANT[networkId].NATIVE_TOKEN.symbol,
            decimals: NETWORK_CONSTANT[networkId].NATIVE_TOKEN.decimals
          }
        }]
    })
  }

  public closeWrongNetworkNoticeOnHeader() {
    web3ConnectorModule.setIsWrongNetworkNoticeOnHeader(false)
  }

  public closeWrongNetworkNoticeOnModal() {
    web3ConnectorModule.setIsWrongNetworkNoticeOnModal(false)
  }

  public setSelectedGasSpeed(gasSpeed: GasSpeed) {
    web3ConnectorModule.setSelectedGasSpeed(gasSpeed)
  }

  private refreshWebPage(networkExpect?: string) {
    const query = Object.assign({}, this.$route.query)
    if (['disconnected'].includes(this.network)) {
      return
    }
    if (networkExpect) {
      query.network = networkExpect
    } else {
      delete query.network
    }
    this.$router.replace({ query })
    this.$router.go(0)
  }

  public async fetchGasPrice() {
    // Fetch gas price only ethereum network
    if (this.networkId !== ChainIDThatSupport.ethereum) {
      return
    }
    // @ts-ignore
    const web3Connector: Web3Connector = this.$web3Connector
    await web3Connector.setGasPrice(this.networkId, this.ethersProvider)
  }
}
