import { wadTokenfeeDiscountTear, mWadNFTfeeDiscountTear } from './bscFeeDiscount'
import bscTokenBases from './token/bscTokenBases.json'

const data = {
  RPC_OFFICIAL_URL: 'https://bsc-dataseed.binance.org/',
  BLOCK_EXPLORER_URL: 'https://bscscan.com',
  WARDEN_EXCHANGE_STAT_S3_URL: 'https://warden-swap-exchange-stat-bsc.s3.ap-southeast-1.amazonaws.com',
  MWAD_NFT_IMAGE_S3_URL: 'https://image.wardenswap.finance/nft',
  GITHUB_ASSET_IMAGE_URL: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets',
  WARDEN_LEARNING_CONTRACT_ADDRESS: '0x898f503742D999E9ea8b3BE0FF38b86606A99843',
  WARDEN_ROUTING_CONTRACT_ADDRESS: '0x451ef8D6B645a60115EB8b8bEa76B39C0C761004',
  WARDEN_MASTER_CHEF_ADDRESS: '0xde866dD77b6DF6772e320dC92BFF0eDDC626C674',
  MWAD_CONTRACT_ADDRESS: '0xA5FDb0822bf82De3315f1766574547115E99016f',
  TOP_MWAD_CLASS_CONTRACT_ADDRESS: '0x03d9a80968F6D35A6886d95B8111D2888efdf38E',
  DEFAULT_GAS_PRICE: '5000000000', // 5 Gwei
  MIN_NATIVE_TOKEN_SWAP: 0.0005, // 0.0005 BNB
  SWAP_FEE_DISCOUNT: {
    wadTokenfeeDiscountTear,
    mWadNFTfeeDiscountTear
  },
  NATIVE_TOKEN: {
    name: 'BNB Token',
    symbol: 'BNB',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 56,
    decimals: 18
  },
  WRAPPED_TOKEN: {
    name: 'WBNB Token',
    symbol: 'WBNB',
    address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    chainId: 56,
    decimals: 18
  },
  STABLE_COIN_TOKEN: {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0x55d398326f99059ff775485246999027b3197955',
    decimals: 18,
    chainId: 56
  },
  WARDEN_TOKEN: {
    name: 'Warden Token',
    symbol: 'WAD',
    address: '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc',
    chainId: 56,
    decimals: 18
  },
  TOKENS_NOT_SUPPORT_ON_CHART: [
    {
      name: 'BUSD Token',
      symbol: 'BUSD',
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      decimals: 18,
      chainId: 56
    }
  ],
  TOKEN_BASES: bscTokenBases
}
export default data
