import avalancheTokenBases from './token/avalancheTokenBases.json'

const data = {
  RPC_OFFICIAL_URL: 'https://api.avax.network/ext/bc/C/rpc',
  GAS_TRACKER_URL: 'https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle',
  BLOCK_EXPLORER_URL: 'https://snowtrace.io/',
  WARDEN_EXCHANGE_STAT_S3_URL: 'https://warden-swap-exchange-stat-avalanche.s3.ap-southeast-1.amazonaws.com',
  GITHUB_ASSET_IMAGE_URL: 'https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens',
  WARDEN_ROUTING_CONTRACT_ADDRESS: '0x5EF960Eb78B8CFc11e654D03BeEB313BaDF5C7C0',
  DEFAULT_GAS_PRICE: '30000000000', // 30 Gwei
  MIN_NATIVE_TOKEN_SWAP: 0.03,
  NATIVE_TOKEN: {
    name: 'AVAX',
    symbol: 'AVAX',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 43114,
    decimals: 18
  },
  WRAPPED_TOKEN: {
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    chainId: 43114,
    decimals: 18
  },
  STABLE_COIN_TOKEN: {
    name: 'USD Coin',
    symbol: 'USDC.e',
    address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    decimals: 6,
    chainId: 43114
  },
  TOKENS_NOT_SUPPORT_ON_CHART: [
    {
      name: 'USD Coin',
      symbol: 'USDC.e',
      address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      decimals: 6,
      chainId: 43114
    },
    {
      name: 'Tether USD',
      symbol: 'USDT.e',
      address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      decimals: 6,
      chainId: 43114
    }
  ],
  TOKEN_BASES: avalancheTokenBases
}
export default data
