import Web3 from 'web3'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { getWalletInfo } from '../utils'
import { WalletProvider, WalletConnector, ChainIDThatSupport } from '../types'
import { NETWORK_CONSTANT } from '@/constants'
export class WalletConnectConnector implements WalletConnector {
  public web3: Web3 | null = null
  public provider: any = null

  getWalletConnectWeb3Modal() {
    console.log('getWalletConnectWeb3Modal')
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: {
            [ChainIDThatSupport.ethereum]: NETWORK_CONSTANT[ChainIDThatSupport.ethereum].RPC_OFFICIAL_URL, // Ethereum
            [ChainIDThatSupport.bsc]: NETWORK_CONSTANT[ChainIDThatSupport.bsc].RPC_OFFICIAL_URL, // BNB Chain
            [ChainIDThatSupport.polygon]: NETWORK_CONSTANT[ChainIDThatSupport.polygon].RPC_OFFICIAL_URL, // Polygon
            [ChainIDThatSupport.arbitrum]: NETWORK_CONSTANT[ChainIDThatSupport.arbitrum].RPC_OFFICIAL_URL, // Arnbitrum
            [ChainIDThatSupport.avalanche]: NETWORK_CONSTANT[ChainIDThatSupport.avalanche].RPC_OFFICIAL_URL, // Avalanche
            [ChainIDThatSupport.optimism]: NETWORK_CONSTANT[ChainIDThatSupport.optimism].RPC_OFFICIAL_URL // Optimism
          },
          network: 'binance',
          chainId: 56
        }
      }
    }
    console.log('providerOptions', providerOptions)

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: true
    })
    return web3Modal
  }

  async connect() {
    const web3Modal = this.getWalletConnectWeb3Modal()
    this.provider = await web3Modal.connect()
    this.web3 = new Web3(this.provider)
    const accounts = await this.web3.eth.getAccounts()

    return {
      provider: this.provider,
      web3: this.web3,
      accounts,
      walletInfo: getWalletInfo(this.provider, WalletProvider.WALLET_CONNECT)
    }
  }

  async disconnect() {
    // @ts-ignore
    if (this.web3 && this.web3.currentProvider && this.web3.currentProvider.close) {
      console.log('close wallet provider', this.web3.currentProvider)
      // @ts-ignore
      await this.web3.currentProvider.close()
    }
    this.provider = null
    this.web3 = null
  }
}
