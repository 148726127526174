import axios from 'axios'
import Bignumber from '@/utils/bignumber'
import { ethers } from 'ethers'
import { NETWORK_CONSTANT } from '@/constants'
import { ChainIDThatSupport, GasfeeData, GasSpeed, GasFeeDataSourceNo } from '@/features/Web3Connector/types'
import { precisionNumber, wrapWithTryCatch } from '@/utils/helper'
const gasSpeedList = Object.values(GasSpeed)

function getAxiosInstance(baseUrl: string) {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 10000
  })
}

async function getEtherScanGasPrice() {
  const axiosInstance = getAxiosInstance('https://api.etherscan.io/api')
  const { data: response } = await axiosInstance.get('', {
    params: {
      module: 'gastracker',
      action: 'gasoracle',
      apikey: process.env.VUE_APP_ETHERSCAN_API_KEY
    }
  })
  const gaspriceList: {
    gasSpeed: string;
    gasPriceWei: string;
    waitingTime?: string;
    baseFee?: string;
    maxPriorityFeePerGas?: string;
  }[] = [
    {
      gasSpeed: GasSpeed.LOW,
      gasPriceWei: ethers.utils.parseUnits(response.result.SafeGasPrice, 'gwei').toString()
    },
    {
      gasSpeed: GasSpeed.AVERAGE,
      gasPriceWei: ethers.utils.parseUnits(response.result.ProposeGasPrice, 'gwei').toString()
    },
    {
      gasSpeed: GasSpeed.HIGH,
      gasPriceWei: ethers.utils.parseUnits(response.result.FastGasPrice, 'gwei').toString()
    }
  ]

  // TODO: Close feature because system not used maxPriorityFeePerGas and BaseFee
  // const suggestBaseFee = ethers.utils.parseUnits(response.result.suggestBaseFee, 'gwei').toString()
  // gaspriceList = await Promise.all(gaspriceList.map(async(data) => {
  //   const { data: estimationOfConfirmationTime } = await etherscanApi.get('', {
  //     params: {
  //       module: 'gastracker',
  //       action: 'gasestimate',
  //       apikey: process.env.VUE_APP_ETHERSCAN_API_KEY,
  //       gasprice: data.gasPriceWei
  //     }
  //   })
  //   data.waitingTime = estimationOfConfirmationTime.result
  //   data.baseFee = suggestBaseFee as string

  //   let maxPriorityFeePerGas = Bignumber(data.gasPriceWei).minus(suggestBaseFee).toString()
  //   if (Bignumber(maxPriorityFeePerGas).lt(1000000000)) {
  //     maxPriorityFeePerGas = '1000000000'
  //   }
  //   data.maxPriorityFeePerGas = maxPriorityFeePerGas
  //   return data
  // }))

  const gasFeeData: GasfeeData = {} as GasfeeData
  gasFeeData.dataSourceNo = GasFeeDataSourceNo.FIRST

  gasSpeedList.forEach(type => {
    const found = gaspriceList.find(data => data.gasSpeed === type)
    if (found) {
      gasFeeData[type] = {
        gasPriceWei: found.gasPriceWei
      }
    }
  })

  return gasFeeData
}

export async function getGasFeeDataFromApi(networkId: ChainIDThatSupport): Promise<GasfeeData> {
  const data = { jsonrpc: '2.0', id: 1, method: 'eth_gasPrice', params: [] }

  switch (networkId) {
    case ChainIDThatSupport.bsc : {
      const axiosInstance = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.bsc].RPC_OFFICIAL_URL)
      const response = await axiosInstance.post('', data) as any
      if (!response?.data?.result) {
        throw new Error('Get gas fee data wrong')
      }
      const gasPrice: string = Bignumber(response.data.result).toString()
      if (!gasPrice) {
        throw new Error('Get gas fee data wrong')
      }

      const gasFeeData: GasfeeData = {} as GasfeeData
      gasSpeedList.forEach(type => {
        gasFeeData[type] = {
          gasPriceWei: gasPrice
        }
      })

      return gasFeeData
    }
    case ChainIDThatSupport.polygon : {
      const axiosInstancePolygonApi = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.polygon].RPC_OFFICIAL_URL)
      const axiosInstancePolygonGasTrackerApi = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.polygon].GAS_TRACKER_URL)
      const [responseFromRPC, responseFromGasTracker] = await Promise.all([
        wrapWithTryCatch(() => axiosInstancePolygonApi.post('', data)),
        wrapWithTryCatch(() => axiosInstancePolygonGasTrackerApi.get(''))
      ])
      let gasPrice: any
      if (responseFromRPC?.data?.result) {
        gasPrice = Bignumber(responseFromRPC.data.result)
      }

      if (responseFromGasTracker?.data?.result?.FastGasPrice) {
        const gasPriceFromGastracker = Bignumber(responseFromGasTracker.data.result.FastGasPrice).times((Bignumber('10').pow(9)))
        if (gasPrice) {
          gasPrice = gasPrice.gt(gasPriceFromGastracker) ? gasPrice : gasPriceFromGastracker
        } else {
          gasPrice = gasPriceFromGastracker
        }
      }

      if (!gasPrice) {
        throw new Error('Get gas fee data wrong')
      }

      // hard code the minimum gas to be 30 gWei
      const minimumGas = Bignumber('10').pow(9).multipliedBy(30)
      gasPrice = gasPrice.gte(minimumGas) ? gasPrice : minimumGas

      // gas price +10%
      gasPrice = precisionNumber(gasPrice.times(1.1), 3).toFixed(0)

      const gasFeeData: GasfeeData = {} as GasfeeData
      gasFeeData.dataSourceNo = GasFeeDataSourceNo.FIRST

      gasSpeedList.forEach(type => {
        gasFeeData[type] = {
          gasPriceWei: gasPrice
        }
      })

      return gasFeeData
    }
    case ChainIDThatSupport.arbitrum : {
      const axiosInstance = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.arbitrum].RPC_OFFICIAL_URL)
      const response = await axiosInstance.post('', data) as any
      if (!response?.data?.result) {
        throw new Error('Get gas fee data wrong')
      }
      const gasPrice = Bignumber(response.data.result).toString()
      if (!gasPrice) {
        throw new Error('Get gas fee data wrong')
      }

      const gasFeeData: GasfeeData = {} as GasfeeData
      gasFeeData.dataSourceNo = GasFeeDataSourceNo.FIRST

      gasSpeedList.forEach(type => {
        gasFeeData[type] = {
          gasPriceWei: gasPrice
        }
      })

      return gasFeeData
    }
    case ChainIDThatSupport.ethereum : {
      const etherScanGasPrice = await getEtherScanGasPrice()
      return etherScanGasPrice
    }
    case ChainIDThatSupport.avalanche : {
      const axiosInstanceAvalancheApi = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.avalanche].RPC_OFFICIAL_URL)
      const axiosInstanceAvalancheGasTrackerApi = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.avalanche].GAS_TRACKER_URL)
      const [responseFromRPC, responseFromGasTracker] = await Promise.all([
        wrapWithTryCatch(() => axiosInstanceAvalancheApi.post('', data)),
        wrapWithTryCatch(() => axiosInstanceAvalancheGasTrackerApi.get(''))
      ])
      let gasPrice: any
      if (responseFromRPC?.data?.result) {
        gasPrice = Bignumber(responseFromRPC.data.result)
      }

      if (responseFromGasTracker?.data?.result?.FastGasPrice) {
        const gasPriceFromGastracker = Bignumber(responseFromGasTracker.data.result.FastGasPrice).times((Bignumber('10').pow(9)))
        if (gasPrice) {
          gasPrice = gasPrice.gt(gasPriceFromGastracker) ? gasPrice : gasPriceFromGastracker
        } else {
          gasPrice = gasPriceFromGastracker
        }
      }

      if (!gasPrice) {
        throw new Error('Get gas fee data wrong')
      }

      // hard code the minimum gas to be 30 gWei
      const minimumGas = Bignumber('10').pow(9).multipliedBy(30)
      gasPrice = gasPrice.gte(minimumGas) ? gasPrice : minimumGas

      // gas price +10%
      gasPrice = precisionNumber(gasPrice.times(1.1), 3).toFixed(0)

      const gasFeeData: GasfeeData = {} as GasfeeData
      gasFeeData.dataSourceNo = GasFeeDataSourceNo.FIRST

      gasSpeedList.forEach(type => {
        gasFeeData[type] = {
          gasPriceWei: gasPrice
        }
      })

      return gasFeeData
      // const response = await avalancheApi.post('', data) as any
      // if (!response?.data?.result) {
      //   throw new Error('Get gas fee data wrong')
      // }
      // const gasPrice: string = Bignumber(response.data.result).toString()
      // if (!gasPrice) {
      //   throw new Error('Get gas fee data wrong')
      // }

      // const gasFeeData: GasfeeData = {} as GasfeeData
      // gasSpeedList.forEach(type => {
      //   gasFeeData[type] = {
      //     gasPriceWei: gasPrice
      //   }
      // })

      // return gasFeeData
    }
    case ChainIDThatSupport.optimism : {
      const axiosInstance = getAxiosInstance(NETWORK_CONSTANT[ChainIDThatSupport.optimism].RPC_OFFICIAL_URL)
      const response = await axiosInstance.post('', data) as any
      if (!response?.data?.result) {
        throw new Error('Get gas fee data wrong')
      }
      const gasPrice: string = Bignumber(response.data.result).toString()
      if (!gasPrice) {
        throw new Error('Get gas fee data wrong')
      }

      const gasFeeData: GasfeeData = {} as GasfeeData
      gasFeeData.dataSourceNo = GasFeeDataSourceNo.FIRST

      gasSpeedList.forEach(type => {
        gasFeeData[type] = {
          gasPriceWei: gasPrice
        }
      })

      return gasFeeData
    }
    default :
      throw new Error(`Get gas fee not support for network ${networkId}`)
  }
}

export function getTotalVolumeByNetworkId(networkId: ChainIDThatSupport) {
  // @ts-ignore
  if (!NETWORK_CONSTANT[networkId]?.WARDEN_EXCHANGE_STAT_S3_URL) {
    throw new Error('WARDEN_EXCHANGE_STAT_S3_URL not exist')
  }
  // @ts-ignore
  const s3Url = NETWORK_CONSTANT[networkId]?.WARDEN_EXCHANGE_STAT_S3_URL
  const axiosInstance = getAxiosInstance(s3Url)
  return axiosInstance.get('/data.json')
}

export function getTokenImageFromUrl(imaageUrl: string): Promise<any> {
  return axios.get(imaageUrl, { timeout: 3000 })
}

export async function getMetaWardenDataFromTokenURI(uri: string) {
  const axiosInstance = getAxiosInstance(uri)
  const { data: metaData } = await axiosInstance.get('')

  return metaData?.image
}
