import { ChainIDThatSupport } from './Web3'
export * from './Wallet'
export * from './Connector'
export { Network, ChainIDThatSupport } from './Web3'
export interface GroupOfWardenAbi {
  wardenRoutingAbi: any;
}

export enum GasSpeed {
  LOW = 'low',
  AVERAGE = 'average',
  HIGH = 'high'
}

interface FeeData {
  gasPriceWei: string;
  baseFee?: string;
  waitingTime?: string;
  maxPriorityFeePerGas?: string;
}

export enum GasFeeDataSourceNo {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  CONSTANT_VALUE = 'constantValue'
}
export interface GasfeeData {
  [GasSpeed.LOW]: FeeData;
  [GasSpeed.AVERAGE]: FeeData;
  [GasSpeed.HIGH]: FeeData;
  dataSourceNo: GasFeeDataSourceNo;
}

export enum ErrorMessage {
  REFRESH_WEB_PAGE = 'Should refresh web page',
  RPC_NODE_TIMEOUT = 'RPC node timeout',
  WRONG_NETWORK = 'Wrong network'
}

export type ChainIdSupportWadToken = ChainIDThatSupport.bsc | ChainIDThatSupport.ethereum | ChainIDThatSupport.arbitrum | ChainIDThatSupport.optimism
