import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { ethers, Contract } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { ErrorHTMLImageElement } from '@/types'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import { MwadNftModule, MwadNftState } from '../store'
import { TopMwadClass, MWADClass } from '../types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import TopMwadClassAbi from '@/constants/abis/bsc/TopMwadClassAbi.json'
import { NETWORK_CONSTANT, CLOUDFLARE_RESIZE_IMAGE_URL, MWAD_NFT_IMAGE_S3_URL } from '@/constants/index'
import MetaWardenAbi from '@/constants/abis/bsc/MetaWardenAbi.json'
import { getMetaWardenDataFromTokenURI } from '@/resources/thirdPartyApi'
import { AbstractAmplitudeView } from '@/features/Amplitude/abstractView'
@Component
export class AbstractMwadNftView extends Mixins(Vue, AbstractAmplitudeView) {
  @Web3ConnectorState public readonly ethersProvider!: ethers.providers.Web3Provider
  @Web3ConnectorState public readonly userAddress!: string
  @Web3ConnectorState public readonly networkId!: number

  @MwadNftState public readonly topMwadClass!: TopMwadClass
  @MwadNftState public readonly metaWardenContract!: Contract

  async initMetawardenContract(newEthersProvider: any) {
    if (!newEthersProvider) {
      return
    }
    const { chainId } = await this.ethersProvider.getNetwork()
    if (chainId !== ChainIDThatSupport.bsc) {
      MwadNftModule.setMetaWardenContract(null)
      return
    }

    const mWadContract = new Contract(
      NETWORK_CONSTANT[ChainIDThatSupport.bsc].MWAD_CONTRACT_ADDRESS,
      MetaWardenAbi,
      this.ethersProvider
    )
    await MwadNftModule.setMetaWardenContract(mWadContract)
  }

  get isFoundMwadNft() {
    if (
        this.topMwadClass?.found &&
        getAddress(this.topMwadClass?.userAddress) === getAddress(this.userAddress)
    ) {
      return true
    }

    return false
  }

  async getTopMwadNFT() {
    if (!this.ethersProvider) {
      MwadNftModule.setTopMwadClass({})
      return
    }
    const { chainId } = await this.ethersProvider.getNetwork()
    if (chainId !== ChainIDThatSupport.bsc) {
      MwadNftModule.setTopMwadClass({})
      return
    }
    if (!this.userAddress) {
      MwadNftModule.setTopMwadClass({})
      return
    }
    const topMwadClassContract = new ethers.Contract(
      NETWORK_CONSTANT[ChainIDThatSupport.bsc].TOP_MWAD_CLASS_CONTRACT_ADDRESS,
      TopMwadClassAbi,
      this.ethersProvider
    )
    const result = await topMwadClassContract.getTopOne(this.userAddress)
    const classNumber = parseInt(result._class)
    const foundNFT = result._found

    let mWadClassName: MWADClass = MWADClass.NO_CLASS
    if (foundNFT) {
      if (classNumber === 0) {
        mWadClassName = MWADClass.GOLDEN
      } else if (classNumber === 1) {
        mWadClassName = MWADClass.SUPER
      } else if (classNumber === 2) {
        mWadClassName = MWADClass.COMMON
      }
      this.amplitudeSetNftUserProperties(parseInt(result._tokenId), mWadClassName)
    } else {
      this.amplitudeUnsetNftUserProperties()
    }

    MwadNftModule.setTopMwadClass({
      class: mWadClassName,
      found: result._found,
      tokenId: parseInt(result._tokenId),
      userAddress: this.userAddress
    })
  }

  public async getNFTImageURIFromTokenId(tokenId: number) {
    if (!this.metaWardenContract) {
      return
    }
    const tokenURI = await this.metaWardenContract.tokenURI(tokenId)
    const imageURI = await getMetaWardenDataFromTokenURI(tokenURI)

    return imageURI
  }

  async handleMwadNFTImageError(e: ErrorHTMLImageElement, tokenId: number) {
    const imageURI = await this.getNFTImageURIFromTokenId(tokenId)
    e.target.src = `${CLOUDFLARE_RESIZE_IMAGE_URL}/${imageURI}`
  }

  getNFTImage(tokenId: number) {
    return `${MWAD_NFT_IMAGE_S3_URL}/${tokenId}.png`
  }
}
