import { getAddress } from '@ethersproject/address'
import bignumber from '@/utils/bignumber'
import { ethers } from 'ethers'
import { APP_NAME, APP_VERSION, NETWORK_CONSTANT, DEFAULT_PRICE_SLIPPAGE } from '@/constants'
import { Token, OldUserLocalStorage, NewUserLocalStorage, SystemLocalStorage, ErrorHTMLImageElement } from '@/types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import THEME from '@/constants/theme'
import * as _ from '@/utils/lodash-extended'

const currentTimestamp = () => new Date().getTime()

export function shortenHex(value: string, format = 5): string {
  if (!value) return ''
  const first = value.substr(0, format)
  const last = value.substr(value.length - format, value.length)
  return `${first}...${last}`
}

export function isAddress(search: string): string | false {
  try {
    return getAddress(search)
  } catch {
    return false
  }
}

export function filterTokens(tokens: Token[], search: string): Token[] {
  if (search.length === 0) return tokens

  const searchingAddress = isAddress(search)

  if (searchingAddress) {
    return tokens.filter((token) => token.address.toUpperCase() === searchingAddress.toUpperCase())
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return tokens
  }

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((str) => str.length > 0)

    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return tokens.filter((token) => {
    const { symbol, name } = token

    return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name))
  })
}

function balanceComparator(balanceA?: string, balanceB?: string) {
  if (balanceA && balanceB) {
    return bignumber(balanceA).gt(bignumber(balanceB)) ? -1 : bignumber(balanceA).eq(bignumber(balanceB)) ? 0 : 1
  }
  if (balanceA && bignumber(balanceA).gt('0')) {
    return -1
  }
  if (balanceB && bignumber(balanceB).gt('0')) {
    return 1
  }
  return 0
}

export function getTokenComparator(
  balances: { [tokenAddress: string]: string },
  tokenA: Token,
  tokenB: Token
): number {
  // sort by balances
  const balanceA = balances[tokenA.address]
  const balanceB = balances[tokenB.address]
  const balanceComp = balanceComparator(balanceA, balanceB)
  if (balanceComp !== 0) return balanceComp

  if (tokenA.symbol && tokenB.symbol) {
    // sort by symbol
    return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
  }
  return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0
}

export function precisionNumber(value: string | number, precision = 6) {
  return bignumber(value).precision(precision)
}

export function toFixedDecimal(value: any, format = 2) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = bignumber(value)
  }
  if (!value || value.isZero()) return 0
  return value.toFormat(format)
}

export function toFixed(value: any, format = 2) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = bignumber(value)
  }
  if (!value || value.isZero()) return 0
  return value.toFormat(format, 1) // ROUND_DOWN
}

export function getTokenImage(symbol: string | null, address: string | null, networkId: ChainIDThatSupport, allToken: Token[]) {
  try {
    if (symbol) {
      return require(`@/assets/img/coins/${symbol.toLowerCase()}.png`)
    }
  } catch (error) {
    if (address) {
      const tokenData = allToken.find((token: Token) => token.address === address) as Token
      if (tokenData?.logoURI) {
        return tokenData.logoURI
      }
      const githubAssetImageUrl = NETWORK_CONSTANT[networkId].GITHUB_ASSET_IMAGE_URL
      return `${githubAssetImageUrl}/${getAddress(address)}/logo.png`
    }
  }
}

export function handleTokenImageError(e: ErrorHTMLImageElement): void {
  e.target.src = require('@/assets/svg/icon-unknow-token.svg')
}

export const numberWithCommas = (number: number | string, format = { groupSeparator: ',', groupSize: 3, decimalSeparator: '.' }) => {
  return bignumber(number).toFormat(format).toString()
}

// add 25%
export function calculateGasMargin(value: string | number): string {
  return bignumber(value).multipliedBy('1.25').toFixed(0)
}

function isNewUserLocalStorage(localStorage: any) {
  const valueChainIDThatSupport = Object.values(ChainIDThatSupport).map(v => v.toString())
  if (localStorage?.tokens && _.intersection(Reflect.ownKeys(localStorage.tokens), valueChainIDThatSupport).length) {
    return true
  }
  return false
}

function tranformOldToNewUserStorage(localStorage: OldUserLocalStorage): NewUserLocalStorage {
  const newLocalStorage = {
    ...localStorage,
    tokens: {
      [ChainIDThatSupport.bsc]: { ...localStorage.tokens }
    }
  }
  return newLocalStorage
}

export function loadUserStorage(): NewUserLocalStorage {
  const localStorageName = `${APP_NAME}.user`
  let newLocalStorageData = {} as any
  const tempLocalStorage = localStorage.getItem(localStorageName)
  if (tempLocalStorage) {
    const tempData = JSON.parse(tempLocalStorage)
    if (isNewUserLocalStorage(tempData)) {
      newLocalStorageData = { ...tempData }
    } else {
      newLocalStorageData = tranformOldToNewUserStorage(tempData)
    }
  }
  if (!newLocalStorageData.hasOwnProperty('createdTimestamp')) {
    const current = currentTimestamp()
    newLocalStorageData.createdTimestamp = current
    newLocalStorageData.lastUpdateTimestamp = current
  }
  if (!newLocalStorageData.hasOwnProperty('tokens')) {
    newLocalStorageData.tokens = {}
  }
  if (!newLocalStorageData.hasOwnProperty('priceSlippagePercentage')) {
    newLocalStorageData.priceSlippagePercentage = DEFAULT_PRICE_SLIPPAGE
  }
  return newLocalStorageData
}

function saveLocalStorage(localStorageData: NewUserLocalStorage | SystemLocalStorage, localStorageName: string) {
  localStorageData.lastUpdateTimestamp = currentTimestamp()
  localStorage.setItem(localStorageName, JSON.stringify(localStorageData))
}

export function addTokenInlocalStorage(tokenData: Token, networkId: ChainIDThatSupport) {
  const tempData = loadUserStorage()
  if (!tempData.tokens.hasOwnProperty(networkId)) {
    tempData.tokens[networkId] = { [tokenData.address]: tokenData }
  } else {
    tempData.tokens[networkId][tokenData.address] = tokenData
  }
  saveLocalStorage(tempData, `${APP_NAME}.user`)
}

export function removeTokenInlocalStorage(tokenAddress: string, networkId: ChainIDThatSupport) {
  const tempData = loadUserStorage()
  delete tempData.tokens[networkId][tokenAddress]
  saveLocalStorage(tempData, `${APP_NAME}.user`)
}

export function setPriceSlippageInlocalStorage(percentage: number | string) {
  const tempData = loadUserStorage()
  tempData.priceSlippagePercentage = percentage.toString()
  saveLocalStorage(tempData, `${APP_NAME}.user`)
}

export function clearAllCookies() {
  console.log('Clear all cookies')
  document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/') })
}

export function handleVersionApp() {
  const localStorageName = `${APP_NAME}.system`
  let newLocalStorageData = {} as SystemLocalStorage
  const tempLocalStorage = localStorage.getItem(localStorageName)
  if (tempLocalStorage) {
    const tempData = JSON.parse(tempLocalStorage)
    newLocalStorageData = { ...tempData }
  }
  const current = currentTimestamp()
  if (!newLocalStorageData.hasOwnProperty('createdTimestamp') || !newLocalStorageData.hasOwnProperty('version')) {
    newLocalStorageData.createdTimestamp = current
    newLocalStorageData.lastUpdateTimestamp = current
    newLocalStorageData.version = APP_VERSION
    saveLocalStorage(newLocalStorageData, `${APP_NAME}.system`)
    clearAllCookies()
  } else if (newLocalStorageData.version !== APP_VERSION) {
    newLocalStorageData.version = APP_VERSION
    newLocalStorageData.lastUpdateTimestamp = current
    saveLocalStorage(newLocalStorageData, `${APP_NAME}.system`)
    clearAllCookies()
  }
}

export function checkIsWrapOrUnwrapNativeToken(tokenXAddress: string, tokenYAddress: string, networkId: number): boolean {
  // TokenX and TokenY is native token and wrapped token
  const wrappedToken = NETWORK_CONSTANT[networkId as ChainIDThatSupport].WRAPPED_TOKEN as Token
  const nativeToken = NETWORK_CONSTANT[networkId as ChainIDThatSupport].NATIVE_TOKEN as Token
  const wrappedTokenChecksumAddress = getAddress(wrappedToken.address)
  const nativeTokenChecksumAddress = getAddress(nativeToken.address)
  const tokenXChecksumAddress = getAddress(tokenXAddress)
  const tokenYChecksumAddress = getAddress(tokenYAddress)
  return _.isEmpty(_.xor([wrappedTokenChecksumAddress, nativeTokenChecksumAddress], [tokenXChecksumAddress, tokenYChecksumAddress]))
}

export function getMute() {
  const tempData = loadUserStorage()
  return tempData.mute || false
}

export function toggleMute() {
  const tempData = loadUserStorage()
  const mute = getMute()
  tempData.mute = !mute || !getMute()

  saveLocalStorage(tempData, `${APP_NAME}.user`)
}

export function getTheme() {
  const tempData = loadUserStorage()
  return tempData.theme || THEME.LIGHT_MODE
}

export function toggleTheme() {
  const tempData = loadUserStorage()
  const theme = getTheme()
  tempData.theme = !theme || theme === THEME.LIGHT_MODE ? THEME.SPACE_MODE : THEME.LIGHT_MODE

  saveLocalStorage(tempData, `${APP_NAME}.user`)
}

export function isOnlyNumber(text: string) {
  return /^\d+$/.test(text)
}
export async function wrapWithTryCatch(func: Function) {
  try {
    return (await func()) as any
  } catch (e) {
    console.log(e)
  }
}

export function paddingZeroNumber(text: string | number, targetLength = 4) {
  return text.toString().padStart(targetLength, '0')
}

export function generateEtheruemMulticallOptions(
  networkId: ChainIDThatSupport,
  ethersProvider: ethers.providers.Web3Provider,
  tryAggregate = false
) {
  const options: any = { ethersProvider }
  if (networkId === ChainIDThatSupport.optimism) {
    options.multicallCustomContractAddress = '0x2DC0E2aa608532Da689e89e237dF582B783E552C'
  }

  if (tryAggregate) {
    options.tryAggregate = true
  }

  return options
}

export function extractError(error: any) {
  if (error instanceof AggregateError) {
    return error.errors
  }

  if (typeof error === 'string' || error instanceof Error) {
    return error
  }

  if (error.error) {
    return error.error
  }

  return error
}

export function extractAggregateErrorsMessage(error: AggregateError) {
  const errorsMessage = error.errors.map((err) => {
    if (err instanceof Error) {
      return err.message
    }
    return err
  })

  return errorsMessage
}

export function findErrorMessage(error: any) {
  return error?.data?.message || error.message
}
