export enum WalletProvider {
  INJECTED_WEB3 = 'INJECTED_WEB3',
  WALLET_CONNECT = 'WALLET_CONNECT',
  BINANCE_CHAIN = 'BINANCE_CHAIN',
  COINBASE_CONNECT = 'COINBASE_CONNECT'
}

export interface WalletInfo {
  provider: WalletProvider;
  name: string;
  type: string;
  logo: string;
}
