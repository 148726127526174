




























































































































/*
# WhiteLabelSwap

This page is for partners to embed the wardenswap panel into
their websites using simple iframe method. The white label page
can be customized using URL query params.

@param hideLayout: boolean - if this param is set, all layout
       will be hidden. usually needed for embedded swap box.
@param network: string - default network
@param from: address - default from token address
@param to: address - default to token address
@param t: number - use 0 for light mode, use 1 for space mode

## Sample
    <iframe
      src="https://www.wardenswap.finance/#/white-label-swap?hideLayout&from=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&to=0xa29b6f4e762874846c081e20ed1142ff83faafef"
      frameborder="0" width="640" height="800"></iframe>
*/
import { Component, Ref, Watch, Mixins } from 'vue-property-decorator'
import { getAddress } from '@ethersproject/address'
import TokenInput from '@/components/TokenInput.vue'
import PercentageSelector from '@/components/PercentageSelector.vue'
import BestRateRoutingBox from '@/components/BestRateRoutingBox.vue'
import ConnectWalletModal from '@/components/ConnectWalletModal.vue'
import ConfirmSwapModal from '@/components/ConfirmSwapModal.vue'
import WaitingConfirmSwapModal from '@/components/WaitingConfirmSwapModal.vue'
import TokenListModal from '@/components/TokenListModal.vue'
import TotalTradingVolumebox from '@/components/TotalTradingVolumebox.vue'
import BannerCarousel from '@/components/BannerCarousel.vue'
import TokenImportModal from '@/components/TokenImportModal.vue'
import BridgeToArbitrum from '@/components/BridgeToArbitrum.vue'
import TradingFeeDiscount from '@/components/TradingFeeDiscount.vue'
import BuyWardenTokenCard from '@/components/BuyWardenTokenCard.vue'
import { TransactionResponse } from '@ethersproject/providers'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { AbstractOptiPunkNftView } from '@/features/OptiPunkNft/abstractView'
import { AbstractMwadNftView } from '@/features/MwadNft/abstractView'
import { ErrorMessage as ErrorMessageSwap } from '@/features/Swap/types'
import { WARDEN_TITLE_WAB_PAGE, NETWORK_CONSTANT } from '@/constants'
import bignumber from '@/utils/bignumber'
import { isAddress, handleVersionApp, isOnlyNumber, findErrorMessage } from '@/utils/helper'
import {
  BestRateButtonStatus,
  TokenInput as TokenInputInterface,
  TokenInputType,
  ApprovalState,
  BestRateQueryState,
  BestRateQuerySide,
  Token,
  TradeState,
  WardenWhiteLabelOptions,
  FeeDiscountType
} from '@/types'
import { Network, ChainIDThatSupport, ChainIdSupportWadToken } from '@/features/Web3Connector/types'

import THEME from '@/constants/theme'
import { mapGetters } from 'vuex'
enum DisplayBtnStatus {
  CONNECT_WALLET = 'Connect Wallet',
  ENTER_TOKEN_AMOUNT = 'Enter Token Amount',
  BALANCE_IS_NOT_ENOUGH = 'Your balance is not enough',
  SELECT_A_TOKEN = 'Select a token',
  UNLOCK_TOKEN = 'Unlock Token',
  UNLOCK_TOKEN_LOADING = 'Unlock Token',
  BEST_RATE_QUERY_LOADING = 'Waiting ...',
  SWAP = 'Swap',
  WRONG_NETWORK = 'Wrong Network',
  UNVALUABLE = 'Unvaluable',
  FAIL = 'Find best rate failed please try again'
}

@Component({
  name: 'WhiteLabelSwap',
  components: {
    TokenInput,
    PercentageSelector,
    BestRateRoutingBox,
    ConnectWalletModal,
    ConfirmSwapModal,
    WaitingConfirmSwapModal,
    TotalTradingVolumebox,
    BannerCarousel,
    TokenImportModal,
    TokenListModal,
    BridgeToArbitrum,
    BuyWardenTokenCard,
    TradingFeeDiscount
  },
  computed: mapGetters(['theme'])
})
export default class WhiteLabelSwap extends Mixins(AbstractSwapView, AbstractWeb3ConnectorView, AbstractMwadNftView, AbstractOptiPunkNftView) {
  theme?: string

  @Ref() readonly ConnectWalletModal!: ConnectWalletModal
  @Ref() readonly ConfirmSwapModal!: ConfirmSwapModal
  @Ref() readonly WaitingConfirmSwapModal!: WaitingConfirmSwapModal
  @Ref() readonly PercentageSelector!: PercentageSelector
  @Ref() readonly TokenImportModalForTokenA!: TokenImportModal
  @Ref() readonly TokenImportModalForTokenB!: TokenImportModal
  @Ref() readonly TokenListModal!: TokenListModal

  displayBalanceTokenA = '0'
  displayBalanceTokenB = '0'
  DisplayBtnStatus = DisplayBtnStatus
  displayTextOnbutton = DisplayBtnStatus.CONNECT_WALLET
  errorMessageModal = ''
  percentageOfTokenBalance = ''
  chainIDThatSupport = ChainIDThatSupport
  tradeFeeDiscountType = FeeDiscountType

  created() {
    handleVersionApp()
    this.handleSetReferralId()
    this.fetchDataEveryTime()
    this.handleSetWhiteLabelOptionsEventListener()
    this.$store.dispatch('setMute', true) // Mute by default for white label
  }

  async watchBestRate() {
    if (this.lastBestRateQuerySide === BestRateQuerySide.FROM_A_TO_B) {
      this.watchBestRateTokenAToTokenB()
    } else if (this.lastBestRateQuerySide === BestRateQuerySide.FROM_B_TO_A) {
      // TODO: close feature
      // this.watchBestRateTokenBToTokenA()
    }
  }

  // Set Partner ID to do a profit sharing
  async handleSetReferralId() {
    const query = this.$route.query
    if (query.hasOwnProperty('ref')) {
      const refString = query.ref.toString()
      if (isOnlyNumber(refString)) {
        this.setReferralId(refString)
      }
    }
  }

  async handleDefaultToken() {
    // call function when best rate query is exist
    const query = this.$route.query
    if (query.hasOwnProperty('from') && isAddress(query.from as string)) {
      this.handleTokenInput('tokenA', query.from as string)
    } else {
      this.handleTokenInput('tokenA', NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN.address)
    }
    if (query.hasOwnProperty('to') && isAddress(query.to as string)) {
      this.handleTokenInput('tokenB', query.to as string)
    }
  }

  // Add event listener to receive style option setting
  // from iframe.postMessage(...)
  //
  // ex. setting background as red
  // iframe.postMessage({ type: "WardenWhiteLabelOptions", value: { style: { '--color-background': 'red' } } }, "*")
  handleSetWhiteLabelOptionsEventListener() {
    const body = document.getElementsByTagName('body')[0]
    window.addEventListener(
      'message',
      (event: any) => {
        if (event.data?.type && event.data.type === 'WardenWhiteLabelOptions') {
          const options: WardenWhiteLabelOptions = event.data.value
          if (options?.style && typeof options.style === 'object') {
            for (const cssProp in options.style) {
              body.style.setProperty(cssProp, options.style[cssProp])
            }
          }
        }
      },
      false
    )
  }

  handleTokenInput(type: TokenInputType, tokenAddress: string) {
    const tokenData = this.allToken.find(
      (token: Token) => getAddress(token.address) === getAddress(tokenAddress)
    ) as Token
    if (!tokenData) {
      switch (type) {
        case 'tokenA':
          this.TokenImportModalForTokenA.showModalWithTokenAddress(type, tokenAddress)
          break
        case 'tokenB':
          this.TokenImportModalForTokenB.showModalWithTokenAddress(type, tokenAddress)
          break
      }
    } else {
      const data: TokenInputInterface = { address: tokenData.address, symbol: tokenData.symbol }
      this.setTokenInput(type, data)
      this.getTokensBalance([tokenData])
    }
  }

  handleSelectedToken(val: { tokenType: TokenInputType; token: Token }) {
    const data: TokenInputInterface = { address: val.token.address, symbol: val.token.symbol }
    this.setTokenInput(val.tokenType, data)
  }

  async fetchWADTokenPrice() {
    const wardenTokenData = NETWORK_CONSTANT[this.networkId as ChainIdSupportWadToken]?.WARDEN_TOKEN
    if (!wardenTokenData) {
      return
    }
    await this.findWADPrice()
    const wardenPrice = this.tokenPrices[wardenTokenData?.address]
    if (!bignumber(wardenPrice).isZero() && !bignumber(wardenPrice).isNaN()) {
      // Update warden price at title of web page
      document.title = `${WARDEN_TITLE_WAB_PAGE} - $${bignumber(wardenPrice).toFixed(3)}`
    }
  }

  fetchTokenInputBalance() {
    const listOfTokenData = [] as Token[]
    if (this.tokenAInput.hasOwnProperty('address')) {
      const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
      if (Object.keys(tokenAData).length) {
        listOfTokenData.push(tokenAData)
      }
    }
    if (this.tokenBInput.hasOwnProperty('address')) {
      const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
      if (Object.keys(tokenBData).length) {
        listOfTokenData.push(tokenBData)
      }
    }
    if (listOfTokenData.length) {
      this.getTokensBalance(listOfTokenData)
    }
  }

  fetchDataEveryTime() {
    this.fetchWADTokenPrice()
    setInterval(() => {
      this.fetchWADTokenPrice()
      this.watchBestRate()
      this.fetchTokenPrice()
      this.fetchTokenInputBalance()
      this.fetchGasPrice()
    }, 1000 * 10) // 10s

    setInterval(() => {
      this.fetchApprovalTokenTransactions()
    }, 1000 * 3) // 3s
  }

  getTokenAmountCanBeSwap(tokenAmount: string, tokenBalance: string): string {
    const nativeToken = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN as Token
    if (this.tokenAInput.address === nativeToken.address) {
      const minNativeTokenSwap = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].MIN_NATIVE_TOKEN_SWAP
      if (bignumber(tokenAmount).isEqualTo(tokenBalance) && bignumber(tokenAmount).isGreaterThan(minNativeTokenSwap)) {
        return bignumber(tokenAmount).minus(minNativeTokenSwap).toString(10)
      } else if (bignumber(bignumber(tokenAmount).plus(minNativeTokenSwap)).isGreaterThan(tokenBalance)) {
        return '0'
      }
    }
    return tokenAmount
  }

  async handleEventSubmitBtn() {
    switch (this.submitBtnStatus) {
      case 'CONNECT_WALLET':
        this.ConnectWalletModal.showModal()
        break
      case 'UNLOCK_TOKEN': {
        try {
          await this.approveToken()
          this.amplitudeLogEvent('Click button approve token in best rate swap box', { tokenAddress: this.tokenAInput.address })
        } catch (error) {
          this.$toast.add({ severity: 'error', summary: 'Error Message', detail: error.message, life: 5000 })
        }
        break
      }
      case 'SWAP':
        this.ConfirmSwapModal.showModal()
        break
    }
  }

  async handleConfirmSwap() {
    try {
      this.errorMessageModal = ''
      this.WaitingConfirmSwapModal.showModal()
      const transactionResponse = (await this.trade()) as TransactionResponse
      this.WaitingConfirmSwapModal.setTxHash(transactionResponse.hash)
      const transactionReceiptData = await this.waitTransactionConfirm(transactionResponse)

      if (transactionReceiptData) {
        this.WaitingConfirmSwapModal.setTransactionReceiptData(transactionReceiptData)
      }
    } catch (error) {
      if (error.message !== ErrorMessageSwap.TRANSACTION_REJECTED) {
        this.sentryLogError(this.network, error, 'Error')
        this.errorMessageModal = findErrorMessage(error)
      }
      console.error(error)
    }
  }

  changeQueryStringNetwork() {
    const query = Object.assign({}, this.$route.query)
    if (['disconnected'].includes(this.network)) {
      return
    }
    if (query.network !== this.network) {
      query.network = this.network
      this.$router.replace({ query })
    }
  }

  async handleBuyWadToken() {
    this.buyWardenTokenWithMinTokenAmount()
  }

  get submitBtnStatus(): BestRateButtonStatus {
    if (this.bestRateQueryState === BestRateQueryState.UNVALUABLE) {
      return 'UNVALUABLE'
    } else if (this.bestRateQueryState === BestRateQueryState.FAIL) {
      return 'FAIL'
    } else if (!this.isWalletConnected) {
      return 'CONNECT_WALLET'
    } else if (this.approvalState === ApprovalState.NOT_APPROVED) {
      return 'UNLOCK_TOKEN'
    } else if (this.approvalState === ApprovalState.PENDING) {
      return 'UNLOCK_TOKEN_LOADING'
    } else if (
      this.isWalletConnected &&
      this.isCorrectNetwork &&
      (!this.tokenAInput?.amount || bignumber(this.tokenAInput?.amount).isZero())
    ) {
      return 'ENTER_TOKEN_AMOUNT'
    } else if (
      (bignumber(this.tokenAInput?.amount).gt(this.tokensBalance[this.tokenAInput?.address as string]) ||
        (bignumber(this.tokenAInput?.amount).isZero() &&
          bignumber(this.tokensBalance[this.tokenAInput?.address as string]).isZero()) ||
        bignumber(this.tokenAInput?.amount).isNegative()) &&
      [BestRateQueryState.UNKNOWN, BestRateQueryState.SUCCESS].includes(this.bestRateQueryState)
    ) {
      return 'BALANCE_IS_NOT_ENOUGH'
    } else if ((!this.tokenAInput.address || !this.tokenBInput.address) && this.isCorrectNetwork) {
      return 'SELECT_A_TOKEN'
    } else if (this.bestRateQueryState === BestRateQueryState.PENDING) {
      return 'BEST_RATE_QUERY_LOADING'
    } else if (
      this.tokenAInput?.address &&
      this.tokenAInput?.amount &&
      this.tokenBInput?.address &&
      this.tokenAInput?.amount &&
      this.isCorrectNetwork
    ) {
      return 'SWAP'
    } else if (!this.isCorrectNetwork && this.network !== 'disconnected') {
      return 'WRONG_NETWORK'
    } else {
      return 'CONNECT_WALLET'
    }
  }

  get displayTotalValueOfBalanceTokenA() {
    if (
      this.tokenAInput?.address &&
      this.tokenPrices[this.tokenAInput.address] &&
      this.tokensBalance[this.tokenAInput.address]
    ) {
      return bignumber(this.tokenPrices[this.tokenAInput.address])
        .multipliedBy(this.tokensBalance[this.tokenAInput.address])
        .toString()
    }
    return '0'
  }

  get displayTotalValueOfBalanceTokenB() {
    if (
      this.tokenBInput?.address &&
      this.tokenPrices[this.tokenBInput.address] &&
      this.tokensBalance[this.tokenBInput.address]
    ) {
      return bignumber(this.tokenPrices[this.tokenBInput.address])
        .multipliedBy(this.tokensBalance[this.tokenBInput.address])
        .toString()
    }
    return '0'
  }

  @Watch('percentageOfTokenBalance')
  handlePercentageOfTokenBalance(percentage: string | number) {
    if (percentage === '') {
      return
    }
    if (this.tokenAInput?.address && this.tokensBalance[this.tokenAInput.address]) {
      const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address)
      // @ts-ignore
      const amountInWei = bignumber(this.tokensBalance[this.tokenAInput.address]).toWei(tokenAData.decimals)
      const resultInWei = bignumber(amountInWei).multipliedBy(bignumber(percentage).div(100)).toFixed(0)
      // @ts-ignore
      const resultInBase = bignumber(resultInWei).toBase(tokenAData.decimals).toString(10)
      const tokenAmount = this.getTokenAmountCanBeSwap(resultInBase, this.tokensBalance[this.tokenAInput.address])
      this.setTokenInputAmount('tokenA', tokenAmount)
    }
  }

  @Watch('tokensBalance')
  async onTokensBalanceChange(val: any) {
    if (this.tokenAInput?.address) {
      this.displayBalanceTokenA = val[this.tokenAInput.address]
    }
    if (this.tokenBInput?.address) {
      this.displayBalanceTokenB = val[this.tokenBInput.address]
    }
  }

  @Watch('tokenAInput')
  async onTokenAInputChange(val: TokenInputInterface) {
    if (val?.address && this.tokensBalance[val.address]) {
      this.displayBalanceTokenA = this.tokensBalance[val.address]
    } else {
      this.displayBalanceTokenA = '0'
    }
  }

  @Watch('tokenAInput.address', { deep: true })
  async onTokenAAddressChange(val: undefined | string, oldVal: undefined | string) {
    // Reset percentage select when user change token A address
    if (val === undefined || oldVal === val) {
      return
    }
    this.percentageOfTokenBalance = ''
  }

  @Watch('tokenBInput')
  async onTokenBInputChange(val: TokenInputInterface) {
    if (val?.address && this.tokensBalance[val.address]) {
      this.displayBalanceTokenB = this.tokensBalance[val.address]
    } else {
      this.displayBalanceTokenB = '0'
    }
  }

  @Watch('tradeState')
  async handleTradeStateChange(status: TradeState) {
    switch (status) {
      case TradeState.REJECTED:
        this.WaitingConfirmSwapModal.closeModal()
        break
    }
  }

  @Watch('userAddress')
  async handleAddressChange(newAddress: string | null, oldAddress: string | null) {
    if ([newAddress, oldAddress].includes(null)) {
      return
    }
    this.clearDataWhenEventChange()
    this.displayBalanceTokenA = '0'
    this.displayBalanceTokenB = '0'
    const nativeToken = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN
    this.handleTokenInput('tokenA', nativeToken.address)
    this.getTokensBalance([nativeToken])
  }

  @Watch('network')
  async handleNetWorkChange(network: Network | 'disconnected') {
    await this.clearDataWhenEventChange()
    await this.handleTokenNetworkChange(network)
    await this.handleUpdateAllToken()
    this.changeQueryStringNetwork()
    if (!(this.networkId in ChainIDThatSupport)) {
      return
    }
    await this.handleDefaultToken()
    const nativeToken = NETWORK_CONSTANT[this.networkId as ChainIDThatSupport].NATIVE_TOKEN
    this.getTokensBalance([nativeToken])
  }

  @Watch('isCorrectNetwork')
  async handleIsCorrectNetworkChange(isCorrectNetwork: boolean) {
    !isCorrectNetwork ? await this.clearDataWhenEventChange() : null
  }

  @Watch('wardenBestRateSdk1')
  async fetchDataAfterInitWardenBestRateSdk1() {
    this.fetchTokenPrice()
    this.fetchTokenInputBalance()
    this.findWADPrice()
  }

  @Watch('ethersProvider', { immediate: true })
  @Watch('userAddress', { immediate: true })
  async handelEventGetNftData() {
    this.getTopMwadNFT()
    this.getTopOptiPunkNFT()
  }

  @Watch('ethersProvider', { immediate: true })
  async handelEventAfterEthersProviderChange(newEthersProvider: any) {
    this.initMetawardenContract(newEthersProvider)
  }

  get iconSwap() {
    if (this.theme === THEME.SPACE_MODE) {
      return require('@/assets/svg/icon-swap-transparent-space.svg')
    }

    return require('@/assets/svg/icon-swap-transparent.svg')
  }
}
