import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { Vue as VueIntegration } from '@sentry/integrations'
import router from './router'
Sentry.init({
  Vue,
  dsn: 'https://41f17ebbc75949dcac3389e3b92763da@o917790.ingest.sentry.io/6355860',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new VueIntegration({ Vue, attachProps: true, logErrors: true })
  ],
  environment: process.env.VUE_APP_SENTRY_ENV || 'production',
  trackComponents: true,
  tracesSampleRate: 1.0
})

export default Sentry
