export enum Network {
  ethereum = 'ethereum',
  arbitrum = 'arbitrum',
  bsc = 'bsc',
  polygon = 'polygon',
  private = 'private',
  avalanche = 'avalanche',
  optimism = 'optimism'
}

export enum ChainIDThatSupport {
  ethereum = 1,
  optimism = 10,
  bsc = 56,
  polygon = 137,
  arbitrum = 42161,
  avalanche = 43114
}
