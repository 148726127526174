














import { Component, Prop, Vue, VModel } from 'vue-property-decorator'

@Component({
  name: 'PercentageSelector'
})
export default class SlippageSelector extends Vue {
  @Prop({ default: [] }) readonly percentageList!: Array<number>
  @Prop({ default: '30px' }) readonly buttonHeight!: string
  @VModel({ default: 0 }) percentageSelected!: number | string

  selectdPercentage(value: number) {
    this.percentageSelected = value
  }
}
