























import { Component } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { getTotalVolumeByNetworkId } from '@/resources/thirdPartyApi'
import NumberAnimation from '@/components/NumberAnimation.vue'
import Bignumber from '@/utils/bignumber'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'

@Component({
  name: 'TotalTradingVolumebox',
  components: {
    NumberAnimation
  }
})
export default class TotalTradingVolumebox extends AbstractSwapView {
  totalVolumeAllTime = ''

  created() {
    this.getTotalVolumeFromApi()
    setInterval(() => this.getTotalVolumeFromApi(), 1000 * 10) // 10s
  }

  async getTotalVolumeFromApi() {
    try {
      const [
        totalVolumeOnBSC,
        totalVolumeOnPolygon,
        totalVolumeOnArbitrum,
        totalVolumeOnEthereum,
        totalVolumeOnAvalanche,
        totalVolumeOnOptimism
      ] = await Promise.all([
        getTotalVolumeByNetworkId(ChainIDThatSupport.bsc),
        getTotalVolumeByNetworkId(ChainIDThatSupport.polygon),
        getTotalVolumeByNetworkId(ChainIDThatSupport.arbitrum),
        getTotalVolumeByNetworkId(ChainIDThatSupport.ethereum),
        getTotalVolumeByNetworkId(ChainIDThatSupport.avalanche),
        getTotalVolumeByNetworkId(ChainIDThatSupport.optimism)
      ])
      const totalVolume = Bignumber(totalVolumeOnBSC.data.totalVolumeAllTime)
        .plus(totalVolumeOnPolygon.data.totalVolumeAllTime)
        .plus(totalVolumeOnArbitrum.data.totalVolumeAllTime)
        .plus(totalVolumeOnEthereum.data.totalVolumeAllTime)
        .plus(totalVolumeOnAvalanche.data.totalVolumeAllTime)
        .plus(totalVolumeOnOptimism.data.totalVolumeAllTime)
        .toString(10)
      this.totalVolumeAllTime = totalVolume
    } catch (error) {
      this.sentryLogError(this.network, error, 'Error')
    }
  }
}
