import { getProviderInfo } from 'web3modal'
import { WalletProvider, WalletInfo, WalletConnector, Network, ChainIDThatSupport, GroupOfWardenAbi, GasfeeData, GasSpeed } from '../types'
import WalletConnectProvider from '@walletconnect/web3-provider'
import {
  InjectedWeb3Connector,
  WalletConnectConnector,
  BinanceChainWalletConnector,
  CoinbaseConnectConnector
} from '../connectors'
import { APP_NAME, NETWORK_ID_LOCAL_STORAGE_KEY, DEFAULT_NETWORK_ID } from '@/constants'

// Import abi
import bscWardenRoutingAbi from '@/constants/abis/bsc/WardenRoutingAbi.json'
import polygonWardenRoutingAbi from '@/constants/abis/polygon/WardenRoutingAbi.json'
import arbitrumWardenRoutingAbi from '@/constants/abis/arbitrum/WardenRoutingAbi.json'
import ethereumWardenRoutingAbi from '@/constants/abis/ethereum/WardenRoutingAbi.json'
import avalancheWardenRoutingAbi from '@/constants/abis/avalanche/WardenRoutingAbi.json'
import optimismWardenRoutingAbi from '@/constants/abis/optimism/WardenRoutingAbi.json'

export function walletLogo(walletProvider: WalletProvider, walletName: string) {
  switch (walletProvider) {
    case WalletProvider.INJECTED_WEB3:
      return walletName === 'MetaMask' ? '/static/svg/wallet-metamask.svg' : '/static/svg/wallet-web3.svg'
    case WalletProvider.WALLET_CONNECT:
      return '/static/svg/wallet-connect.svg'
    case WalletProvider.BINANCE_CHAIN:
      return '/static/svg/binance-wallet.svg'
    case WalletProvider.COINBASE_CONNECT:
      return '/static/svg/coinbase-wallet.svg'
  }
}

export function getWalletInfo(provider: WalletConnectProvider, walletProvider: WalletProvider): WalletInfo {
  const info = getProviderInfo(provider)
  const metaName = provider.walletMeta && provider.walletMeta.name
  const walletName = metaName ?? info.name

  console.log(info)
  return {
    // @ts-ignore
    provider: walletProvider,
    name: walletName,
    type: info.type,
    logo: walletLogo(walletProvider, walletName)
  }
}

export function getNetwork(id: number): Network {
  switch (id) {
    case 1: return Network.ethereum
    case 10: return Network.optimism
    case 56: return Network.bsc
    case 137: return Network.polygon
    case 42161: return Network.arbitrum
    case 43114: return Network.avalanche
    default: return Network.private
  }
}

export async function getConnector(walletProvider: WalletProvider) {
  let connector: WalletConnector
  switch (walletProvider) {
    case WalletProvider.INJECTED_WEB3:
      connector = new InjectedWeb3Connector()
      break
    case WalletProvider.WALLET_CONNECT:
      connector = new WalletConnectConnector()
      break
    case WalletProvider.BINANCE_CHAIN:
      connector = new BinanceChainWalletConnector()
      break
    case WalletProvider.COINBASE_CONNECT:
      connector = new CoinbaseConnectConnector()
      break
  }
  return connector
}

function saveCurrentNetworkIdInlocalStorage(netWorkId: ChainIDThatSupport) {
  const localStorageName = `${APP_NAME}.${NETWORK_ID_LOCAL_STORAGE_KEY}`
  localStorage.setItem(localStorageName, netWorkId.toString())
}
export { saveCurrentNetworkIdInlocalStorage }

export function getCurrentNetworkIdFromLocalStorage(): number {
  const localStorageName = `${APP_NAME}.${NETWORK_ID_LOCAL_STORAGE_KEY}`
  const tempLocalStorage = localStorage.getItem(localStorageName)
  if (!tempLocalStorage) {
    saveCurrentNetworkIdInlocalStorage(DEFAULT_NETWORK_ID)
    return DEFAULT_NETWORK_ID
  }
  const currentNetworkId: ChainIDThatSupport = parseInt(tempLocalStorage as string)
  return currentNetworkId
}

export function getWardenAbis(networkId: ChainIDThatSupport): GroupOfWardenAbi {
  let wardenRoutingAbi = null

  switch (networkId) {
    case ChainIDThatSupport.polygon :
      wardenRoutingAbi = polygonWardenRoutingAbi
      break
    case ChainIDThatSupport.bsc :
      wardenRoutingAbi = bscWardenRoutingAbi
      break
    case ChainIDThatSupport.arbitrum :
      wardenRoutingAbi = arbitrumWardenRoutingAbi
      break
    case ChainIDThatSupport.ethereum :
      wardenRoutingAbi = ethereumWardenRoutingAbi
      break
    case ChainIDThatSupport.avalanche :
      wardenRoutingAbi = avalancheWardenRoutingAbi
      break
    case ChainIDThatSupport.optimism :
      wardenRoutingAbi = optimismWardenRoutingAbi
      break
    default :
      throw new Error(`Function getWardenAbis don't have abi for network id ${networkId}`)
  }
  return {
    wardenRoutingAbi
  }
}

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function genGasFeeDataFromSelectedGasSpeed(gasFeeData: GasfeeData, selectedGasSpeed: GasSpeed, isWalletProviderSupportEip1559: boolean, networkId: ChainIDThatSupport) {
  const gasFee = gasFeeData[selectedGasSpeed]
  if (!gasFee) {
    throw Error('Gee fee data not found')
  }

  if ([ChainIDThatSupport.ethereum, ChainIDThatSupport.optimism, ChainIDThatSupport.arbitrum].includes(networkId)) {
    // Don't send gas price to wallet provider
    return {}
  }

  return { gasPrice: gasFee.gasPriceWei }
}
