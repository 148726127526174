import THEME from '@/constants/theme'

export default class State {
  public mute: boolean
  public theme: string

  constructor() {
    this.mute = false
    this.theme = THEME.LIGHT_MODE
  }
}
