import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { ethers } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { ErrorHTMLImageElement } from '@/types'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import { OptiPunkNftModule, OptiPunkNftState } from '../store'
import { TopOptiPunkClass, OptiPunkClass } from '../types'
import { ChainIDThatSupport } from '@/features/Web3Connector/types'
import TopOptipunkAbi from '@/constants/abis/optimism/TopOptiPunkAbi.json'
import { NETWORK_CONSTANT } from '@/constants/index'
import { AbstractAmplitudeView } from '@/features/Amplitude/abstractView'

@Component
export class AbstractOptiPunkNftView extends Mixins(Vue, AbstractAmplitudeView) {
  @Web3ConnectorState public readonly ethersProvider!: ethers.providers.Web3Provider
  @Web3ConnectorState public readonly userAddress!: string
  @Web3ConnectorState public readonly networkId!: number

  @OptiPunkNftState public readonly topOptiPunkClass!: TopOptiPunkClass

  get isFoundOptiPunkNft() {
    if (
        this.topOptiPunkClass?.found &&
        getAddress(this.topOptiPunkClass?.userAddress) === getAddress(this.userAddress)
    ) {
      return true
    }

    return false
  }

  get displayOptiPunkImageUrl() {
    return this.topOptiPunkClass.imageUrl ? this.topOptiPunkClass.imageUrl : ''
  }

  async getTopOptiPunkNFT() {
    if (!this.ethersProvider) {
      OptiPunkNftModule.setTopOptiPunkClass({})
      return
    }
    const { chainId } = await this.ethersProvider.getNetwork()
    if (chainId !== ChainIDThatSupport.optimism) {
      OptiPunkNftModule.setTopOptiPunkClass({})
      return
    }
    if (!this.userAddress) {
      OptiPunkNftModule.setTopOptiPunkClass({})
      return
    }
    const topOptiPunkClassContract = new ethers.Contract(
      NETWORK_CONSTANT[ChainIDThatSupport.optimism].TOP_OPTIPUNK_CLASS_CONTRACT_ADDRESS,
      TopOptipunkAbi,
      this.ethersProvider
    )
    const result = await topOptiPunkClassContract.getTopOne(this.userAddress)
    const classNumber = parseInt(result._class)
    const foundNFT = result._found
    const tokenId = parseInt(result._tokenId)

    let optiPunkClassName: OptiPunkClass = OptiPunkClass.NO_CLASS
    if (foundNFT) {
      if (classNumber === 0) {
        optiPunkClassName = OptiPunkClass.OPTIPUNK
      }
      if (this.topOptiPunkClass?.tokenId !== tokenId) {
        this.getOptiPunkNftImage(tokenId).then(async url => OptiPunkNftModule.updateTopOptiPunkImageUrl(url))
      }
      this.amplitudeSetNftUserProperties(tokenId, optiPunkClassName)
    } else {
      this.amplitudeUnsetNftUserProperties()
    }
    OptiPunkNftModule.setTopOptiPunkClass({
      class: optiPunkClassName,
      found: result._found,
      tokenId: tokenId,
      userAddress: this.userAddress
    })
  }

  async handleOptiPunkNftImageError(e: ErrorHTMLImageElement) {
    if (e?.target?.src) {
      e.target.src = require('@/assets/png/example-optipunk.png')
    }
  }

  public async getOptiPunkNftImage(tokenId: number) {
    const uri = `${NETWORK_CONSTANT[ChainIDThatSupport.optimism].OPTIPUNK_NFT_IMAGE_S3_URL}/${tokenId}.png`
    await OptiPunkNftModule.updateTopOptiPunkImageUrl(uri)

    return uri
  }
}
