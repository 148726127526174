import Web3 from 'web3'
import { getWalletInfo } from '../utils'
import { WalletProvider, WalletConnector } from '../types'

export class BinanceChainWalletConnector implements WalletConnector {
  public web3: Web3 | null = null
  public provider: any = null

  async connect() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.provider = window.BinanceChain
    if (this.provider === undefined) {
      throw Error('No injected window.BinanceChain found')
    }
    await this.provider.enable()
    this.web3 = new Web3(this.provider)
    const accounts = await this.web3.eth.getAccounts()
    return {
      provider: this.provider,
      web3: this.web3,
      accounts,
      walletInfo: getWalletInfo(this.provider, WalletProvider.BINANCE_CHAIN)
    }
  }

  async disconnect() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (this.web3 && this.web3.currentProvider && this.web3.currentProvider.close) {
      console.log('close wallet provider', this.web3.currentProvider)
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      await this.web3.currentProvider.close()
    }
    this.provider = null
    this.web3 = null
  }
}
