
















import { Component } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'

@Component({
  name: 'WrongNetworkNotice'
})

export default class WrongNetworkNotice extends AbstractWeb3ConnectorView {

}
